/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";
import searchPage from "../../../utils/searchPage";

export default function Pagination(props: {
  currentPage: number;
  setCurrentPage: any;
  totalPages: number;
}): JSX.Element {
  const { currentPage, setCurrentPage, totalPages } = props;
  const history = useHistory();

  let page1: number;
  let page2: number;
  let page3: number;
  let page4: number;
  switch (currentPage) {
    case 1:
      page1 = 1;
      page2 = 2;
      page3 = 3;
      page4 = totalPages;
      break;
    case 2:
      page1 = 1;
      page2 = 2;
      page3 = 3;
      page4 = totalPages;
      break;
    case 3:
      if (totalPages < 5) {
        page1 = 1;
        page2 = 2;
        page3 = 3;
        page4 = totalPages;
      } else {
        page1 = currentPage - 1;
        page2 = currentPage;
        page3 = currentPage + 1;
        page4 = totalPages;
      }
      break;
    case totalPages - 1:
      page1 = 1;
      page2 = currentPage - 1;
      page3 = currentPage;
      page4 = currentPage + 1;
      break;
    case totalPages:
      page1 = 1;
      page2 = currentPage - 2;
      page3 = currentPage - 1;
      page4 = currentPage;
      break;
    default:
      page1 = currentPage - 1;
      page2 = currentPage;
      page3 = currentPage + 1;
      page4 = totalPages;
      break;
  }

  return (
    <div className="row" style={{ paddingBottom: 12 }}>
      <div className="col-lg-12" style={{ padding: "0 6px" }}>
        <ul className="pagination" style={{ backgroundColor: "white" }}>
          <li className="page-item">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/mouse-events-have-key-events */}
            <div
              style={{ cursor: "pointer", background: currentPage === 1 ? "white" : undefined }}
              className="page-link"
              onClick={() => {
                if (currentPage - 1 > 0) {
                  searchPage(currentPage - 1, setCurrentPage, history);
                }
              }}
            >
              <i
                className="fa fa-chevron-left"
                aria-hidden="true"
                style={{ color: currentPage === 1 ? "#777777" : undefined }}
              />
            </div>
          </li>
          <li className="page-item">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              style={{ cursor: "pointer" }}
              className={currentPage === page1 ? "page-link active" : "page-link"}
              onClick={() => searchPage(page1, setCurrentPage, history)}
            >
              {page1}
            </div>
          </li>
          {totalPages > 4 && (currentPage === totalPages || currentPage === totalPages - 1) && (
            <li className="page-item">...</li>
          )}
          {totalPages >= 2 && (
            <li className="page-item">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                style={{ cursor: "pointer" }}
                className={currentPage === page2 ? "page-link active" : "page-link"}
                onClick={() => searchPage(page2, setCurrentPage, history)}
              >
                {page2}
              </div>
            </li>
          )}
          {totalPages >= 3 && (
            <li className="page-item">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                style={{ cursor: "pointer" }}
                className={currentPage === page3 ? "page-link active" : "page-link"}
                onClick={() => searchPage(page3, setCurrentPage, history)}
              >
                {page3}
              </div>
            </li>
          )}
          {totalPages > 4 && currentPage !== totalPages && currentPage !== totalPages - 1 && (
            <li className="page-item">...</li>
          )}
          {totalPages >= 4 && (
            <li className="page-item">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                style={{ cursor: "pointer" }}
                className={currentPage === page4 ? "page-link active" : "page-link"}
                onClick={() => searchPage(page4, setCurrentPage, history)}
              >
                {page4}
              </div>
            </li>
          )}
          <li className="page-item">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              style={{ cursor: "pointer", background: currentPage >= totalPages ? "white" : undefined }}
              className="page-link"
              onClick={() => {
                if (currentPage + 1 <= totalPages) {
                  searchPage(currentPage + 1, setCurrentPage, history);
                }
              }}
            >
              <i
                className="fa fa-chevron-right"
                aria-hidden="true"
                style={{ color: currentPage >= totalPages ? "#777777" : undefined }}
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
