import React from "react";

// @ts-ignore
export default function AdPostTags(props) {
  const { handleBlur, values, setFieldValue } = props;

  return (
    <div className="col-lg-12">
      <div className="form-group">
        <label className="form-label">ad tag</label>
        <textarea
          id="ad-post-tags"
          name="tags"
          onChange={(event) => {
            setFieldValue(
              "tags",
              event.target.value
                .replace(/ /g, "")
                .split(",")
                .filter((el) => el.length > 0)
            );
          }}
          onBlur={handleBlur}
          defaultValue={values.tags.join(", ")}
          className="form-control"
          placeholder="Maximum of 15 keywords. Separate by comma. (Eg: tag1, tag2)"
        />
      </div>
    </div>
  );
}
