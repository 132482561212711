import { call, put } from "redux-saga/effects";
import { FetchMyAdsAction, fetchMyAdsFailureAction, fetchMyAdsSuccessAction } from "../actions";
import getCurrentUserAuthorization from "../../../../utils/getAuthorizationToken";
import { AdvertisementCardInterface } from "../../../../shared/advertisementInterface";

async function fetchMyAdsFromApi(userId: string, authToken: string) {
  return fetch(`${process.env.REACT_APP_BASE_URL}/user/${userId}/my-ads`, {
    method: "GET",
    headers: {
      Authorization: `bearer ${authToken}`
    }
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callFetchMyAdsSaga(action: FetchMyAdsAction) {
  const authToken: string = yield call(getCurrentUserAuthorization);

  try {
    // @ts-ignore
    const result = yield call(fetchMyAdsFromApi, action.userId, authToken);
    if (result.status === "success") {
      const idResult: AdvertisementCardInterface[] = (result.items || []).map((el: any) => ({
        url: el.url,
        title: el.title,
        images: el.images,
        adType: el.adType,
        category: el.category,
        subCategory: el.subCategory,
        isNegotiable: el.isNegotiable,
        location: el.location,
        subLocation: el.subLocation,
        postedOn: new Date(el.postedOn),
        price: el.price,
        priceUnit: el.priceUnit,
        isVerified: el.isVerified
      }));

      yield put(fetchMyAdsSuccessAction(idResult, result.totalAds || 0));
    } else {
      throw new Error(result.toString());
    }
  } catch (e) {
    yield put(fetchMyAdsFailureAction(e.toString()));
  }
}
