import { call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { ForgetPasswordAction } from "../actions";
import { auth } from "../../../../config/firebase";

function forgetPassword(email: string) {
  return auth.sendPasswordResetEmail(email);
}

export default function* callForgetPasswordSaga(actions: ForgetPasswordAction) {
  try {
    yield call(forgetPassword, actions.email);
    toast.success("Password reset email sent successfully.");
  } catch (e) {
    toast.error(`An error occurred, Please try again later.`);
  }
}
