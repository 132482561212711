import React from "react";
import { useTranslation } from "react-i18next";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";

export default function ViewAdSpec(props: { currentAd: ReceivedAdvertisementInterface }) {
  const { currentAd } = props;
  const { t } = useTranslation();

  return (
    <>
      {Object.keys(currentAd.specifications).length > 0 && (
        <div className="ad-details-card">
          <div className="ad-details-title">
            <h5>Specification</h5>
          </div>
          <div className="ad-details-specific">
            <ul>
              {Object.keys(currentAd.specifications)
                .sort()
                .map((el, index) => (
                  <li key={index}>
                    <h6>{t(el)}</h6>
                    <p style={{ textAlign: "end" }}>{currentAd?.specifications[el]}</p>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
