// eslint-disable-next-line import/prefer-default-export
export const LOCATIONS = {
  colombo: [],
  galle: [],
  kandy: [],
  ampara: [],
  anuradhapura: [],
  badulla: [],
  batticaloa: [],
  gampaha: [],
  hambantota: [],
  jaffna: [],
  kalutara: [],
  kegalle: [],
  kilinochchi: [],
  kurunegala: [],
  mannar: [],
  matale: [],
  matara: [],
  monaragala: [],
  mullativu: [],
  nuwara_eliya: [],
  polonnaruwa: [],
  puttalam: [],
  ratnapura: [],
  trincomalee: [],
  vavuniya: []
};
