import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteAdAction } from "../../redux/actions";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";

export default function DeleteConfirmationModal(props: { ad: ReceivedAdvertisementInterface }) {
  const dispatch = useDispatch();
  const { ad } = props;
  const [reason, setReason] = useState<string>("SOLD_ON_THE_SITE");

  return (
    <div
      className="modal"
      id="deleteConfirmationModal"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Are you sure?
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ color: "black" }}>
            <p>This action cannot be undone. If you press delete, this advertisement will be removed permanently.</p>
            <br />
            <p>Mark as: </p>
            <div className="d-flex flex-column">
              <div className="form-check w-100 h-100 pl-5 my-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gridRadios"
                  id="gridRadios1"
                  checked={reason === "SOLD_ON_THE_SITE"}
                  style={{
                    marginTop: "7px"
                  }}
                  onChange={() => setReason("SOLD_ON_THE_SITE")}
                />
                <label style={{ cursor: "pointer" }} className="form-check-label" htmlFor="gridRadios1">
                  Sold on the site
                </label>
              </div>
              <div className="form-check w-100 h-100 pl-5 my-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gridRadios"
                  id="gridRadios2"
                  checked={reason === "SOLD_ON_SOMEWHERE_ELSE"}
                  style={{
                    marginTop: "7px"
                  }}
                  onChange={() => setReason("SOLD_ON_SOMEWHERE_ELSE")}
                />
                <label style={{ cursor: "pointer" }} className="form-check-label" htmlFor="gridRadios2">
                  Sold on somewhere else
                </label>
              </div>
              <div className="form-check w-100 h-100 pl-5 my-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gridRadios"
                  id="gridRadios3"
                  checked={reason === "CHANGED_MY_MIND"}
                  style={{
                    marginTop: "7px"
                  }}
                  onChange={() => setReason("CHANGED_MY_MIND")}
                />
                <label style={{ cursor: "pointer" }} className="form-check-label" htmlFor="gridRadios3">
                  Changed my mind
                </label>
              </div>
              <div className="form-check w-100 h-100 pl-5 my-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gridRadios"
                  id="gridRadios4"
                  checked={reason === "OTHER"}
                  style={{
                    marginTop: "7px"
                  }}
                  onChange={() => setReason("OTHER")}
                />
                <label style={{ cursor: "pointer" }} className="form-check-label" htmlFor="gridRadios4">
                  Other
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
              style={{
                height: "35px",
                padding: "0 32px",
                textTransform: "capitalize"
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm"
              data-dismiss="modal"
              style={{
                height: "35px",
                padding: "0 32px",
                textTransform: "capitalize"
              }}
              onClick={() => dispatch(deleteAdAction(ad.id, reason))}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
