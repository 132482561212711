import PuffLoader from "react-spinners/PuffLoader";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../store";
import { RemoteConfigState } from "../../../utils/remoteConfigurations";
import { HomePageState } from "../redux/reducer";
import searchCities from "../../../utils/searchCities";

export default function FilterByCities(): JSX.Element {
  const history = useHistory();
  const { locations } = useSelector<AppState, RemoteConfigState>((state) => state.remoteConfigManagement);
  const { cityCounts } = useSelector<AppState, HomePageState>((state) => state.homePageManagement);
  const { t } = useTranslation();
  const [cities, setCities] = useState<string[]>([]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const citiesFromUrl = (urlSearchParams.get("cities") || "").split(",").filter((el) => el.length > 0);
  useEffect(() => {
    if (citiesFromUrl.toString() !== cities.toString()) {
      setCities(citiesFromUrl);
    }
  }, [citiesFromUrl]);

  return (
    <div className="col-md-6 col-lg-12" style={{ padding: "0 6px" }}>
      <div className="product-sidebar" style={{ backgroundColor: "white" }}>
        <div className="product-sidebar-title">
          <h6>Filter by cities</h6>
        </div>
        <div className="product-sidebar-content">
          <ul className="product-sidebar-list widget">
            {Object.keys(locations).length === 0 ? (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  height: "100%"
                }}
              >
                <PuffLoader size={50} color="#001CAC" loading />
              </div>
            ) : (
              Object.keys(locations).map((currentCity) => {
                const cityCountTotal = (cityCounts[currentCity] || {}).total;
                return (
                  <li key={currentCity}>
                    <input
                      id={currentCity}
                      type="checkbox"
                      checked={cities.includes(currentCity)}
                      onChange={(e) => {
                        // @ts-ignore
                        if (e.target.checked) {
                          searchCities(cities.concat(currentCity), setCities, history);
                        } else {
                          searchCities(
                            cities.filter((x) => x !== currentCity),
                            setCities,
                            history
                          );
                        }
                      }}
                    />
                    <label htmlFor={currentCity}>
                      <span>{t(currentCity)}</span>
                      {cityCountTotal && <span>{`(${cityCountTotal})`}</span>}
                    </label>
                  </li>
                );
              })
            )}
          </ul>
          <button type="button" className="product-filter-btn" onClick={() => searchCities([], setCities, history)}>
            <span>Clear Filter</span>
          </button>
        </div>
      </div>
    </div>
  );
}
