import { AdvertisementCardInterface } from "../../../shared/advertisementInterface";

export enum homePageActionTypes {
  FETCH_ADVERTISEMENTS = "aduwata-lk/home/fetch_advertisements",
  FETCH_ADVERTISEMENTS_SUCCESS = "aduwata-lk/home/fetch_advertisements_success",
  FETCH_ADVERTISEMENTS_FAILURE = "aduwata-lk/home/fetch_advertisements_failure",
  FETCH_COUNTS = "aduwata-lk/home/fetch_counts",
  FETCH_COUNTS_SUCCESS = "aduwata-lk/home/fetch_counts_success",
  FETCH_COUNTS_FAILURE = "aduwata-lk/home/fetch_counts_failure",
  SUBSCRIBE = "aduwata-lk/home/subscribe",
  SUBSCRIBE_SUCCESS = "aduwata-lk/home/subscribe_success",
  SUBSCRIBE_FAILURE = "aduwata-lk/home/subscribe_failure"
}

export interface FetchAdvertisementsAction {
  type: homePageActionTypes.FETCH_ADVERTISEMENTS;
  query: string;
  category: string;
  subCategory: string;
  cities: string[];
  adTypes: string[];
  page: number;
}

interface FetchAdvertisementsSuccessAction {
  type: homePageActionTypes.FETCH_ADVERTISEMENTS_SUCCESS;
  advertisements: AdvertisementCardInterface[];
  totalAds: number;
}

interface FetchAdvertisementsFailureAction {
  type: homePageActionTypes.FETCH_ADVERTISEMENTS_FAILURE;
  err: string;
}

export interface FetchCountsAction {
  type: homePageActionTypes.FETCH_COUNTS;
}

interface FetchCountsSuccessAction {
  type: homePageActionTypes.FETCH_COUNTS_SUCCESS;
  categoryCounts: any;
  cityCounts: any;
  totalAdCount: number;
}

interface FetchCountsFailureAction {
  type: homePageActionTypes.FETCH_COUNTS_FAILURE;
  err: string;
}

export interface SubscribeAction {
  type: homePageActionTypes.SUBSCRIBE;
  email: string;
}

interface SubscribeSuccessAction {
  type: homePageActionTypes.SUBSCRIBE_SUCCESS;
}

interface SubscribeFailureAction {
  type: homePageActionTypes.SUBSCRIBE_FAILURE;
  err: string;
}

export type HomePageActions =
  | FetchAdvertisementsAction
  | FetchAdvertisementsSuccessAction
  | FetchAdvertisementsFailureAction
  | FetchCountsAction
  | FetchCountsSuccessAction
  | FetchCountsFailureAction
  | SubscribeAction
  | SubscribeSuccessAction
  | SubscribeFailureAction;

export function fetchAdvertisementsAction(
  query: string,
  category: string,
  subCategory: string,
  cities: string[],
  adTypes: string[],
  page: number
): FetchAdvertisementsAction {
  return {
    type: homePageActionTypes.FETCH_ADVERTISEMENTS,
    query,
    category,
    subCategory,
    cities,
    adTypes,
    page
  };
}

export function fetchAdvertisementsSuccessAction(
  advertisements: AdvertisementCardInterface[],
  totalAds: number
): FetchAdvertisementsSuccessAction {
  return {
    type: homePageActionTypes.FETCH_ADVERTISEMENTS_SUCCESS,
    advertisements,
    totalAds
  };
}

export function fetchAdvertisementsFailureAction(err: string): FetchAdvertisementsFailureAction {
  console.error("fetchAdvertisementsFailureAction", err);
  return {
    type: homePageActionTypes.FETCH_ADVERTISEMENTS_FAILURE,
    err
  };
}

export function fetchCountsAction(): FetchCountsAction {
  return {
    type: homePageActionTypes.FETCH_COUNTS
  };
}

export function fetchCountsSuccessAction(
  categoryCounts: any,
  cityCounts: any,
  totalAdCount: number
): FetchCountsSuccessAction {
  return {
    type: homePageActionTypes.FETCH_COUNTS_SUCCESS,
    categoryCounts,
    cityCounts,
    totalAdCount
  };
}

export function fetchCountsFailureAction(err: string): FetchCountsFailureAction {
  console.error("fetchAdvertisementsFailureAction", err);
  return {
    type: homePageActionTypes.FETCH_COUNTS_FAILURE,
    err
  };
}

export function subscribeAction(email: string): SubscribeAction {
  return {
    type: homePageActionTypes.SUBSCRIBE,
    email
  };
}

export function subscribeSuccessAction(): SubscribeSuccessAction {
  return {
    type: homePageActionTypes.SUBSCRIBE_SUCCESS
  };
}

export function subscribeFailureAction(err: string): SubscribeFailureAction {
  console.error("subscribeFailureAction", err);
  return {
    type: homePageActionTypes.SUBSCRIBE_FAILURE,
    err
  };
}
