import { call, put } from "redux-saga/effects";
import { auth } from "../../../../config/firebase";
import { signOutFailureAction, signOutSuccessAction } from "../actions";

async function signOutFromFirebase() {
  return auth
    .signOut()
    .then((data) => data)
    .catch((err) => err);
}

export default function* callSignOutSaga() {
  try {
    // @ts-ignore
    const result = yield call(signOutFromFirebase);
    yield put(signOutSuccessAction());
  } catch (e) {
    yield put(signOutFailureAction(e.toString()));
  }
}
