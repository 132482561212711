import React from "react";

export default function EditOpeningTime(props: {
  day: string;
  isClosed: boolean;
  setIsClosed: any;
  isOpen24h: boolean;
  setIsOpen24h: any;
  startTime: string | undefined;
  setStartTime: any;
  endTime: string | undefined;
  setEndTime: any;
}) {
  const { day, isClosed, setIsClosed, isOpen24h, setIsOpen24h, startTime, setStartTime, endTime, setEndTime } = props;

  return (
    <div className="d-flex flex-column">
      <div className="col-12 row m-0 mt-3 d-flex align-items-center">
        <p className="col-12 col-md-2 p-0 mr-2" style={{ maxWidth: 90, textTransform: "capitalize" }}>
          {day}:
        </p>
        <div className="">
          <div className="form-check-inline">
            <label className="form-check-label" htmlFor={`${day}-open`}>
              <input
                type="radio"
                className="form-check-input"
                id={`${day}-open`}
                name={`${day}-optradio`}
                value="open"
                checked={!isClosed}
                onChange={() => setIsClosed(false)}
              />
              Open
            </label>
          </div>
          <div className="form-check-inline">
            <label className="form-check-label" htmlFor={`${day}-closed`}>
              <input
                type="radio"
                className="form-check-input"
                id={`${day}-closed`}
                name={`${day}-optradio`}
                value="closed"
                checked={isClosed}
                onChange={() => {
                  setIsClosed(true);
                  setStartTime(undefined);
                  setEndTime(undefined);
                }}
              />
              Closed
            </label>
          </div>
        </div>
      </div>
      {!isClosed && (
        <div className="col-12 row m-0 d-flex align-items-center">
          <div style={{ width: 98 }} />
          <input
            disabled={isOpen24h}
            type="time"
            id={`${day}-open-time`}
            className="form-control col-12 col-md-2 mb-1 mr-2"
            style={{ height: 36, minWidth: 180 }}
            defaultValue={startTime}
            onChange={(e) => {
              setStartTime(e.target.value);
            }}
          />
          <input
            disabled={isOpen24h}
            type="time"
            id={`${day}-close-time`}
            className="form-control col-12 col-md-2 mb-1 mr-2"
            style={{ height: 36, minWidth: 180 }}
            defaultValue={endTime}
            onChange={(e) => {
              setEndTime(e.target.value);
            }}
          />
          <label className="switch" style={{ cursor: "pointer" }}>
            <input
              type="checkbox"
              checked={isOpen24h}
              onChange={(e) => {
                setIsOpen24h(e.target.checked);
                setStartTime(undefined);
                setEndTime(undefined);
              }}
            />
            <span className="slider round mr-2" />
            Open 24 hours
          </label>
        </div>
      )}
    </div>
  );
}
