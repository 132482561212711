import { takeLatest } from "redux-saga/effects";
import { userActionTypes } from "../actions";
import callFetchCustomUserSaga from "./callFetchCustomUserSaga";
import callFetchMyAdsSaga from "./callFetchMyAdsSaga";
import callEditUserProfileSaga from "./callEditUserProfileSaga";
import callFetchMessagesSaga from "./callFetchMessagesSaga";
import callFetchLoggedInUserSaga from "../../../login/redux/sagas/callFetchLoggedInUserSaga";
import callAddContactNoSaga from "./callAddContactNoSaga";
import callRemoveContactNoSaga from "./callRemoveContactNoSaga";

function* getUserSagas(): any {
  yield takeLatest(userActionTypes.FETCH_CUSTOM_USER, callFetchCustomUserSaga);
  yield takeLatest(userActionTypes.FETCH_MY_ADS, callFetchMyAdsSaga);
  yield takeLatest(userActionTypes.ADD_CONTACT_NO, callAddContactNoSaga);
  yield takeLatest(userActionTypes.REMOVE_CONTACT_NO, callRemoveContactNoSaga);
  yield takeLatest(userActionTypes.EDIT_USER_PROFILE, callEditUserProfileSaga);
  // listen to another action
  yield takeLatest(userActionTypes.EDIT_USER_PROFILE_SUCCESS, callFetchCustomUserSaga);
  yield takeLatest(userActionTypes.EDIT_USER_PROFILE_SUCCESS, callFetchLoggedInUserSaga);
  yield takeLatest(userActionTypes.ADD_CONTACT_NO_SUCCESS, callFetchCustomUserSaga);
  yield takeLatest(userActionTypes.ADD_CONTACT_NO_SUCCESS, callFetchLoggedInUserSaga);
  yield takeLatest(userActionTypes.REMOVE_CONTACT_NO_SUCCESS, callFetchCustomUserSaga);
  yield takeLatest(userActionTypes.REMOVE_CONTACT_NO_SUCCESS, callFetchLoggedInUserSaga);
  yield takeLatest(userActionTypes.FETCH_MESSAGES, callFetchMessagesSaga);
}

// eslint-disable-next-line import/prefer-default-export
export const userSagas = [getUserSagas];
