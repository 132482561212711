import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { RemoteConfigState } from "../../../utils/remoteConfigurations";
import getBackgroundColorCode from "../../../utils/getBackgroundColorCode";

// @ts-ignore
export default function AdPostAdType(props) {
  const { handleBlur, values, setFieldValue } = props;
  const { t } = useTranslation();
  const { adTypes: availableAdTypes } = useSelector<AppState, RemoteConfigState>(
    (state) => state.remoteConfigManagement
  );

  return (
    <div className="col-lg-6">
      <div className="form-group">
        <ul className="form-check-list">
          <li style={{ marginBottom: 0 }}>
            <label className="form-label">ad Type</label>
          </li>
          {availableAdTypes.map((currentType: any, index: React.Key) => (
            <li key={index}>
              <input
                className="form-check"
                name="adType"
                onBlur={handleBlur}
                type="checkbox"
                checked={values.adType === currentType}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValue("adType", currentType);
                  }
                }}
              />
              <label
                htmlFor={currentType}
                className="flat-badge sale"
                style={{
                  position: "unset",
                  backgroundColor: getBackgroundColorCode(currentType)
                }}
              >
                {t(currentType)}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
