import React from "react";
import errorStyles from "../../../config/errorStyles";
import { COLOR_TEXT_LINK } from "../../../config/colorProfile";
import ManageMyContactModal from "../../user/containers/ManageMyContactModal";

// @ts-ignore
export default function AuthorInformation(props) {
  const { loggedInUser, errors } = props;

  return (
    <div className="adpost-card">
      <div className="adpost-title">
        <h3>Author Information</h3>
      </div>
      <ManageMyContactModal currentUser={loggedInUser} />
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Your Name"
              value={loggedInUser?.name}
              disabled
              onChange={() => undefined}
            />
            {errors.name && <p style={errorStyles.err}>{errors.name}</p>}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Your Email"
              value={loggedInUser?.email}
              disabled
              onChange={() => undefined}
            />
            {errors.email && <p style={errorStyles.err}>{errors.email}</p>}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group" style={{ position: "relative" }}>
            <label className="form-label">Contact Number</label>
            <button
              type="button"
              style={{
                position: "absolute",
                left: "130px",
                top: "1px",
                border: "none",
                backgroundColor: "transparent"
              }}
              data-toggle="modal"
              data-target="#manageMyContactModal"
            >
              <i className="fa fa-pencil-square" aria-hidden="true" style={{ fontSize: 18, color: COLOR_TEXT_LINK }} />
            </button>
            <input
              type="text"
              className="form-control"
              placeholder="Your Number"
              value={loggedInUser?.contact.map((el: { number: any }) => el.number).join(", ")}
              disabled
              onChange={() => undefined}
            />

            {errors.contact && <p style={errorStyles.err}>{errors.contact}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
