import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  SendMessageToSellerAdAction,
  sendMessageToSellerAdFailureAction,
  sendMessageToSellerAdSuccessAction
} from "../actions";

async function sendMessageToSellerApiCall(
  adId: string,
  authorId: string,
  name: string,
  email: string,
  contactNo: string,
  message: string
) {
  const body = {
    adId,
    authorId,
    name,
    email,
    contactNo,
    message
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/user/message`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callSendMessageToSellerSaga(action: SendMessageToSellerAdAction) {
  try {
    // @ts-ignore
    const result = yield call(
      sendMessageToSellerApiCall,
      action.adId,
      action.authorId,
      action.name,
      action.email,
      action.contactNo,
      action.message
    );
    if (result.status === "success") {
      toast.success("You have successfully sent the message.");
      yield put(sendMessageToSellerAdSuccessAction());
    } else {
      throw new Error(JSON.stringify(result));
    }
  } catch (e) {
    toast.error(`An error occurred, Please try again later.`);
    yield put(sendMessageToSellerAdFailureAction(e.toString()));
  }
}
