import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../config/firebase";
import { setAuthenticationUidAction } from "../views/login/redux/actions";
import { AppState } from "../store";
import { LoginState } from "../views/login/redux/reducer";

export const UserContext = createContext({ user: null });

export const UserProvider = (props: { children: any }) => {
  const { children } = props;
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const { authenticationUID } = useSelector<AppState, LoginState>((state) => state.loginManagement);

  useEffect(() => {
    if (user) {
      // @ts-ignore
      const uid = user?.uid;
      if (uid !== authenticationUID) {
        dispatch(setAuthenticationUidAction(uid));
      }
    }
  }, [authenticationUID, dispatch, user]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // @ts-ignore
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  });

  // @ts-ignore
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
