import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import yupSchemaForAdPost from "../yupSchemaForAdPost";
import { PublishAdvertisementInterface } from "../../../shared/advertisementInterface";
import { publishAdAction } from "../redux/actions";
import FormikOnError from "../../../utils/FormikOnError";
import AdPostTitle from "../components/AdPostTitle";
import AdPostImageUpload from "../components/AdPostImageUpload";
import AdPostCategory from "../components/AdPostCategory";
import AdPostSubCategory from "../components/AdPostSubCategory";
import AdPostLocation from "../components/AdPostLocation";
import AdPostSubLocation from "../components/AdPostSubLocation";
import AdPostPrice from "../components/AdPostPrice";
import AdPostAdType from "../components/AdPostAdType";
import Specifications from "../components/Specifications";
import AdPostDescription from "../components/AdPostDescription";
import AdPostTags from "../components/AdPostTags";
import AuthorInformation from "../components/AuthorInformation";
import AdPostSubmitBtn from "../components/AdPostSubmitBtn";
import generateId from "../../../utils/generateId";
import { AppState } from "../../../store";
import { LoginState } from "../../login/redux/reducer";

export default function PublishAdForm() {
  const dispatch = useDispatch();
  const { authenticationUID, loggedInUser } = useSelector<AppState, LoginState>((state) => state.loginManagement);

  const [generatedId, setGeneratedId] = useState("");
  useEffect(() => {
    setGeneratedId(generateId());
  }, []);

  const initialValues: PublishAdvertisementInterface = {
    id: generatedId,
    title: "",
    description: "",
    images: [],
    adType: "sales",
    category: "",
    subCategory: "",
    isNegotiable: false,
    location: "",
    subLocation: "",
    postedBy: "",
    price: 0,
    priceUnit: "",
    specifications: {},
    tags: [],
    name: loggedInUser?.name || "",
    email: loggedInUser?.email || "",
    contact: loggedInUser?.contact || []
  };

  const [imageAsUrl1, setImageAsUrl1] = useState<string | undefined>(undefined);
  const [imageAsUrl2, setImageAsUrl2] = useState<string | undefined>(undefined);
  const [imageAsUrl3, setImageAsUrl3] = useState<string | undefined>(undefined);
  const [imageAsUrl4, setImageAsUrl4] = useState<string | undefined>(undefined);
  const [imageAsUrl5, setImageAsUrl5] = useState<string | undefined>(undefined);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yupSchemaForAdPost}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const modifiedValues: PublishAdvertisementInterface = { ...values };
        // @ts-ignore
        modifiedValues.images = [imageAsUrl1, imageAsUrl2, imageAsUrl3, imageAsUrl4, imageAsUrl5].filter((el) => el);
        modifiedValues.id = generatedId;
        modifiedValues.postedBy = authenticationUID || "";

        dispatch(publishAdAction(modifiedValues));
        setGeneratedId(generateId());
        setSubmitting(false);
        // @ts-ignore
        resetForm(initialValues);
        setImageAsUrl1("");
        setImageAsUrl2("");
        setImageAsUrl3("");
        setImageAsUrl4("");
        setImageAsUrl5("");
        const elementById = document.getElementById("postAdPrice");
        if (elementById) {
          // @ts-ignore
          elementById.value = "";
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form className="adpost-form" onSubmit={handleSubmit}>
          <FormikOnError>
            <div className="adpost-card">
              <div className="adpost-title">
                <h3>Ad Information</h3>
              </div>
              <div className="row">
                <AdPostTitle
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <AdPostImageUpload
                  isEditView={false}
                  imageAsUrl1={imageAsUrl1}
                  setImageAsUrl1={setImageAsUrl1}
                  imageAsUrl2={imageAsUrl2}
                  setImageAsUrl2={setImageAsUrl2}
                  imageAsUrl3={imageAsUrl3}
                  setImageAsUrl3={setImageAsUrl3}
                  imageAsUrl4={imageAsUrl4}
                  setImageAsUrl4={setImageAsUrl4}
                  imageAsUrl5={imageAsUrl5}
                  setImageAsUrl5={setImageAsUrl5}
                  adId={generatedId}
                />
                <AdPostCategory
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <AdPostSubCategory
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <AdPostLocation
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <AdPostSubLocation
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* <AdPostGeoCoordinates handleChange={handleChange} handleBlur={handleBlur} /> */}
                {/* <div className="col-lg-6" /> */}
                <AdPostPrice
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
                <AdPostAdType handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} />
                <div className="col-lg-12" style={{ height: 20 }} />
                <Specifications values={values} setFieldValue={setFieldValue} />
                <AdPostDescription
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <AdPostTags handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} />
              </div>
            </div>
            <AuthorInformation loggedInUser={loggedInUser} errors={errors} />
            <AdPostSubmitBtn isSubmitting={isSubmitting} />
          </FormikOnError>
        </form>
      )}
    </Formik>
  );
}
