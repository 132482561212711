/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { AppState } from "../../../store";
import { HomePageState } from "../redux/reducer";
import Pagination from "../components/Pagination";
import Card from "../components/Card";
import FilterByCategories from "../components/FilterByCategories";
import FilterByCities from "../components/FilterByCities";
import FilterByType from "../components/FilterByType";
import PageSummaryTop from "../components/PageSummaryTop";
import { fetchAdvertisementsAction } from "../redux/actions";
import ImageOnlyCard from "../components/ImageOnlyCard";

export default function Home() {
  const dispatch = useDispatch();
  const advertisementsPerPage = (process.env.REACT_APP_ADVERTISEMENTS_PER_PAGE as unknown) as number;
  const { advertisements, totalAds } = useSelector<AppState, HomePageState>((state) => state.homePageManagement);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    setTotalPages(Math.ceil(totalAds / advertisementsPerPage));
  }, [advertisementsPerPage, totalAds]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryFromUrl = urlSearchParams.get("query");
  const categoryFromUrl = urlSearchParams.get("category");
  const subCategoryFromUrl = urlSearchParams.get("subCategory");
  const citiesFromUrl = urlSearchParams.get("cities");
  const adTypesFromUrl = urlSearchParams.get("adTypes");
  const currentPageFromUrl = urlSearchParams.get("page");

  useEffect(() => {
    dispatch(
      fetchAdvertisementsAction(
        queryFromUrl || "",
        categoryFromUrl || "",
        subCategoryFromUrl || "",
        (citiesFromUrl || "").split(",").filter((el) => el.length > 0),
        (adTypesFromUrl || "").split(",").filter((el) => el.length > 0),
        parseInt(currentPageFromUrl || "1")
      )
    );
  }, [queryFromUrl, categoryFromUrl, subCategoryFromUrl, citiesFromUrl, adTypesFromUrl, currentPageFromUrl, dispatch]);

  useEffect(() => {
    if (currentPageFromUrl) {
      if (currentPageFromUrl.toString() !== currentPage.toString()) {
        setCurrentPage(parseInt(currentPageFromUrl));
      }
    } else {
      setCurrentPage(1);
    }
  }, [currentPageFromUrl]);

  return (
    <section
      className="ad-list-part"
      style={{
        // backgroundColor: "white",
        paddingTop: 29,
        paddingBottom: 48
      }}
    >
      <Helmet>
        <title>Aduwata.lk - Cars, Electronics, Houses, Services and Jobs in Sri Lanka</title>
        <meta
          content="classicads, classified, ads, classified ads, listing, business, directory, jobs, marketing, portal, advertising, local, posting, ad listing, ad posting,"
          name="keywords"
        />
        <meta
          content="හොදම බඩු මිලදී ගන්න-ඕනෑම දෙයක් විකුණගන්න අප හා එක්වන්න...Visit us on http://aduwata.lk Like our fan page https://web.facebook.com/aduwataclassifieds"
          name="description"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aduwata.lk" />
        <meta property="og:title" content="Aduwata.lk - Cars, Electronics, Houses, Services and Jobs in Sri Lanka" />
        <meta
          property="og:description"
          content="හොදම බඩු මිලදී ගන්න-ඕනෑම දෙයක් විකුණගන්න අප හා එක්වන්න...Visit us on http://aduwata.lk Like our fan page https://web.facebook.com/aduwataclassifieds"
        />
        <meta property="og:image" content="https://aduwata.lk/images/logo.webp" />
      </Helmet>

      <div className="container">
        <PageSummaryTop totalAds={totalAds} currentPage={currentPage} />

        <div className="row content-reverse">
          <div className="col-lg-3">
            <div className="row">
              <FilterByCategories />
              <FilterByCities />
              <FilterByType />
            </div>
          </div>
          <div className="col-lg-9">
            <div className="row" style={{ padding: window.innerWidth < 576 ? "0 3px" : 0 }}>
              {advertisements.map((ad, index) => {
                if (ad.title) {
                  return <Card ad={ad} key={index} />;
                }
                return <ImageOnlyCard ad={ad} key={index} />;
              })}
              {advertisements.length === 0 && (
                <p
                  style={{
                    textAlign: "center",
                    width: "100%",
                    paddingTop: "20vh"
                  }}
                >
                  No data found
                </p>
              )}
            </div>

            {advertisements.length > 0 && (
              <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
