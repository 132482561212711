import React from "react";
import errorStyles from "../../../config/errorStyles";

// @ts-ignore
export default function AdPostDescription(props) {
  const { handleChange, handleBlur, values, errors, touched } = props;

  return (
    <div className="col-lg-12">
      <div className="form-group">
        <label className="form-label">
          ad description<span style={{ color: "red" }}> *</span>
        </label>
        <textarea
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          className="form-control"
          placeholder="Describe your message"
        />
        {errors.description && touched.description && <p style={errorStyles.err}>{errors.description}</p>}
      </div>
    </div>
  );
}
