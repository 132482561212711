/* eslint-disable no-param-reassign */
import produce from "immer";
import { ContactUsPageActions, contactUsPageActionTypes } from "./actions";

export interface ContactUsPageState {
  sendMessageToAdminIsLoading: boolean;
  sendMessageToAdminError: { hasError: boolean; description: string };
}

const initialState: ContactUsPageState = {
  sendMessageToAdminIsLoading: false,
  sendMessageToAdminError: { description: "", hasError: false }
};

export default function contactUsPageReducer(
  state: ContactUsPageState = initialState,
  action: ContactUsPageActions
): ContactUsPageState {
  return produce(state, (draft) => {
    switch (action.type) {
      case contactUsPageActionTypes.SEND_MESSAGE_TO_ADMIN: {
        draft.sendMessageToAdminIsLoading = true;
        break;
      }
      case contactUsPageActionTypes.SEND_MESSAGE_TO_ADMIN_SUCCESS: {
        draft.sendMessageToAdminIsLoading = false;
        draft.sendMessageToAdminError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case contactUsPageActionTypes.SEND_MESSAGE_TO_ADMIN_FAILURE: {
        draft.sendMessageToAdminIsLoading = false;
        draft.sendMessageToAdminError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      default:
        break;
    }
  });
}
