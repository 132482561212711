import { takeLatest } from "redux-saga/effects";
import { contactUsPageActionTypes } from "../actions";
import callSendMessageToAdminSaga from "./callSendMessageToAdminSaga";

function* getContactUsSagas(): any {
  yield takeLatest(contactUsPageActionTypes.SEND_MESSAGE_TO_ADMIN, callSendMessageToAdminSaga);
}

// eslint-disable-next-line import/prefer-default-export
export const contactUsPageSagas = [getContactUsSagas];
