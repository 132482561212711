// @ts-nocheck
import { call, put } from "redux-saga/effects";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";
import {
  FetchSingleAdvertisementAction,
  fetchSingleAdvertisementFailureAction,
  fetchSingleAdvertisementSuccessAction
} from "../actions";

async function fetchSingleAdvertisementFromApi(id: string) {
  return fetch(`${process.env.REACT_APP_BASE_URL}/ad/${id}`, {
    method: "GET"
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callFetchSingleAdSaga(action: FetchSingleAdvertisementAction) {
  try {
    const result = yield call(fetchSingleAdvertisementFromApi, action.id);
    if (result.status === "success") {
      const receivedAdvertisement: ReceivedAdvertisementInterface = {
        id: result.item?.id,
        url: result.item?.url,
        adType: result.item?.adType,
        category: result.item?.category,
        description: result.item?.description,
        isNegotiable: result.item?.isNegotiable || false,
        location: result.item?.location,
        postedOn: new Date(result.item?.postedOn),
        price: result.item?.price || 0,
        priceUnit: result.item?.priceUnit,
        specifications: result.item?.specifications || {},
        subCategory: result.item?.subCategory,
        subLocation: result.item?.subLocation,
        tags: result.item?.tags || [],
        title: result.item?.title,
        images: result.item?.images || [],
        author: {
          uid: result.item?.author?.uid,
          url: result.item?.author?.url,
          name: result.item?.author?.name,
          shortDescription: result.item?.shortDescription?.name,
          address: result.item?.author?.address,
          image: result.item?.author?.image,
          joinedDate: new Date(result.item?.author?.joinedDate),
          contact: result.item?.author?.contact || [],
          openHours: result.item?.author?.openHours
            ? {
              monday: result.item?.author?.openHours?.monday,
              tuesday: result.item?.author?.openHours?.tuesday,
              wednesday: result.item?.author?.openHours?.wednesday,
              thursday: result.item?.author?.openHours?.thursday,
              friday: result.item?.author?.openHours?.friday,
              saturday: result.item?.author?.openHours?.saturday,
              sunday: result.item?.author?.openHours?.sunday
            }
            : undefined
        }
      };

      yield put(fetchSingleAdvertisementSuccessAction(receivedAdvertisement));
    } else {
      yield put(fetchSingleAdvertisementSuccessAction(undefined));
    }
  } catch (e) {
    yield put(fetchSingleAdvertisementFailureAction(e.toString()));
  }
}
