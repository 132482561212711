import React from "react";

export default function Error() {
  return (
    <section className="error-part">
      <div className="container" style={{ height: 429 }}>
        <div className="error">
          <h1>404</h1>
          <h2>Oops! Something Went Wrong?</h2>
          <a href="/" className="btn btn-outline">
            <span>go to homepage</span>
          </a>
        </div>
      </div>
    </section>
  );
}
