import { call, put, select } from "redux-saga/effects";
import { FetchCustomUserAction, fetchCustomUserFailureAction, fetchCustomUserSuccessAction } from "../actions";
import User from "../../../../shared/userInterface";

async function fetchCustomUserFromApi(userUrl: string) {
  return fetch(`${process.env.REACT_APP_BASE_URL}/user/url/${userUrl}`, {
    method: "GET"
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callFetchCustomUserSaga(action: FetchCustomUserAction) {
  const customUser: User | undefined = yield select((state) => state.userManagement.customUser);
  if (action.userUrl || customUser?.url) {
    try {
      // @ts-ignore
      const result = yield call(fetchCustomUserFromApi, action.userUrl || customUser?.url);
      if (result.status === "success") {
        const rUser = result.user;
        const user: User = {
          address: rUser.address,
          openHours: rUser.openHours
            ? {
              monday: rUser.openHours.monday,
              tuesday: rUser.openHours.tuesday,
              wednesday: rUser.openHours.wednesday,
              thursday: rUser.openHours.thursday,
              friday: rUser.openHours.friday,
              saturday: rUser.openHours.saturday,
              sunday: rUser.openHours.sunday
            }
            : undefined,
          shortDescription: rUser.shortDescription,
          contact: rUser.contact || [],
          description: rUser.description,
          email: rUser.email,
          image: rUser.image,
          isArchive: rUser.isArchive,
          joinedDate: new Date(rUser.joinedDate),
          name: rUser.name,
          role: rUser.role,
          type: rUser.type,
          uid: rUser.uid,
          url: rUser.url
        };
        yield put(fetchCustomUserSuccessAction(user));
      } else {
        yield put(fetchCustomUserSuccessAction(undefined));
      }
    } catch (e) {
      yield put(fetchCustomUserFailureAction(e.toString()));
    }
  }
}
