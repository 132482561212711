import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { COLOR_OFF_BLACK, COLOR_PRIMARY_BLUE } from "../../../config/colorProfile";
import { AppState } from "../../../store";
import { RemoteConfigState } from "../../../utils/remoteConfigurations";
import { HomePageState } from "../redux/reducer";
import searchByCategoryAndSubCategory from "../../../utils/searchByCategoryAndSubCategory";

export default function FilterByCategories(): JSX.Element {
  const history = useHistory();
  const { categories } = useSelector<AppState, RemoteConfigState>((state) => state.remoteConfigManagement);
  const { categoryCounts } = useSelector<AppState, HomePageState>((state) => state.homePageManagement);
  const { t } = useTranslation();
  const refs = useRef(new Array(Object.keys(categories).length));
  const [category, setCategory] = useState<string>("");
  const [subCategory, setSubCategory] = useState<string>("");

  const urlSearchParams = new URLSearchParams(window.location.search);
  const categoryFromUrl = urlSearchParams.get("category");
  const subCategoryFromUrl = urlSearchParams.get("subCategory");
  useEffect(() => {
    if (category !== categoryFromUrl) {
      setCategory(categoryFromUrl || "");
    }
    if (subCategory !== subCategoryFromUrl) {
      setSubCategory(subCategoryFromUrl || "");
    }
  }, [categoryFromUrl, subCategoryFromUrl]);

  return (
    <div className="col-md-6 col-lg-12" style={{ padding: "0 6px" }}>
      <div className="product-sidebar" style={{ backgroundColor: "white" }}>
        <div className="product-sidebar-title">
          <h6>Filter by Categories</h6>
        </div>
        <div className="product-sidebar-content">
          <ul className="nasted-dropdown">
            {Object.keys(categories).map((currentCategory, i) => (
              <li key={currentCategory}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  ref={(el) => (refs.current[i] = el)}
                  className="nasted-menu"
                  onClick={() => {
                    refs.current[i].scrollIntoView({
                      block: "nearest",
                      inline: "start"
                    });
                    searchByCategoryAndSubCategory(currentCategory, "", setCategory, setSubCategory, history);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    <img
                      src={`/images/category/${currentCategory}.png`}
                      alt={currentCategory}
                      width={20}
                      height={20}
                      style={{ marginRight: 5 }}
                    />
                    <p
                      style={{
                        fontWeight: category === currentCategory ? "bold" : "unset",
                        color: category === currentCategory ? COLOR_PRIMARY_BLUE : ""
                      }}
                    >
                      {`${t(currentCategory)}`}
                      {(categoryCounts[currentCategory] || {}).total && ` (${categoryCounts[currentCategory].total})`}
                    </p>
                  </div>
                  {categories[currentCategory].length > 0 && <i className="fa fa-chevron-down" />}
                </div>
                <ul className="nasted-menu-list" style={{ display: category === currentCategory ? "block" : "none" }}>
                  {categories[currentCategory].map((currentSubCategory: any, index: number) => (
                    <li key={index}>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                      <p
                        onClick={() => {
                          searchByCategoryAndSubCategory(
                            currentCategory,
                            currentSubCategory,
                            setCategory,
                            setSubCategory,
                            history
                          );
                        }}
                        style={{
                          fontSize: 14,
                          padding: "6px 0",
                          cursor: "pointer",
                          fontWeight: subCategory === currentSubCategory ? "bold" : "unset",
                          color: subCategory === currentSubCategory ? COLOR_PRIMARY_BLUE : COLOR_OFF_BLACK
                        }}
                      >
                        {`${t(currentSubCategory)}`}
                        {(categoryCounts[currentCategory] || {})[currentSubCategory] &&
                        ` (${(categoryCounts[currentCategory] || {})[currentSubCategory]})`}
                      </p>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <button
            type="button"
            className="product-filter-btn"
            onClick={() => {
              searchByCategoryAndSubCategory("", "", setCategory, setSubCategory, history);
            }}
          >
            <span>Clear Filter</span>
          </button>
        </div>
      </div>
    </div>
  );
}
