// eslint-disable-next-line import/prefer-default-export
export const CATEGORIES = {
  electronics: [
    "mobiles",
    "tablets",
    "mobile_accessories",
    "computers",
    "computer_accessories",
    "cameras",
    "camera_accessories",
    "other"
  ],
  automobile: [
    "cars",
    "motorbikes",
    "three_wheelers",
    "bicycles",
    "vans",
    "buses",
    "lorries",
    "trucks",
    "heavy_duty",
    "tractors",
    "accessories_parts",
    "water_transport",
    "other"
  ],
  properties: [
    "land",
    "house",
    "apartment",
    "room_annex",
    "holiday_short_term_resorts",
    "commercial_properties",
    "other"
  ],
  furniture_and_homewares: [
    "furniture",
    "lighting",
    "tv_video_devices",
    "home_audio",
    "washing_machines",
    "refrigerators",
    "gas_burners",
    "kitchen_appliances",
    "fan_cooling",
    "vacuums_floor_care",
    "irons",
    "other"
  ],
  fashion: [
    "mens_clothing",
    "women_clothing",
    "kids_clothing",
    "bags",
    "shoes",
    "mens_watches",
    "women_watches",
    "sunglasses",
    "jewellery",
    "other"
  ],
  sports_accessories: [
    "shoes",
    "clothing",
    "exercise_fitness",
    "water_sports",
    "racket_sports",
    "team_sports",
    "other"
  ],
  animals: ["cat", "dog", "fish", "birds", "animal_accessories", "animal_foods", "other"],
  food: ["vegetables", "fruits", "baby_products", "beverages", "cooking_essentials", "frozen_foods", "other"],
  education: ["higher_education", "textbooks", "tuition", "other"],
  services: [],
  other: []
};
