import React from "react";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";
import { COLOR_TEXT_INFO } from "../../../../config/colorProfile";

export default function ViewAdDescriptionCard(props: { currentAd: ReceivedAdvertisementInterface }) {
  const { currentAd } = props;
  return (
    <div className="ad-details-card">
      <div className="ad-details-title">
        <h5>description</h5>
      </div>
      <div className="ad-details-descrip">
        <p style={{ whiteSpace: "pre-line", color: COLOR_TEXT_INFO }}>{currentAd.description}</p>
      </div>
    </div>
  );
}
