import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { RemoteConfigState } from "../../../utils/remoteConfigurations";
import errorStyles from "../../../config/errorStyles";

// @ts-ignore
export default function AdPostSubCategory(props) {
  const { handleChange, handleBlur, values, errors, touched } = props;
  const { t } = useTranslation();
  const { categories } = useSelector<AppState, RemoteConfigState>((state) => state.remoteConfigManagement);

  return (
    <div className="col-lg-6">
      <div className="form-group">
        <label className="form-label">
          Sub Category<span style={{ color: "red" }}> *</span>
        </label>
        <select
          className="form-control custom-select"
          name="subCategory"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.subCategory}
        >
          <option value="">Select Sub Category</option>
          {(categories[values.category] || []).map((currentSubCategory: any, index: number) => (
            <option key={index} value={currentSubCategory}>
              {t(currentSubCategory)}
            </option>
          ))}
        </select>
        {errors.subCategory && touched.subCategory && <p style={errorStyles.err}>{errors.subCategory}</p>}
      </div>
    </div>
  );
}
