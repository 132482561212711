import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { DeleteAdAction, deleteAdFailureAction, deleteAdSuccessAction } from "../actions";
import getCurrentUserAuthorization from "../../../../utils/getAuthorizationToken";

async function deleteAdApiCall(adId: string, reason: string, authToken: string) {
  const body = {
    reason
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/ad/${adId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${authToken}`
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callDeleteAdSaga(action: DeleteAdAction) {
  const authToken: string = yield call(getCurrentUserAuthorization);

  try {
    // @ts-ignore
    const result = yield call(deleteAdApiCall, action.id, action.reason, authToken);
    if (result.status === "success") {
      toast.success("You have successfully deleted the ad.");
      yield put(deleteAdSuccessAction());
    } else {
      throw new Error(JSON.stringify(result));
    }
  } catch (e) {
    toast.error(`An error occurred, Please try again later.`);
    yield put(deleteAdFailureAction(e.toString()));
  }
}
