// @ts-ignore
import React, { useState } from "react";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";

export default function ViewAdNumberCard(props: { currentAd: ReceivedAdvertisementInterface }) {
  const { currentAd } = props;
  const [isContactNumberShowing, setContactNumberShowing] = useState(false);

  return (
    <button
      type="button"
      className="ad-details-number"
      onClick={() => setContactNumberShowing(true)}
      style={{ minHeight: 76, justifyContent: "center" }}
    >
      {isContactNumberShowing ? (
        <h5
          style={{
            fontSize: "25px",
            padding: 7
          }}
        >
          {(currentAd.author?.contact || []).map((el, index) => (
            <a
              key={index}
              href={`tel:${el}}`}
              style={{
                fontSize: "25px",
                color: "white",
                lineHeight: "30px",
                width: "100%",
                padding: "2px 0"
              }}
            >
              {`(${el.substring(0, 3)}) ${el.substring(3, 6)} ${el.substring(6, 10)}`}
            </a>
          ))}
        </h5>
      ) : (
        <div className="d-flex flex-row">
          <i className="fa fa-phone" />
          <span>Click to show the number</span>
        </div>
      )}
    </button>
  );
}
