import React from "react";
import electronicsMobiles from "./Specifications/electronicsMobiles";

// @ts-ignore
export default function Specifications(props) {
  const { values, setFieldValue } = props;
  const { category, subCategory } = values;

  switch (`${category}:${subCategory}`) {
    case "electronics:mobiles":
      return electronicsMobiles(setFieldValue);
    case "electronics:tablets":
    case "electronics:mobile_accessories":
    case "electronics:computers":
    case "electronics:computer_accessories":
    case "electronics:cameras":
    case "electronics:camera_accessories":
    case "electronics:other":
    case "automobile":
    case "properties":
    case "furniture_and_homewares":
    case "fashion":
    case "sports_accessories":
    case "animals":
    case "food":
    case "education":
    case "services":
    default:
      return <div />;
  }
}
