import { call, put } from "redux-saga/effects";
import { fetchAboutUsCountsFailureAction, fetchAboutUsCountsSuccessAction } from "../actions";

async function fetchAboutUsCountsApiCall() {
  return fetch(`${process.env.REACT_APP_BASE_URL}/about-us-counts`, {
    method: "GET"
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callFetchAboutUsCountsSaga() {
  try {
    // @ts-ignore
    const result = yield call(fetchAboutUsCountsApiCall);

    yield put(
      fetchAboutUsCountsSuccessAction(
        result.registeredUsers || 0,
        result.communityAds || 0,
        result.soldOnThisWebsite || 0
      )
    );
  } catch (e) {
    yield put(fetchAboutUsCountsFailureAction(e.toString()));
  }
}
