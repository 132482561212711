import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function PageSummaryTop(props: { totalAds: number; currentPage: number }): JSX.Element {
  const { totalAds, currentPage } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const advertisementsPerPage = (process.env.REACT_APP_ADVERTISEMENTS_PER_PAGE as unknown) as number;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const categoryFromUrl = urlSearchParams.get("category");
  const subCategoryFromUrl = urlSearchParams.get("subCategory");
  const citiesFromUrl = urlSearchParams.get("cities");
  const adTypesFromUrl = urlSearchParams.get("adTypes");

  const cityArray = (citiesFromUrl || "").split(",");
  const adTypesArray = (adTypesFromUrl || "").split(",");
  const path = [categoryFromUrl, subCategoryFromUrl]
    .concat(cityArray)
    .concat(adTypesArray)
    .filter((el) => el)
    .map((el) => t(`${el}`));

  return (
    <div className="row">
      <div className="col-lg-12" style={{ padding: "0 6px" }}>
        {path.length > 0 ? (
          <>
            {path.map((el, index) => (
              <button key={index} type="button" className="home-page-filters" style={{ marginRight: 5 }}>
                {el}
                <i className="fa fa-times-circle ml-2" aria-hidden="true" />
              </button>
            ))}
            <button type="button" className="home-page-filters-clear-all" onClick={() => history.push("/ads/home")}>
              Clear All
            </button>
          </>
        ) : (
          <button type="button" className="home-page-filters" style={{ padding: "0 10px" }}>
            All ads in Sri Lanka
          </button>
        )}
        <div className="product-filter" style={{ backgroundColor: "white" }}>
          <div className="product-page-number">
            <p>
              {totalAds === 0
                ? `Showing 0–0 of 0 results`
                : `Showing ${advertisementsPerPage * (currentPage - 1) + 1}–${Math.min(
                  advertisementsPerPage * currentPage,
                  totalAds
                )} of Total Result`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
