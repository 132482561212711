// eslint-disable-next-line @typescript-eslint/no-var-requires
const dateFormat = require("dateformat");

export function getPrintableDate(date: Date): string {
  try {
    return dateFormat(date, "yyyy-mmm-dd h:MM TT");
  } catch (e) {
    return "Now";
  }
}

export function getPrintableDate2(date: Date | undefined): string {
  try {
    return dateFormat(date, "mmmm d, yyyy");
  } catch (e) {
    return "";
  }
}
