import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { RemoteConfigState } from "../../../utils/remoteConfigurations";
import errorStyles from "../../../config/errorStyles";

// @ts-ignore
export default function AdPostCategory(props) {
  const { setFieldValue, handleBlur, values, errors, touched } = props;
  const { t } = useTranslation();
  const { categories } = useSelector<AppState, RemoteConfigState>((state) => state.remoteConfigManagement);

  return (
    <div className="col-lg-6">
      <div className="form-group">
        <label className="form-label">
          Category<span style={{ color: "red" }}> *</span>
        </label>
        <select
          className="form-control custom-select"
          name="category"
          onChange={(e) => {
            setFieldValue("category", e.target.value);
            setFieldValue("subCategory", "");
          }}
          onBlur={handleBlur}
          value={values.category}
        >
          <option value="">Select Category</option>
          {Object.keys(categories).map((currentCategory, index) => (
            <option key={index} value={currentCategory}>
              {t(currentCategory)}
            </option>
          ))}
        </select>
        {errors.category && touched.category && <p style={errorStyles.err}>{errors.category}</p>}
      </div>
    </div>
  );
}
