import { call, put } from "redux-saga/effects";
import {
  SignInWithEmailAndPasswordAction,
  signInWithEmailAndPasswordFailureAction,
  signInWithEmailAndPasswordSuccessAction
} from "../actions";
import { auth } from "../../../../config/firebase";

async function signInWithEmailAndPasswordInFirebase(email: string, password: string) {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then((res) => res)
    .catch((error) => error.code);
}

export default function* callSignInWithEmailAndPasswordSaga(action: SignInWithEmailAndPasswordAction) {
  try {
    // @ts-ignore
    const result = yield call(signInWithEmailAndPasswordInFirebase, action.email, action.password);
    if (result.user) {
      yield put(signInWithEmailAndPasswordSuccessAction(result.user?.uid));
    } else if (result === "auth/user-not-found") {
      yield put(signInWithEmailAndPasswordFailureAction("auth/user-not-found"));
    } else if (result === "auth/wrong-password") {
      yield put(signInWithEmailAndPasswordFailureAction("auth/wrong-password"));
    } else if (result === "auth/too-many-requests") {
      yield put(signInWithEmailAndPasswordFailureAction("auth/too-many-requests"));
    } else {
      yield put(signInWithEmailAndPasswordFailureAction(result));
    }
  } catch (e) {
    yield put(signInWithEmailAndPasswordFailureAction(e.toString()));
  }
}
