import { Formik } from "formik";
import { PulseLoader } from "react-spinners";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { LoginState } from "../redux/reducer";
import {
  resetLoginErrorAction,
  signInWithEmailAndPasswordAction,
  signInWithFacebookAction,
  signInWithGoogleAction
} from "../redux/actions";
import { COLOR_LIGHT_BLUE, COLOR_PURPLE, COLOR_RED } from "../../../config/colorProfile";
import errorStyles from "../../../config/errorStyles";
import { facebook, google } from "../../../assets/logo";
import ForgetPasswordModal from "./ForgetPasswordModal";

export default function LoginForm(props: { setSignUpForm: any }) {
  const { setSignUpForm } = props;
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    dispatch(resetLoginErrorAction());
  }, [dispatch]);

  const {
    signInWithEmailAndPasswordError: { hasError: signInWithEmailAndPasswordHasError },
    signInWithGoogleError: { hasError: signInWithGoogleHasError },
    signInWithFacebookError: { hasError: signInWithFacebookHasError },
    signInWithEmailAndPasswordIsLoading
  } = useSelector<AppState, LoginState>((state) => state.loginManagement);

  const yupSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email").required("Email is required"),
    password: Yup.string().required("Password is required")
  });

  const initialValue = {
    email: "",
    password: ""
  };

  return (
    <div className="tab-pane active" id="login-tab">
      <ForgetPasswordModal />
      <div className="user-form-title">
        <h2>Welcome!</h2>
        <p>Use credentials to access your account.</p>
      </div>
      <Formik
        initialValues={initialValue}
        validationSchema={yupSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(signInWithEmailAndPasswordAction(values.email, values.password));
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className="form-control"
                    placeholder="Email"
                  />
                  {errors.email && touched.email && <p style={errorStyles.err}>{errors.email}</p>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    className="form-control"
                    placeholder="Password"
                  />
                  <button
                    type="button"
                    className="form-icon"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ marginTop: 2 }}
                  >
                    <i className={showPassword ? "eye fa fa-eye-slash" : "eye fa fa-eye"} />
                  </button>
                  {errors.password && touched.password && <p style={errorStyles.err}>{errors.password}</p>}
                </div>
              </div>
              <div className="col-6" />
              <div className="col-6">
                <div className="form-group text-right">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <button
                    style={{
                      background: "transparent",
                      border: "none"
                    }}
                    type="button"
                    className="form-forgot"
                    data-toggle="modal"
                    data-target="#forgetPasswordModal"
                  >
                    Forgot password?
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  {signInWithEmailAndPasswordHasError && (
                    <p
                      style={{
                        color: COLOR_RED,
                        textAlign: "center"
                      }}
                    >
                      Sorry, Your User ID/email or password is incorrect.
                      <br /> Please try again.
                    </p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <button
                    style={{
                      fontSize: "15px",
                      backgroundColor: COLOR_PURPLE,
                      borderColor: COLOR_PURPLE
                    }}
                    type="submit"
                    className="btn btn-inline"
                    disabled={isSubmitting}
                  >
                    {signInWithEmailAndPasswordIsLoading ? (
                      <div
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex"
                        }}
                      >
                        <PulseLoader size={10} margin={1} color={COLOR_LIGHT_BLUE} loading />
                      </div>
                    ) : (
                      <span>Sign in</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <p
        style={{
          fontSize: "15px",
          textAlign: "center",
          marginBottom: "20px",
          fontWeight: 500
        }}
      >
        - or -
      </p>
      <div className="col-12" style={{ padding: 0 }}>
        <div className="form-group">
          <button
            type="button"
            className="btn btn-inline"
            style={{ height: 45, backgroundColor: "transparent", color: "#2681db" }}
            onClick={() => dispatch(signInWithGoogleAction())}
          >
            {google}
            <span>Sign in with Google</span>
          </button>
        </div>
      </div>
      <div className="col-12" style={{ padding: 0 }}>
        <div className="form-group">
          <button
            type="button"
            className="btn btn-inline"
            style={{ height: 45, backgroundColor: "transparent", color: "#2681db" }}
            onClick={() => dispatch(signInWithFacebookAction())}
          >
            {facebook}
            <span>Sign in with Facebook</span>
          </button>
        </div>
      </div>
      <div className="col-12">
        <div className="form-group">
          {(signInWithGoogleHasError || signInWithFacebookHasError) && (
            <p
              style={{
                color: COLOR_RED,
                textAlign: "center"
              }}
            >
              Your email is associated with another sign in method.
              <br /> Please use a different sign in method
            </p>
          )}
        </div>
      </div>
      <div className="user-form-direction">
        <p>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Don't have an account? click on the
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span
            style={{
              cursor: "pointer"
            }}
            onClick={() => setSignUpForm(true)}
          >
            &nbsp;( sign up )&nbsp;
          </span>
          button.
        </p>
      </div>
    </div>
  );
}
