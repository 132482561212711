import React from "react";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";

export default function ViewAdOpeningHours(props: { currentAd: ReceivedAdvertisementInterface }) {
  const { currentAd } = props;
  return (
    <div className="ad-details-card">
      <div className="ad-details-title">
        <h5>opening hours</h5>
      </div>
      <div className="ad-details-opening">
        <ul>
          <li>
            <h6>monday</h6>
            <p>{currentAd.author.openHours?.monday || "-"}</p>
          </li>
          <li>
            <h6>tuesday</h6>
            <p>{currentAd.author.openHours?.tuesday || "-"}</p>
          </li>
          <li>
            <h6>wednesday</h6>
            <p>{currentAd.author.openHours?.wednesday || "-"}</p>
          </li>
          <li>
            <h6>thursday</h6>
            <p>{currentAd.author.openHours?.thursday || "-"}</p>
          </li>
          <li>
            <h6>friday</h6>
            <p>{currentAd.author.openHours?.friday || "-"}</p>
          </li>
          <li>
            <h6>Saturday</h6>
            <p>{currentAd.author.openHours?.saturday || "-"}</p>
          </li>
          <li>
            <h6>Sunday</h6>
            <p>{currentAd.author.openHours?.sunday || "-"}</p>
          </li>
        </ul>
      </div>
    </div>
  );
}
