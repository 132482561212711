import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { all, fork } from "redux-saga/effects";
import remoteConfigReducer, { remoteConfigSagas, RemoteConfigState } from "./utils/remoteConfigurations";
import userReducer, { UserState } from "./views/user/redux/reducer";
import { userSagas } from "./views/user/redux/sagas";
import homePageReducer, { HomePageState } from "./views/home/redux/reducer";
import { homePageSagas } from "./views/home/redux/sagas";
import advertisementReducer, { AdvertisementState } from "./views/advertisement/redux/reducer";
import { advertisementSagas } from "./views/advertisement/redux/sagas";
import aboutUsPageCountsReducer, { AboutUsPageState } from "./views/aboutUs/redux/reducer";
import { aboutUsPageSagas } from "./views/aboutUs/redux/sagas";
import contactUsPageReducer, { ContactUsPageState } from "./views/contactUs/redux/reducer";
import { contactUsPageSagas } from "./views/contactUs/redux/sagas";
import loginReducer, { LoginState } from "./views/login/redux/reducer";
import { loginSagas } from "./views/login/redux/sagas";

export interface AppState {
  remoteConfigManagement: RemoteConfigState;
  loginManagement: LoginState;
  userManagement: UserState;
  homePageManagement: HomePageState;
  advertisementManagement: AdvertisementState;
  aboutUsPageManagement: AboutUsPageState;
  contactUsPageManagement: ContactUsPageState;
}

const rootReducer = combineReducers<AppState>({
  remoteConfigManagement: remoteConfigReducer,
  loginManagement: loginReducer,
  userManagement: userReducer,
  homePageManagement: homePageReducer,
  advertisementManagement: advertisementReducer,
  aboutUsPageManagement: aboutUsPageCountsReducer,
  contactUsPageManagement: contactUsPageReducer
});

function* rootSaga() {
  yield all(remoteConfigSagas.map((s) => fork(s)));
  yield all(loginSagas.map((s) => fork(s)));
  yield all(userSagas.map((s) => fork(s)));
  yield all(homePageSagas.map((s) => fork(s)));
  yield all(advertisementSagas.map((s) => fork(s)));
  yield all(aboutUsPageSagas.map((s) => fork(s)));
  yield all(contactUsPageSagas.map((s) => fork(s)));
}

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const persistConfig = {
  key: "root",
  storage
  // whitelist: ['remoteConfigReducer']
};
const persistedReducer = persistReducer(persistConfig, rootReducer); // create a persisted reducer

const store = createStore(persistedReducer, {}, compose(applyMiddleware(...middleware)));
sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);

export { store, persistor };
