// @ts-nocheck
import React, { useEffect } from "react";
import errorStyles from "../../../config/errorStyles";

export default function AdPostPrice(props) {
  const { handleChange, handleBlur, values, errors, touched, setFieldValue } = props;
  const reference = React.createRef();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const wheelHandler = () => {
  };

  useEffect(() => {
    const reff = reference.current;
    reff.addEventListener("wheel", wheelHandler);
    return () => reff.removeEventListener("wheel", wheelHandler);
  }, [reference]);

  return (
    <div className="col-lg-6">
      <div className="form-group">
        <label className="form-label">Price (Rs.)</label>
        <input
          id="postAdPrice"
          ref={reference}
          name="price"
          onChange={handleChange}
          onBlur={handleBlur}
          // value={values.price}
          type="number"
          min="0"
          className="form-control"
          placeholder="0.00"
        />
        {errors.price && touched.price && <p style={errorStyles.err}>{errors.price}</p>}
      </div>
      <div className="form-group">
        <ul className="form-check-list">
          <li>
            <input
              type="checkbox"
              className="form-check"
              id="nego-check"
              checked={values.isNegotiable}
              onChange={(e) => {
                if (e.target.checked) {
                  setFieldValue("isNegotiable", true);
                } else {
                  setFieldValue("isNegotiable", false);
                }
              }}
              onBlur={handleBlur}
            />
            <label htmlFor="nego-check" className="form-check-text">
              negotiable
            </label>
          </li>
        </ul>
      </div>
    </div>
  );
}
