import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import getCurrentUserAuthorization from "../../../utils/getAuthorizationToken";
import { getThumbnailImg } from "../../../utils/imageUrlGenerator";

async function uploadFile(file: any, adId: string) {
  const formData = new FormData();
  formData.append("image", file);
  formData.append("adId", adId);

  const authToken = await getCurrentUserAuthorization();
  return fetch(`${process.env.REACT_APP_BASE_URL}/image/ad`, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      Authorization: `bearer ${authToken}`
    },
    body: formData
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function ImgPreview(props: {
  _id: string;
  isEditView: boolean;
  imageAsUrl: string | undefined;
  setImageAsUrl: any;
  adId: string;
}) {
  const { _id, isEditView, imageAsUrl, setImageAsUrl, adId } = props;
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<undefined | string>(undefined);

  useEffect(() => {
    if (imageAsUrl) {
      if (isEditView) {
        setImagePreview(getThumbnailImg(imageAsUrl));
      }
    } else {
      setImagePreview(undefined);
    }
  }, [imageAsUrl, isEditView]);

  const handleImageAsFile = async (e: any) => {
    setImageIsLoading(true);
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      const res = await uploadFile(file, adId);

      if (res.status === "success") {
        setImageAsUrl(res.id);
      }
    }
    setImageIsLoading(false);
  };

  return (
    <div
      id={_id}
      style={{
        margin: "0 12px 12px 0",
        width: 100,
        height: 75,
        position: "relative",
        border: "2px solid lightgray",
        borderRadius: 5,
        backgroundImage: imagePreview ? `url('${imagePreview}')` : "unset",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        opacity: imageAsUrl ? 1 : 0.5
      }}
    >
      <>
        <input
          id={`uploadImg${_id}`}
          type="file"
          accept="image/*"
          onChange={handleImageAsFile}
          style={{ opacity: 0, width: 96, height: 71 }}
        />
        {imageIsLoading && (
          <div
            className="d-flex justify-content-center align-items-center h-100 w-100"
            style={{
              position: "absolute",
              top: 0,
              left: 0
            }}
          >
            <ClipLoader size={30} color="lightgray" />
          </div>
        )}
        {!imageAsUrl && !imageIsLoading && (
          <label
            htmlFor={`uploadImg${_id}`}
            style={{
              position: "absolute",
              top: 22,
              left: 9,
              fontSize: "13px"
            }}
          >
            Upload Image
          </label>
        )}
        {imageAsUrl && !imageIsLoading && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            onClick={() => setImageAsUrl(undefined)}
            style={{
              position: "absolute",
              top: -10,
              left: 88
            }}
          >
            <i
              className="fa fa-times-circle"
              aria-hidden="true"
              style={{ fontSize: "22px", backgroundColor: "white", height: "18px", width: "18px", borderRadius: "50%" }}
            />
          </div>
        )}
      </>
    </div>
  );
}
