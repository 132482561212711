import { call, put } from "redux-saga/effects";
import firebase from "firebase";
import { signInWithFacebookFailureAction, signInWithFacebookSuccessAction } from "../actions";
import { auth } from "../../../../config/firebase";
import createFirestoreUserDoc from "./createFirestoreUserDoc";
import getCurrentUserAuthorization from "../../../../utils/getAuthorizationToken";

const provider = new firebase.auth.FacebookAuthProvider();

async function signInWithFacebookInFirebase() {
  return auth
    .signInWithPopup(provider)
    .then((res) => res)
    .catch((error) => error.code);
}

export default function* callSignInWithFacebookSaga() {
  try {
    // @ts-ignore
    const result = yield call(signInWithFacebookInFirebase);
    if (result.user) {
      const authToken: string = yield call(getCurrentUserAuthorization);

      // @ts-ignore
      const resultUserCreation = yield call(
        createFirestoreUserDoc,
        result.user?.uid,
        result.user?.displayName,
        result.user?.email,
        result.user?.photoURL,
        "facebook",
        authToken
      );
      if (resultUserCreation.status === "success") {
        yield put(signInWithFacebookSuccessAction(result.user?.uid));
      } else {
        throw new Error(JSON.stringify(resultUserCreation));
      }
    } else {
      throw new Error(JSON.stringify(result.toString()));
    }
  } catch (e) {
    yield put(signInWithFacebookFailureAction(e.toString()));
  }
}
