import User, { CreateUser } from "../../../shared/userInterface";

export enum loginActionTypes {
  SIGN_IN_WITH_EMAIL_AND_PASSWORD = "aduwata-lk/login/sign_in_with_email_and_password",
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS = "aduwata-lk/login/sign_in_with_email_and_password_success",
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE = "aduwata-lk/login/sign_in_with_email_and_password_failure",
  SIGN_IN_WITH_GOOGLE = "aduwata-lk/login/sign_in_with_google",
  SIGN_IN_WITH_GOOGLE_SUCCESS = "aduwata-lk/login/sign_in_with_google_success",
  SIGN_IN_WITH_GOOGLE_FAILURE = "aduwata-lk/login/sign_in_with_google_failure",
  SIGN_IN_WITH_FACEBOOK = "aduwata-lk/login/sign_in_with_facebook",
  SIGN_IN_WITH_FACEBOOK_SUCCESS = "aduwata-lk/login/sign_in_with_facebook_success",
  SIGN_IN_WITH_FACEBOOK_FAILURE = "aduwata-lk/login/sign_in_with_facebook_failure",
  CREATE_USER_WITH_EMAIL_AND_PASSWORD = "aduwata-lk/login/create_user_with_email_and_password",
  CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS = "aduwata-lk/login/create_user_with_email_and_password_success",
  CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE = "aduwata-lk/login/create_user_with_email_and_password_failure",
  SET_AUTHENTICATION_UID = "aduwata-lk/login/set_authentication_uid",
  FETCH_LOGGED_IN_USER = "aduwata-lk/login/fetch_logged_in_user",
  FETCH_LOGGED_IN_USER_SUCCESS = "aduwata-lk/login/fetch_logged_in_user_success",
  FETCH_LOGGED_IN_USER_FAILURE = "aduwata-lk/login/fetch_logged_in_user_failure",
  SIGN_OUT = "aduwata-lk/login/sign_out",
  SIGN_OUT_SUCCESS = "aduwata-lk/login/sign_out_success",
  SIGN_OUT_FAILURE = "aduwata-lk/login/sign_out_failure",
  RESET_SIGN_OUT = "aduwata-lk/login/reset_sign_out",
  RESET_LOGIN_ERRORS = "aduwata-lk/login/reset_login_errors",
  FORGET_PASSWORD = "aduwata-lk/login/forget_password"
}

export interface SignInWithEmailAndPasswordAction {
  type: loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD;
  email: string;
  password: string;
}

interface SignInWithEmailAndPasswordSuccessAction {
  type: loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS;
  uid: string;
}

interface SignInWithEmailAndPasswordFailureAction {
  type: loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE;
  err: string;
}

export interface SignInWithGoogleAction {
  type: loginActionTypes.SIGN_IN_WITH_GOOGLE;
}

interface SignInWithGoogleSuccessAction {
  type: loginActionTypes.SIGN_IN_WITH_GOOGLE_SUCCESS;
  uid: string;
}

interface SignInWithGoogleFailureAction {
  type: loginActionTypes.SIGN_IN_WITH_GOOGLE_FAILURE;
  err: string;
}

export interface SignInWithFacebookAction {
  type: loginActionTypes.SIGN_IN_WITH_FACEBOOK;
}

interface SignInWithFacebookSuccessAction {
  type: loginActionTypes.SIGN_IN_WITH_FACEBOOK_SUCCESS;
  uid: string;
}

interface SignInWithFacebookFailureAction {
  type: loginActionTypes.SIGN_IN_WITH_FACEBOOK_FAILURE;
  err: string;
}

export interface CreateUserWithEmailAndPasswordAction {
  type: loginActionTypes.CREATE_USER_WITH_EMAIL_AND_PASSWORD;
  user: CreateUser;
}

interface CreateUserWithEmailAndPasswordSuccessAction {
  type: loginActionTypes.CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS;
  uid: string;
}

interface CreateUserWithEmailAndPasswordFailureAction {
  type: loginActionTypes.CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE;
  err: string;
}

interface SetAuthenticationUidAction {
  type: loginActionTypes.SET_AUTHENTICATION_UID;
  uid: string;
}

interface ResetSignOutAction {
  type: loginActionTypes.RESET_SIGN_OUT;
}

interface ResetLoginErrorAction {
  type: loginActionTypes.RESET_LOGIN_ERRORS;
}

export interface ForgetPasswordAction {
  type: loginActionTypes.FORGET_PASSWORD;
  email: string;
}

interface SignOutAction {
  type: loginActionTypes.SIGN_OUT;
}

interface SignOutSuccessAction {
  type: loginActionTypes.SIGN_OUT_SUCCESS;
}

interface SignOutFailureAction {
  type: loginActionTypes.SIGN_OUT_FAILURE;
  err: string;
}

export interface FetchLoggedInUserAction {
  type: loginActionTypes.FETCH_LOGGED_IN_USER;
  uid: string;
}

interface FetchLoggedInUserSuccessAction {
  type: loginActionTypes.FETCH_LOGGED_IN_USER_SUCCESS;
  user: User | undefined;
}

interface FetchLoggedInUserFailureAction {
  type: loginActionTypes.FETCH_LOGGED_IN_USER_FAILURE;
  err: string;
}

export type LoginActions =
  | SignInWithEmailAndPasswordAction
  | SignInWithEmailAndPasswordSuccessAction
  | SignInWithEmailAndPasswordFailureAction
  | SignInWithGoogleAction
  | SignInWithGoogleSuccessAction
  | SignInWithGoogleFailureAction
  | SignInWithFacebookAction
  | SignInWithFacebookSuccessAction
  | SignInWithFacebookFailureAction
  | CreateUserWithEmailAndPasswordAction
  | CreateUserWithEmailAndPasswordSuccessAction
  | CreateUserWithEmailAndPasswordFailureAction
  | SetAuthenticationUidAction
  | ResetSignOutAction
  | ResetLoginErrorAction
  | ForgetPasswordAction
  | SignOutAction
  | SignOutSuccessAction
  | SignOutFailureAction
  | FetchLoggedInUserAction
  | FetchLoggedInUserSuccessAction
  | FetchLoggedInUserFailureAction;

export function signInWithEmailAndPasswordAction(email: string, password: string): SignInWithEmailAndPasswordAction {
  return {
    type: loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD,
    email,
    password
  };
}

export function signInWithEmailAndPasswordSuccessAction(uid: string): SignInWithEmailAndPasswordSuccessAction {
  return {
    type: loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS,
    uid
  };
}

export function signInWithEmailAndPasswordFailureAction(err: string): SignInWithEmailAndPasswordFailureAction {
  console.error("signInWithEmailAndPasswordFailureAction", err);
  return {
    type: loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE,
    err
  };
}

export function signInWithGoogleAction(): SignInWithGoogleAction {
  return {
    type: loginActionTypes.SIGN_IN_WITH_GOOGLE
  };
}

export function signInWithGoogleSuccessAction(uid: string): SignInWithGoogleSuccessAction {
  return {
    type: loginActionTypes.SIGN_IN_WITH_GOOGLE_SUCCESS,
    uid
  };
}

export function signInWithGoogleFailureAction(err: string): SignInWithGoogleFailureAction {
  console.error("signInWithGoogleFailureAction", err);
  return {
    type: loginActionTypes.SIGN_IN_WITH_GOOGLE_FAILURE,
    err
  };
}

export function signInWithFacebookAction(): SignInWithFacebookAction {
  return {
    type: loginActionTypes.SIGN_IN_WITH_FACEBOOK
  };
}

export function signInWithFacebookSuccessAction(uid: string): SignInWithFacebookSuccessAction {
  return {
    type: loginActionTypes.SIGN_IN_WITH_FACEBOOK_SUCCESS,
    uid
  };
}

export function signInWithFacebookFailureAction(err: string): SignInWithFacebookFailureAction {
  console.error("signInWithFacebookFailureAction", err);
  return {
    type: loginActionTypes.SIGN_IN_WITH_FACEBOOK_FAILURE,
    err
  };
}

export function createUserWithEmailAndPasswordAction(user: CreateUser): CreateUserWithEmailAndPasswordAction {
  return {
    type: loginActionTypes.CREATE_USER_WITH_EMAIL_AND_PASSWORD,
    user
  };
}

export function createUserWithEmailAndPasswordSuccessAction(uid: string): CreateUserWithEmailAndPasswordSuccessAction {
  return {
    type: loginActionTypes.CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS,
    uid
  };
}

export function createUserWithEmailAndPasswordFailureAction(err: string): CreateUserWithEmailAndPasswordFailureAction {
  console.error("createUserWithEmailAndPasswordFailureAction", err);
  return {
    type: loginActionTypes.CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE,
    err
  };
}

export function setAuthenticationUidAction(uid: string): SetAuthenticationUidAction {
  return {
    type: loginActionTypes.SET_AUTHENTICATION_UID,
    uid
  };
}

export function resetSignOutAction(): ResetSignOutAction {
  return {
    type: loginActionTypes.RESET_SIGN_OUT
  };
}

export function resetLoginErrorAction(): ResetLoginErrorAction {
  return {
    type: loginActionTypes.RESET_LOGIN_ERRORS
  };
}

export function forgetPasswordAction(email: string): ForgetPasswordAction {
  return {
    type: loginActionTypes.FORGET_PASSWORD,
    email
  };
}

export function signOutAction(): SignOutAction {
  return {
    type: loginActionTypes.SIGN_OUT
  };
}

export function signOutSuccessAction(): SignOutSuccessAction {
  return {
    type: loginActionTypes.SIGN_OUT_SUCCESS
  };
}

export function signOutFailureAction(err: string): SignOutFailureAction {
  console.error("signOutFailureAction", err);
  return {
    type: loginActionTypes.SIGN_OUT_FAILURE,
    err
  };
}

export function fetchLoggedInUserAction(uid: string): FetchLoggedInUserAction {
  return {
    type: loginActionTypes.FETCH_LOGGED_IN_USER,
    uid
  };
}

export function fetchLoggedInUserSuccessAction(user: User | undefined): FetchLoggedInUserSuccessAction {
  return {
    type: loginActionTypes.FETCH_LOGGED_IN_USER_SUCCESS,
    user
  };
}

export function fetchLoggedInUserFailureAction(err: string): FetchLoggedInUserFailureAction {
  console.error("fetchLoggedInUserFailureAction", err);
  return {
    type: loginActionTypes.FETCH_LOGGED_IN_USER_FAILURE,
    err
  };
}
