/* eslint-disable no-param-reassign */
import produce from "immer";
import { AboutUsPageActions, aboutUsPageActionTypes } from "./actions";

export interface AboutUsPageState {
  fetchAboutUsCountsIsLoading: boolean;
  registeredUsers: number;
  communityAds: number;
  soldOnThisWebsite: number;
  fetchAboutUsCountsError: { hasError: boolean; description: string };
}

const initialState: AboutUsPageState = {
  fetchAboutUsCountsIsLoading: false,
  communityAds: 0,
  registeredUsers: 0,
  soldOnThisWebsite: 0,
  fetchAboutUsCountsError: { description: "", hasError: false }
};

export default function aboutUsPageCountsReducer(
  state: AboutUsPageState = initialState,
  action: AboutUsPageActions
): AboutUsPageState {
  return produce(state, (draft) => {
    switch (action.type) {
      case aboutUsPageActionTypes.FETCH_ABOUT_US_COUNTS: {
        draft.fetchAboutUsCountsIsLoading = true;
        break;
      }
      case aboutUsPageActionTypes.FETCH_ABOUT_US_COUNTS_SUCCESS: {
        draft.fetchAboutUsCountsIsLoading = false;
        draft.registeredUsers = action.registeredUsers;
        draft.communityAds = action.communityAds;
        draft.soldOnThisWebsite = action.soldOnThisWebsite;
        draft.fetchAboutUsCountsError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case aboutUsPageActionTypes.FETCH_ABOUT_US_COUNTS_FAILURE: {
        draft.fetchAboutUsCountsIsLoading = false;
        draft.fetchAboutUsCountsError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      default:
        break;
    }
  });
}
