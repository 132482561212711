import { call, delay, put } from "redux-saga/effects";
import {
  CreateUserWithEmailAndPasswordAction,
  createUserWithEmailAndPasswordFailureAction,
  createUserWithEmailAndPasswordSuccessAction
} from "../actions";
import { auth } from "../../../../config/firebase";
import { CreateUser } from "../../../../shared/userInterface";
import getCurrentUserAuthorization from "../../../../utils/getAuthorizationToken";

async function createUserWithEmailAndPasswordInFirebase(email: string, password: string) {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then((res) => res)
    .catch((error) => error.code);
}

async function createFirestoreUserDocument(user: CreateUser, uid: string, authToken: string) {
  const body = {
    uid,
    name: user.name,
    email: user.email,
    contact: user.contact.map((el) => ({
      number: el,
      verified: false
    }))
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/user`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${authToken}`
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callCreateUserWithEmailAndPasswordSaga(action: CreateUserWithEmailAndPasswordAction) {
  try {
    // @ts-ignore
    const result = yield call(createUserWithEmailAndPasswordInFirebase, action.user.email, action.user.password);
    if (result.user) {
      yield delay(2000);
      const authToken: string = yield call(getCurrentUserAuthorization);

      yield call(createFirestoreUserDocument, action.user, result.user?.uid, authToken);
      yield put(createUserWithEmailAndPasswordSuccessAction(result.user?.uid));
    } else if (result === "auth/email-already-in-use") {
      yield put(createUserWithEmailAndPasswordFailureAction("auth/email-already-in-use"));
    } else {
      yield put(createUserWithEmailAndPasswordFailureAction(result));
    }
  } catch (e) {
    yield put(createUserWithEmailAndPasswordFailureAction(e.toString()));
  }
}
