import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import User, { ContactNoInterface } from "../../../shared/userInterface";
import { addContactNoAction, removeContactNoAction } from "../redux/actions";
import { COLOR_TEXT_LIGHT_TITLE } from "../../../config/colorProfile";

// @ts-ignore
export default function ManageMyContactModal(props: { currentUser: User | undefined }) {
  const dispatch = useDispatch();
  const { currentUser } = props;
  const [contactList, setContactList] = useState<ContactNoInterface[]>([]);
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    setContactList(currentUser?.contact || []);
  }, [currentUser?.contact]);

  return (
    <div
      className="modal"
      id="manageMyContactModal"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Manage My Contacts
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setValue("");
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center" style={{ color: "black" }}>
            {contactList.map((el, index) => (
              <div
                className="d-flex flex-row justify-content-start mb-2"
                key={index}
                style={{
                  width: "100%",
                  maxWidth: 300,
                  paddingLeft: "20px"
                }}
              >
                <p style={{ fontSize: 22, width: 122 }}>{el.number}</p>
                <button
                  style={{ color: contactList.length <= 1 ? "gray" : "#2681db" }}
                  disabled={contactList.length <= 1}
                  type="button"
                  className="manage-contact-no-remove-btn"
                  onClick={() => {
                    dispatch(removeContactNoAction(el.number));
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
            <div
              className="input-group"
              style={{
                width: "100%",
                maxWidth: 300,
                marginTop: "10px"
              }}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Contact No"
                aria-label="Contact No"
                aria-describedby="button-addon2"
                style={{ height: 35 }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  disabled={!value.match(/^0[0-9]{9}$/)}
                  className="btn"
                  type="button"
                  id="button-addon2"
                  style={{
                    height: "35px",
                    padding: "0px 15px",
                    color: "white",
                    background: "rgb(38, 129, 219)",
                    border: "rgb(38, 129, 219)"
                  }}
                  onClick={() => {
                    dispatch(addContactNoAction(value));
                    setValue("");
                  }}
                >
                  Add
                </button>
              </div>
            </div>
            {value.length === 0 ? (
              <p
                style={{
                  color: COLOR_TEXT_LIGHT_TITLE,
                  paddingLeft: "20px",
                  fontSize: "80%",
                  fontWeight: 400,
                  lineHeight: "18px",
                  width: "100%",
                  maxWidth: 300,
                  marginBottom: "20px"
                }}
              >
                eg: 0712345678
              </p>
            ) : !value.match(/^0[0-9]{9}$/) ? (
              <p
                style={{
                  color: "rgb(220, 53, 69)",
                  paddingLeft: "20px",
                  fontSize: "80%",
                  fontWeight: 400,
                  lineHeight: "18px",
                  width: "100%",
                  maxWidth: 300,
                  marginBottom: "20px"
                }}
              >
                Must contain a valid number
              </p>
            ) : (
              <div style={{ height: 38 }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
