export default async function createFirestoreUserDoc(
  uid: string,
  name: string,
  email: string,
  image: string,
  provider: string,
  authToken: string
) {
  const body = {
    uid,
    name,
    contact: [],
    email,
    image,
    provider
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/user/provider`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${authToken}`
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}
