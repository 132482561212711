import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { PublishAdAction, publishAdFailureAction, publishAdSuccessAction } from "../actions";
import { PublishAdvertisementInterface } from "../../../../shared/advertisementInterface";
import getCurrentUserAuthorization from "../../../../utils/getAuthorizationToken";

async function publishAdApiCall(advertisement: PublishAdvertisementInterface, authToken: string) {
  const body = {
    id: advertisement.id,
    title: advertisement.title,
    description: advertisement.description,
    images: advertisement.images,
    adType: advertisement.adType,
    category: advertisement.category,
    subCategory: advertisement.subCategory,
    isNegotiable: advertisement.isNegotiable,
    location: advertisement.location,
    subLocation: advertisement.subLocation,
    postedBy: advertisement.postedBy,
    price: advertisement.price,
    priceUnit: advertisement.priceUnit,
    specifications: advertisement.specifications,
    tags: advertisement.tags
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/ad`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${authToken}`
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callPublishAdSaga(action: PublishAdAction) {
  const authToken: string = yield call(getCurrentUserAuthorization);

  try {
    // @ts-ignore
    const result = yield call(publishAdApiCall, action.advertisement, authToken);
    if (result.status === "success") {
      toast.success("You have successfully post a new ad.");
      yield put(publishAdSuccessAction(result.adUrl));
    } else {
      throw new Error(JSON.stringify(result));
    }
  } catch (e) {
    toast.error("An error occurred, Please try again later.");
    yield put(publishAdFailureAction(e.toString()));
  }
}
