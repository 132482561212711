/* eslint-disable no-param-reassign */
import produce from "immer";
import { ReceivedAdvertisementInterface } from "../../../shared/advertisementInterface";
import { AdvertisementActions, advertisementActionTypes } from "./actions";

export interface AdvertisementState {
  publishAdIsLoading: boolean;
  publishAdIsSuccess: boolean;
  adUrl: string | undefined;
  publishAdError: { hasError: boolean; description: string };
  editAdIsLoading: boolean;
  editAdIsSuccess: boolean;
  editAdError: { hasError: boolean; description: string };
  deleteAdIsLoading: boolean;
  deleteAdIsSuccess: boolean;
  deleteAdError: { hasError: boolean; description: string };
  fetchSingleAdIsLoading: boolean;
  singleAd: ReceivedAdvertisementInterface | undefined;
  fetchSingleAdError: { hasError: boolean; description: string };
  reportAdIsLoading: boolean;
  reportAdError: { hasError: boolean; description: string };
  sendMessageToSellerIsLoading: boolean;
  sendMessageToSellerError: { hasError: boolean; description: string };
}

const initialState: AdvertisementState = {
  publishAdIsLoading: false,
  publishAdIsSuccess: false,
  adUrl: undefined,
  publishAdError: { hasError: false, description: "" },
  editAdIsLoading: false,
  editAdIsSuccess: false,
  editAdError: { description: "", hasError: false },
  deleteAdIsLoading: false,
  deleteAdIsSuccess: false,
  deleteAdError: { description: "", hasError: false },
  fetchSingleAdIsLoading: false,
  singleAd: undefined,
  fetchSingleAdError: { hasError: false, description: "" },
  reportAdIsLoading: false,
  reportAdError: { description: "", hasError: false },
  sendMessageToSellerIsLoading: false,
  sendMessageToSellerError: { description: "", hasError: false }
};

export default function adPostReducer(
  state: AdvertisementState = initialState,
  action: AdvertisementActions
): AdvertisementState {
  return produce(state, (draft) => {
    switch (action.type) {
      case advertisementActionTypes.PUBLISH_AD: {
        draft.publishAdIsLoading = true;
        break;
      }
      case advertisementActionTypes.PUBLISH_AD_SUCCESS: {
        draft.publishAdIsLoading = false;
        draft.publishAdIsSuccess = true;
        draft.adUrl = action.adUrl;
        draft.publishAdError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case advertisementActionTypes.PUBLISH_AD_FAILURE: {
        draft.publishAdIsLoading = false;
        draft.publishAdError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case advertisementActionTypes.EDIT_AD: {
        draft.editAdIsLoading = true;
        break;
      }
      case advertisementActionTypes.EDIT_AD_SUCCESS: {
        draft.editAdIsLoading = false;
        draft.editAdIsSuccess = true;
        draft.adUrl = action.adUrl;
        draft.editAdError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case advertisementActionTypes.EDIT_AD_FAILURE: {
        draft.editAdIsLoading = false;
        draft.editAdError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case advertisementActionTypes.DELETE_AD: {
        draft.deleteAdIsLoading = true;
        break;
      }
      case advertisementActionTypes.DELETE_AD_SUCCESS: {
        draft.deleteAdIsLoading = false;
        draft.deleteAdIsSuccess = true;
        draft.deleteAdError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case advertisementActionTypes.DELETE_AD_FAILURE: {
        draft.deleteAdIsLoading = false;
        draft.deleteAdError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case advertisementActionTypes.SET_AD_IS_LOADING: {
        draft.fetchSingleAdIsLoading = true;
        break;
      }
      case advertisementActionTypes.FETCH_SINGLE_AD: {
        draft.fetchSingleAdIsLoading = true;
        draft.singleAd = undefined;
        break;
      }
      case advertisementActionTypes.FETCH_SINGLE_AD_SUCCESS: {
        draft.fetchSingleAdIsLoading = false;
        draft.singleAd = action.singleAd;
        draft.fetchSingleAdError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case advertisementActionTypes.FETCH_SINGLE_AD_FAILURE: {
        draft.fetchSingleAdIsLoading = false;
        draft.fetchSingleAdError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case advertisementActionTypes.REPORT_AD: {
        draft.reportAdIsLoading = true;
        break;
      }
      case advertisementActionTypes.REPORT_AD_SUCCESS: {
        draft.reportAdIsLoading = false;
        draft.reportAdError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case advertisementActionTypes.REPORT_AD_FAILURE: {
        draft.reportAdIsLoading = false;
        draft.reportAdError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case advertisementActionTypes.SEND_MESSAGE_TO_SELLER_AD: {
        draft.sendMessageToSellerIsLoading = true;
        break;
      }
      case advertisementActionTypes.SEND_MESSAGE_TO_SELLER_AD_SUCCESS: {
        draft.sendMessageToSellerIsLoading = false;
        draft.sendMessageToSellerError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case advertisementActionTypes.SEND_MESSAGE_TO_SELLER_AD_FAILURE: {
        draft.sendMessageToSellerIsLoading = false;
        draft.sendMessageToSellerError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case advertisementActionTypes.RESET_AD_STATUS: {
        draft.publishAdIsSuccess = false;
        draft.editAdIsSuccess = false;
        draft.deleteAdIsSuccess = false;
        draft.adUrl = undefined;
        break;
      }
      default:
        break;
    }
  });
}
