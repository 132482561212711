export default function getLastSeenTime(postedOn: Date): string {
  try {
    const currentDateTime: Date = new Date();
    const timeDiff = currentDateTime.getTime() - postedOn.getTime();
    if (timeDiff < 60000) {
      return "Just Now";
    }
    if (timeDiff >= 60000 && timeDiff < 3600000) {
      const number = Math.floor(timeDiff / 60000);
      return `${number} ${number === 1 ? "minute ago" : "minutes ago"}`;
    }
    if (timeDiff >= 3600000 && timeDiff < 86400000) {
      const number = Math.floor(timeDiff / 3600000);
      return `${number} ${number === 1 ? "hour ago" : "hours ago"}`;
    }
    if (timeDiff >= 86400000 && timeDiff < 2628000000) {
      const number = Math.floor(timeDiff / 86400000);
      return `${number} ${number === 1 ? "day ago" : "days ago"}`;
    }
    const number = Math.floor(timeDiff / 2628000000);
    return `${number} ${number === 1 ? "month ago" : "months ago"}`;
  } catch (e) {
    return "Long time ago";
  }
}
