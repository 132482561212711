import { PublishAdvertisementInterface, ReceivedAdvertisementInterface } from "../../../shared/advertisementInterface";

export enum advertisementActionTypes {
  PUBLISH_AD = "aduwata-lk/advertisement/publish_ad",
  PUBLISH_AD_SUCCESS = "aduwata-lk/advertisement/publish_ad_success",
  PUBLISH_AD_FAILURE = "aduwata-lk/advertisement/publish_ad_failure",
  EDIT_AD = "aduwata-lk/advertisement/edit_ad",
  EDIT_AD_SUCCESS = "aduwata-lk/advertisement/edit_ad_success",
  EDIT_AD_FAILURE = "aduwata-lk/advertisement/edit_ad_failure",
  DELETE_AD = "aduwata-lk/advertisement/delete_ad",
  DELETE_AD_SUCCESS = "aduwata-lk/advertisement/delete_ad_success",
  DELETE_AD_FAILURE = "aduwata-lk/advertisement/delete_ad_failure",
  SET_AD_IS_LOADING = "aduwata-lk/advertisement/set_ad_is_loading",
  FETCH_SINGLE_AD = "aduwata-lk/advertisement/fetch_single_ad",
  FETCH_SINGLE_AD_SUCCESS = "aduwata-lk/advertisement/fetch_single_ad_success",
  FETCH_SINGLE_AD_FAILURE = "aduwata-lk/advertisement/fetch_single_ad_failure",
  REPORT_AD = "aduwata-lk/advertisement/report_ad",
  REPORT_AD_SUCCESS = "aduwata-lk/advertisement/report_ad_success",
  REPORT_AD_FAILURE = "aduwata-lk/advertisement/report_ad_failure",
  SEND_MESSAGE_TO_SELLER_AD = "aduwata-lk/advertisement/send_message_to_seller_ad",
  SEND_MESSAGE_TO_SELLER_AD_SUCCESS = "aduwata-lk/advertisement/send_message_to_seller_ad_success",
  SEND_MESSAGE_TO_SELLER_AD_FAILURE = "aduwata-lk/advertisement/send_message_to_seller_ad_failure",
  RESET_AD_STATUS = "aduwata-lk/advertisement/reset_ad_status"
}

export interface PublishAdAction {
  type: advertisementActionTypes.PUBLISH_AD;
  advertisement: PublishAdvertisementInterface;
}

interface PublishAdSuccessAction {
  type: advertisementActionTypes.PUBLISH_AD_SUCCESS;
  adUrl: string;
}

interface PublishAdFailureAction {
  type: advertisementActionTypes.PUBLISH_AD_FAILURE;
  err: string;
}

export interface EditAdAction {
  type: advertisementActionTypes.EDIT_AD;
  advertisement: PublishAdvertisementInterface;
}

interface EditAdSuccessAction {
  type: advertisementActionTypes.EDIT_AD_SUCCESS;
  adUrl: string;
}

interface EditAdFailureAction {
  type: advertisementActionTypes.EDIT_AD_FAILURE;
  err: string;
}

export interface DeleteAdAction {
  type: advertisementActionTypes.DELETE_AD;
  id: string;
  reason: string;
}

interface DeleteAdSuccessAction {
  type: advertisementActionTypes.DELETE_AD_SUCCESS;
}

interface DeleteAdFailureAction {
  type: advertisementActionTypes.DELETE_AD_FAILURE;
  err: string;
}

export interface FetchSingleAdvertisementAction {
  type: advertisementActionTypes.FETCH_SINGLE_AD;
  id: string;
}

export interface SetAdIsLoadingAction {
  type: advertisementActionTypes.SET_AD_IS_LOADING;
}

interface FetchSingleAdvertisementSuccessAction {
  type: advertisementActionTypes.FETCH_SINGLE_AD_SUCCESS;
  singleAd: ReceivedAdvertisementInterface | undefined;
}

interface FetchSingleAdvertisementFailureAction {
  type: advertisementActionTypes.FETCH_SINGLE_AD_FAILURE;
  err: string;
}

export interface ReportAdAction {
  type: advertisementActionTypes.REPORT_AD;
  adId: string;
  reason: string;
}

interface ReportAdSuccessAction {
  type: advertisementActionTypes.REPORT_AD_SUCCESS;
}

interface ReportAdFailureAction {
  type: advertisementActionTypes.REPORT_AD_FAILURE;
  err: string;
}

export interface SendMessageToSellerAdAction {
  type: advertisementActionTypes.SEND_MESSAGE_TO_SELLER_AD;
  adId: string;
  authorId: string;
  name: string;
  email: string;
  contactNo: string;
  message: string;
}

interface SendMessageToSellerAdSuccessAction {
  type: advertisementActionTypes.SEND_MESSAGE_TO_SELLER_AD_SUCCESS;
}

interface SendMessageToSellerAdFailureAction {
  type: advertisementActionTypes.SEND_MESSAGE_TO_SELLER_AD_FAILURE;
  err: string;
}

interface ResetAdStatusAction {
  type: advertisementActionTypes.RESET_AD_STATUS;
}

export type AdvertisementActions =
  | PublishAdAction
  | PublishAdSuccessAction
  | PublishAdFailureAction
  | EditAdAction
  | EditAdSuccessAction
  | EditAdFailureAction
  | DeleteAdAction
  | DeleteAdSuccessAction
  | DeleteAdFailureAction
  | SetAdIsLoadingAction
  | FetchSingleAdvertisementAction
  | FetchSingleAdvertisementSuccessAction
  | FetchSingleAdvertisementFailureAction
  | ReportAdAction
  | ReportAdSuccessAction
  | ReportAdFailureAction
  | SendMessageToSellerAdAction
  | SendMessageToSellerAdSuccessAction
  | SendMessageToSellerAdFailureAction
  | ResetAdStatusAction;

export function publishAdAction(advertisement: PublishAdvertisementInterface): PublishAdAction {
  return {
    type: advertisementActionTypes.PUBLISH_AD,
    advertisement
  };
}

export function publishAdSuccessAction(adUrl: string): PublishAdSuccessAction {
  return {
    type: advertisementActionTypes.PUBLISH_AD_SUCCESS,
    adUrl
  };
}

export function publishAdFailureAction(err: string): PublishAdFailureAction {
  console.error("publishAdFailureAction", err);
  return {
    type: advertisementActionTypes.PUBLISH_AD_FAILURE,
    err
  };
}

export function editAdAction(advertisement: PublishAdvertisementInterface): EditAdAction {
  return {
    type: advertisementActionTypes.EDIT_AD,
    advertisement
  };
}

export function editAdSuccessAction(adUrl: string): EditAdSuccessAction {
  return {
    type: advertisementActionTypes.EDIT_AD_SUCCESS,
    adUrl
  };
}

export function editAdFailureAction(err: string): EditAdFailureAction {
  console.error("editAdFailureAction", err);
  return {
    type: advertisementActionTypes.EDIT_AD_FAILURE,
    err
  };
}

export function deleteAdAction(id: string, reason: string): DeleteAdAction {
  return {
    type: advertisementActionTypes.DELETE_AD,
    id,
    reason
  };
}

export function deleteAdSuccessAction(): DeleteAdSuccessAction {
  return {
    type: advertisementActionTypes.DELETE_AD_SUCCESS
  };
}

export function deleteAdFailureAction(err: string): DeleteAdFailureAction {
  console.error("deleteAdFailureAction", err);
  return {
    type: advertisementActionTypes.DELETE_AD_FAILURE,
    err
  };
}

export function setAdIsLoadingAction(): SetAdIsLoadingAction {
  return {
    type: advertisementActionTypes.SET_AD_IS_LOADING
  };
}

export function fetchSingleAdvertisementAction(id: string): FetchSingleAdvertisementAction {
  return {
    type: advertisementActionTypes.FETCH_SINGLE_AD,
    id
  };
}

export function fetchSingleAdvertisementSuccessAction(
  singleAd: ReceivedAdvertisementInterface | undefined
): FetchSingleAdvertisementSuccessAction {
  return {
    type: advertisementActionTypes.FETCH_SINGLE_AD_SUCCESS,
    singleAd
  };
}

export function fetchSingleAdvertisementFailureAction(err: string): FetchSingleAdvertisementFailureAction {
  console.error("fetchSingleAdvertisementFailureAction", err);
  return {
    type: advertisementActionTypes.FETCH_SINGLE_AD_FAILURE,
    err
  };
}

export function reportAdAction(adId: string, reason: string): ReportAdAction {
  return {
    type: advertisementActionTypes.REPORT_AD,
    adId,
    reason
  };
}

export function reportAdSuccessAction(): ReportAdSuccessAction {
  return {
    type: advertisementActionTypes.REPORT_AD_SUCCESS
  };
}

export function reportAdFailureAction(err: string): ReportAdFailureAction {
  console.error("reportAdFailureAction", err);
  return {
    type: advertisementActionTypes.REPORT_AD_FAILURE,
    err
  };
}

export function sendMessageToSellerAdAction(
  adId: string,
  authorId: string,
  name: string,
  email: string,
  contactNo: string,
  message: string
): SendMessageToSellerAdAction {
  return {
    type: advertisementActionTypes.SEND_MESSAGE_TO_SELLER_AD,
    adId,
    authorId,
    name,
    email,
    contactNo,
    message
  };
}

export function sendMessageToSellerAdSuccessAction(): SendMessageToSellerAdSuccessAction {
  return {
    type: advertisementActionTypes.SEND_MESSAGE_TO_SELLER_AD_SUCCESS
  };
}

export function sendMessageToSellerAdFailureAction(err: string): SendMessageToSellerAdFailureAction {
  console.error("sendMessageToSellerAdFailureAction", err);
  return {
    type: advertisementActionTypes.SEND_MESSAGE_TO_SELLER_AD_FAILURE,
    err
  };
}

export function resetAdStatusAction(): ResetAdStatusAction {
  return {
    type: advertisementActionTypes.RESET_AD_STATUS
  };
}
