// @ts-nocheck
import GoogleMapReact from "google-map-react";
import React from "react";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";
import { COLOR_RED } from "../../../../config/colorProfile";

const LocationPin = () => (
  <div className="pin">
    <i
      className="fa fa-map-marker-alt"
      style={{ fontSize: 35, color: COLOR_RED, position: "absolute", top: -35, left: -13 }}
    />
  </div>
);

export default function ViewAdLocationCard(props: { currentAd: ReceivedAdvertisementInterface }) {
  const { currentAd } = props;
  return (
    <>
      {currentAd.geoLocation && (
        <div className="ad-details-card">
          <div className="ad-details-title">
            <h5>location</h5>
          </div>
          <div className="ad-details-location">
            <div style={{ height: 350, width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyAKhXBoA8Z01-fg5BmQJ_dwJKg0pRnAHdw" }}
                defaultCenter={{
                  lat: currentAd.geoLocation.latitude,
                  lng: currentAd.geoLocation.longitude
                }}
                defaultZoom={17}
              >
                <LocationPin lat={currentAd.geoLocation.latitude} lng={currentAd.geoLocation.longitude} />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
