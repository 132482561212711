export enum contactUsPageActionTypes {
  SEND_MESSAGE_TO_ADMIN = "aduwata-lk/contact-us/send_message_to_admin",
  SEND_MESSAGE_TO_ADMIN_SUCCESS = "aduwata-lk/contact-us/send_message_to_admin_success",
  SEND_MESSAGE_TO_ADMIN_FAILURE = "aduwata-lk/contact-us/send_message_to_admin_failure"
}

export interface SendMessageToAdminAction {
  type: contactUsPageActionTypes.SEND_MESSAGE_TO_ADMIN;
  name: string;
  email: string;
  subject: string;
  message: string;
}

interface SendMessageToAdminSuccessAction {
  type: contactUsPageActionTypes.SEND_MESSAGE_TO_ADMIN_SUCCESS;
}

interface SendMessageToAdminFailureAction {
  type: contactUsPageActionTypes.SEND_MESSAGE_TO_ADMIN_FAILURE;
  err: string;
}

export type ContactUsPageActions =
  | SendMessageToAdminAction
  | SendMessageToAdminSuccessAction
  | SendMessageToAdminFailureAction;

export function sendMessageToAdminAction(
  name: string,
  email: string,
  subject: string,
  message: string
): SendMessageToAdminAction {
  return {
    type: contactUsPageActionTypes.SEND_MESSAGE_TO_ADMIN,
    name,
    email,
    subject,
    message
  };
}

export function sendMessageToAdminSuccessAction(): SendMessageToAdminSuccessAction {
  return {
    type: contactUsPageActionTypes.SEND_MESSAGE_TO_ADMIN_SUCCESS
  };
}

export function sendMessageToAdminFailureAction(err: string): SendMessageToAdminFailureAction {
  console.error("sendMessageToAdminFailureAction", err);
  return {
    type: contactUsPageActionTypes.SEND_MESSAGE_TO_ADMIN_FAILURE,
    err
  };
}
