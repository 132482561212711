import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AdvertisementCardInterface } from "../../../shared/advertisementInterface";
import numberWithCommas from "../../../utils/priceFormatter";
import getLastSeenTime from "../getLastSeenTime";
import { getThumbnailImg } from "../../../utils/imageUrlGenerator";
import { setAdIsLoadingAction } from "../../advertisement/redux/actions";
import getBackgroundColorCode from "../../../utils/getBackgroundColorCode";
import {
  CardCategoryTitle,
  CardLocationText,
  CardPriceText,
  CardPriceUnitText,
  CardTitle
} from "../../../config/fontStylingProfile";

export default function Card(props: { ad: AdvertisementCardInterface }) {
  const dispatch = useDispatch();
  const { ad } = props;
  const { t } = useTranslation();

  const path = [t(ad.category), t(ad.subCategory)].filter((el) => el.length > 0).join(" / ");
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className="col-6 col-sm-6 col-md-4 col-lg-4 card-grid"
      style={{
        marginBottom: window.innerWidth < 576 ? 6 : 12,
        cursor: "pointer",
        padding: window.innerWidth < 576 ? "0 3px" : "0 6px"
      }}
      onClick={() => {
        dispatch(setAdIsLoadingAction());
      }}
    >
      <Link to={`/ads/view-ad/${ad.url}`} style={{ width: "100%" }}>
        <div className="product-card">
          <div className="product-head">
            <div
              className="product-img"
              style={{
                backgroundImage:
                  ad.images && ad.images.length > 0
                    ? `url('${getThumbnailImg(ad.images[0])}')`
                    : `url('/images/product/image-not-found-white.png')`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: ad.images && ad.images.length > 0 ? "cover" : "80px 60px"
              }}
            >
              {ad.isVerified && <i className="cross-badge fa fa-shield" />}
              <span
                className="flat-badge rent"
                style={{
                  backgroundColor: getBackgroundColorCode(ad.adType)
                }}
              >
                {t(ad.adType)}
              </span>
            </div>
          </div>
          <div className="product-info">
            <div className="product-tag">
              <img
                src={`/images/category/${ad.category}.png`}
                alt={ad.title}
                width={18}
                height={18}
                style={{ marginRight: 5 }}
              />
              <CardCategoryTitle>{path}</CardCategoryTitle>
            </div>
            <div className="product-title">
              <CardTitle>{ad.title}</CardTitle>
              <ul className="product-location" style={{ display: "list-item" }}>
                <li className="justify-content-start">
                  <img src="/images/card/gps-pointer.png" alt="location" width={18} height={18} />
                  {ad.subLocation ? (
                    <CardLocationText>{`${t(ad.location)}, ${t(ad.subLocation)}`}</CardLocationText>
                  ) : (
                    <CardLocationText>{`${t(ad.location)}`}</CardLocationText>
                  )}
                </li>
                <li className="justify-content-start">
                  <img src="/images/card/clock.png" alt="postedOn" width={18} height={18} />
                  <CardLocationText>{getLastSeenTime(ad.postedOn)}</CardLocationText>
                </li>
              </ul>
            </div>
            <div className="product-details">
              <div
                className="product-price"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap"
                }}
              >
                {ad.price === 0 ? (
                  <CardPriceText style={{ fontSize: window.innerWidth < 576 ? 18 : 20 }}>Call for price</CardPriceText>
                ) : (
                  <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <CardPriceText style={{ fontSize: window.innerWidth < 576 ? 18 : 20 }}>
                        {`Rs ${numberWithCommas(ad.price)}`}
                      </CardPriceText>
                      {ad.priceUnit && <CardPriceUnitText>{`${ad.priceUnit}`}</CardPriceUnitText>}
                    </div>
                    {ad.isNegotiable && <CardLocationText>&nbsp;/&nbsp;Negotiable</CardLocationText>}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
