/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";
import { getPrintableDate } from "../../../../utils/dateFormatter";
import { getResizedImg } from "../../../../utils/imageUrlGenerator";
import { COLOR_TEXT_LIGHT_TITLE } from "../../../../config/colorProfile";
import { AppState } from "../../../../store";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../carousel.css";
import getBackgroundColorCode from "../../../../utils/getBackgroundColorCode";
import { LoginState } from "../../../login/redux/reducer";

export default function ViewAdTitle(props: { currentAd: ReceivedAdvertisementInterface }) {
  const { currentAd } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { loggedInUser } = useSelector<AppState, LoginState>((state) => state.loginManagement);

  return (
    <div className="ad-details-card">
      <div className="ad-details-breadcrumb">
        <div className="d-flex flex-row justify-content-between">
          <ol className="breadcrumb">
            <li>
              <span
                className="flat-badge sale"
                style={{
                  backgroundColor: getBackgroundColorCode(currentAd.adType)
                }}
              >
                {t(currentAd?.adType)}
              </span>
            </li>
          </ol>
          <div className="d-flex flex-row overflow-hidden">
            {loggedInUser?.uid !== currentAd.author?.uid && loggedInUser?.role !== "admin" && (
              <button type="button" className="view-ad-header-q-a">
                <i className="fa fa-bookmark" aria-hidden="true" />
                Bookmark
              </button>
            )}
            {loggedInUser?.uid !== currentAd.author?.uid && loggedInUser?.role !== "admin" && (
              <button type="button" className="view-ad-header-q-a" data-toggle="modal" data-target="#reportAdModal">
                <i className="fa fa-flag" aria-hidden="true" />
                Report
              </button>
            )}
            {loggedInUser?.uid !== currentAd.author?.uid && loggedInUser?.role !== "admin" && (
              <button type="button" className="view-ad-header-q-a">
                <i className="fa fa-share-alt" aria-hidden="true" />
                share
              </button>
            )}
            {(loggedInUser?.uid === currentAd.author?.uid || loggedInUser?.role === "admin") && (
              <>
                <button
                  type="button"
                  className="view-ad-header-q-a"
                  onClick={() => {
                    history.push(`/ads/edit-ad/${currentAd.url}`);
                  }}
                >
                  <i className="fa fa-pencil-square" aria-hidden="true" />
                  Edit
                </button>
                <div style={{ width: 5 }} />
              </>
            )}
            {(loggedInUser?.uid === currentAd.author?.uid || loggedInUser?.role === "admin") && (
              <button
                type="button"
                className="view-ad-header-q-a"
                data-toggle="modal"
                data-target="#deleteConfirmationModal"
              >
                <i className="fa fa-trash" aria-hidden="true" />
                Delete
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="ad-details-heading">
        <h2 style={{ textTransform: "capitalize" }}>{currentAd.title}</h2>
      </div>
      <div className="ad-details-breadcrumb">
        <ol className="breadcrumb">
          <li
            style={{ fontSize: 15, lineHeight: "12px", color: COLOR_TEXT_LIGHT_TITLE, marginBottom: 5 }}
          >{`Posted on ${getPrintableDate(currentAd.postedOn)}`}</li>
        </ol>
      </div>
      <div className="carousel-outer">
        <Carousel showStatus={false} infiniteLoop autoPlay swipeable dynamicHeight={false}>
          {(currentAd.images || []).map((el, index) => (
            <div key={index}>
              <img src={getResizedImg(el)} alt={currentAd.title} />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
