import { takeLatest } from "redux-saga/effects";
import { homePageActionTypes } from "../actions";
import callFetchAdvertisementSaga from "./callFetchAdvertisementSaga";
import callFetchCountSaga from "./callFetchCountSaga";
import callSubscribeSaga from "./callSubscribeSaga";

function* getAdvertisementSagas(): any {
  yield takeLatest(homePageActionTypes.FETCH_ADVERTISEMENTS, callFetchAdvertisementSaga);
  yield takeLatest(homePageActionTypes.FETCH_COUNTS, callFetchCountSaga);
  yield takeLatest(homePageActionTypes.SUBSCRIBE, callSubscribeSaga);
}

// eslint-disable-next-line import/prefer-default-export
export const homePageSagas = [getAdvertisementSagas];
