import { call, put } from "redux-saga/effects";
import { FetchMessagesAction, fetchMessagesFailureAction, fetchMessagesSuccessAction } from "../actions";
import getCurrentUserAuthorization from "../../../../utils/getAuthorizationToken";
import Message from "../../../../shared/messageInterface";

async function fetchMessagesFromApi(userId: string, authToken: string) {
  return fetch(`${process.env.REACT_APP_BASE_URL}/user/${userId}/messages`, {
    method: "GET",
    headers: {
      Authorization: `bearer ${authToken}`
    }
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callFetchMessagesSaga(action: FetchMessagesAction) {
  const authToken: string = yield call(getCurrentUserAuthorization);

  try {
    // @ts-ignore
    const result = yield call(fetchMessagesFromApi, action.userId, authToken);
    if (result.status === "success") {
      const iResult: Message[] = (result.messages || []).map((el: any) => ({
        id: el.id,
        adId: el.adId,
        adUserId: el.adUserId,
        contact: el.contact,
        email: el.email,
        message: el.message,
        name: el.name,
        timestamp: new Date(el.timestamp)
      }));

      yield put(fetchMessagesSuccessAction(iResult));
    } else {
      throw new Error(result.toString());
    }
  } catch (e) {
    yield put(fetchMessagesFailureAction(e.toString()));
  }
}
