import { toast } from "react-toastify";
import User from "../../../shared/userInterface";
import { AdvertisementCardInterface } from "../../../shared/advertisementInterface";
import Message from "../../../shared/messageInterface";

export enum userActionTypes {
  FETCH_CUSTOM_USER = "aduwata-lk/user/fetch_custom_user",
  FETCH_CUSTOM_USER_SUCCESS = "aduwata-lk/user/fetch_custom_user_success",
  FETCH_CUSTOM_USER_FAILURE = "aduwata-lk/user/fetch_custom_user_failure",
  FETCH_MY_ADS = "aduwata-lk/user/fetch_my_ads",
  FETCH_MY_ADS_SUCCESS = "aduwata-lk/user/fetch_my_ads_success",
  FETCH_MY_ADS_FAILURE = "aduwata-lk/user/fetch_my_ads_failure",
  SET_PROFILE_IMG_URL = "aduwata-lk/user/set_profile_img",
  EDIT_USER_PROFILE = "aduwata-lk/user/edit_user_profile",
  EDIT_USER_PROFILE_SUCCESS = "aduwata-lk/user/edit_user_profile_success",
  EDIT_USER_PROFILE_FAILURE = "aduwata-lk/user/edit_user_profile_failure",
  ADD_CONTACT_NO = "aduwata-lk/user/add_contact_no",
  ADD_CONTACT_NO_SUCCESS = "aduwata-lk/user/add_contact_no_success",
  ADD_CONTACT_NO_FAILURE = "aduwata-lk/user/add_contact_no_failure",
  REMOVE_CONTACT_NO = "aduwata-lk/user/remove_contact_no",
  REMOVE_CONTACT_NO_SUCCESS = "aduwata-lk/user/remove_contact_no_success",
  REMOVE_CONTACT_NO_FAILURE = "aduwata-lk/user/remove_contact_no_failure",
  FETCH_MESSAGES = "aduwata-lk/user/fetch_messages",
  FETCH_MESSAGES_SUCCESS = "aduwata-lk/user/fetch_messages_success",
  FETCH_MESSAGES_FAILURE = "aduwata-lk/user/fetch_messages_failure"
}

export interface FetchCustomUserAction {
  type: userActionTypes.FETCH_CUSTOM_USER;
  userUrl: string;
}

interface FetchCustomUserSuccessAction {
  type: userActionTypes.FETCH_CUSTOM_USER_SUCCESS;
  user: User | undefined;
}

interface FetchCustomUserFailureAction {
  type: userActionTypes.FETCH_CUSTOM_USER_FAILURE;
  err: string;
}

export interface FetchMyAdsAction {
  type: userActionTypes.FETCH_MY_ADS;
  userId: string;
}

interface FetchMyAdsSuccessAction {
  type: userActionTypes.FETCH_MY_ADS_SUCCESS;
  myAds: AdvertisementCardInterface[];
  totalAds: number;
}

interface FetchMyAdsFailureAction {
  type: userActionTypes.FETCH_MY_ADS_FAILURE;
  err: string;
}

export interface SetProfileImgUrlAction {
  type: userActionTypes.SET_PROFILE_IMG_URL;
  imageUrl: string;
}

export interface EditUserProfileAction {
  type: userActionTypes.EDIT_USER_PROFILE;
  user: User;
}

interface EditUserProfileSuccessAction {
  type: userActionTypes.EDIT_USER_PROFILE_SUCCESS;
}

interface EditUserProfileFailureAction {
  type: userActionTypes.EDIT_USER_PROFILE_FAILURE;
  err: string;
}

export interface AddContactNoAction {
  type: userActionTypes.ADD_CONTACT_NO;
  contact: string;
}

interface AddContactNoSuccessAction {
  type: userActionTypes.ADD_CONTACT_NO_SUCCESS;
}

interface AddContactNoFailureAction {
  type: userActionTypes.ADD_CONTACT_NO_FAILURE;
  err: string;
}

export interface RemoveContactNoAction {
  type: userActionTypes.REMOVE_CONTACT_NO;
  contact: string;
}

interface RemoveContactNoSuccessAction {
  type: userActionTypes.REMOVE_CONTACT_NO_SUCCESS;
}

interface RemoveContactNoFailureAction {
  type: userActionTypes.REMOVE_CONTACT_NO_FAILURE;
  err: string;
}

export interface FetchMessagesAction {
  type: userActionTypes.FETCH_MESSAGES;
  userId: string;
}

interface FetchMessagesSuccessAction {
  type: userActionTypes.FETCH_MESSAGES_SUCCESS;
  messages: Message[];
}

interface FetchMessagesFailureAction {
  type: userActionTypes.FETCH_MESSAGES_FAILURE;
  err: string;
}

export type UserActions =
  | FetchCustomUserAction
  | FetchCustomUserSuccessAction
  | FetchCustomUserFailureAction
  | FetchMyAdsAction
  | FetchMyAdsSuccessAction
  | FetchMyAdsFailureAction
  | SetProfileImgUrlAction
  | EditUserProfileAction
  | EditUserProfileSuccessAction
  | EditUserProfileFailureAction
  | AddContactNoAction
  | AddContactNoSuccessAction
  | AddContactNoFailureAction
  | RemoveContactNoAction
  | RemoveContactNoSuccessAction
  | RemoveContactNoFailureAction
  | FetchMessagesAction
  | FetchMessagesSuccessAction
  | FetchMessagesFailureAction;

export function fetchCustomUserAction(userUrl: string): FetchCustomUserAction {
  return {
    type: userActionTypes.FETCH_CUSTOM_USER,
    userUrl
  };
}

export function fetchCustomUserSuccessAction(user: User | undefined): FetchCustomUserSuccessAction {
  return {
    type: userActionTypes.FETCH_CUSTOM_USER_SUCCESS,
    user
  };
}

export function fetchCustomUserFailureAction(err: string): FetchCustomUserFailureAction {
  console.error("fetchCustomUserFailureAction", err);
  return {
    type: userActionTypes.FETCH_CUSTOM_USER_FAILURE,
    err
  };
}

export function fetchMyAdsAction(userId: string): FetchMyAdsAction {
  return {
    type: userActionTypes.FETCH_MY_ADS,
    userId
  };
}

export function fetchMyAdsSuccessAction(
  myAds: AdvertisementCardInterface[],
  totalAds: number
): FetchMyAdsSuccessAction {
  return {
    type: userActionTypes.FETCH_MY_ADS_SUCCESS,
    myAds,
    totalAds
  };
}

export function fetchMyAdsFailureAction(err: string): FetchMyAdsFailureAction {
  console.error("fetchMyAdsFailureAction", err);
  return {
    type: userActionTypes.FETCH_MY_ADS_FAILURE,
    err
  };
}

export function setProfileImgUrlAction(imageUrl: string): SetProfileImgUrlAction {
  return {
    type: userActionTypes.SET_PROFILE_IMG_URL,
    imageUrl
  };
}

export function editUserProfileAction(user: User): EditUserProfileAction {
  return {
    type: userActionTypes.EDIT_USER_PROFILE,
    user
  };
}

export function editUserProfileSuccessAction(): EditUserProfileSuccessAction {
  toast.success("You have successfully update your profile.");
  return {
    type: userActionTypes.EDIT_USER_PROFILE_SUCCESS
  };
}

export function editUserProfileFailureAction(err: string): EditUserProfileFailureAction {
  console.error("editUserProfileFailureAction", err);
  toast.error(`An error occurred, Please try again later.`);
  return {
    type: userActionTypes.EDIT_USER_PROFILE_FAILURE,
    err
  };
}

export function addContactNoAction(contact: string): AddContactNoAction {
  return {
    type: userActionTypes.ADD_CONTACT_NO,
    contact
  };
}

export function addContactNoSuccessAction(): AddContactNoSuccessAction {
  toast.success("You have successfully add contact number.");
  return {
    type: userActionTypes.ADD_CONTACT_NO_SUCCESS
  };
}

export function addContactNoFailureAction(err: string): AddContactNoFailureAction {
  console.error("addContactNoFailureAction", err);
  toast.error(`An error occurred, Please try again later.`);
  return {
    type: userActionTypes.ADD_CONTACT_NO_FAILURE,
    err
  };
}

export function removeContactNoAction(contact: string): RemoveContactNoAction {
  return {
    type: userActionTypes.REMOVE_CONTACT_NO,
    contact
  };
}

export function removeContactNoSuccessAction(): RemoveContactNoSuccessAction {
  toast.success("You have successfully remove contact number.");
  return {
    type: userActionTypes.REMOVE_CONTACT_NO_SUCCESS
  };
}

export function removeContactNoFailureAction(err: string): RemoveContactNoFailureAction {
  console.error("removeContactNoFailureAction", err);
  toast.error(`An error occurred, Please try again later.`);
  return {
    type: userActionTypes.REMOVE_CONTACT_NO_FAILURE,
    err
  };
}

export function fetchMessagesAction(userId: string): FetchMessagesAction {
  return {
    type: userActionTypes.FETCH_MESSAGES,
    userId
  };
}

export function fetchMessagesSuccessAction(messages: Message[]): FetchMessagesSuccessAction {
  return {
    type: userActionTypes.FETCH_MESSAGES_SUCCESS,
    messages
  };
}

export function fetchMessagesFailureAction(err: string): FetchMessagesFailureAction {
  console.error("fetchMessagesFailureAction", err);
  return {
    type: userActionTypes.FETCH_MESSAGES_FAILURE,
    err
  };
}
