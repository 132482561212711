import React from "react";

const electronicsMobiles = (setFieldValue: any) => {
  return (
    <>
      <div className="col-lg-12">
        <div style={{ backgroundColor: "red" }} />
      </div>
    </>
  );
};

export default electronicsMobiles;
