import { PulseLoader } from "react-spinners";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { COLOR_LIGHT_BLUE, COLOR_PURPLE } from "../../../config/colorProfile";
import { AppState } from "../../../store";
import { AdvertisementState } from "../redux/reducer";

// @ts-ignore
export default function AdPostSubmitBtn(props) {
  const history = useHistory();
  const { isSubmitting, isCreate = true } = props;
  const [isAgreedToTnC, setAgreedToTnC] = useState(false);
  const { publishAdIsLoading, editAdIsLoading } = useSelector<AppState, AdvertisementState>(
    (state) => state.advertisementManagement
  );

  return (
    <div className="adpost-card pb-2" style={{ marginBottom: 12 }}>
      <div className="adpost-agree">
        <div className="form-group">
          <input
            type="checkbox"
            className="form-check"
            checked={isAgreedToTnC}
            onChange={(e) => {
              // @ts-ignore
              if (e.target.checked) {
                setAgreedToTnC(true);
              } else {
                setAgreedToTnC(false);
              }
            }}
          />
        </div>
        <p>
          You agree to our&nbsp;
          <button
            type="button"
            onClick={() => window.open("/ads/terms-of-use")}
            style={{
              background: "transparent",
              border: "none",
              color: "#007bff"
            }}
          >
            Terms of Use
          </button>
          &nbsp;and&nbsp;
          <button
            type="button"
            onClick={() => window.open("/ads/privacy-policy")}
            style={{
              background: "transparent",
              border: "none",
              color: "#007bff"
            }}
          >
            Privacy Policy
          </button>
          &nbsp;and acknowledge that you are the rightful owner of this item and using Trade to find a genuine buyer.
        </p>
      </div>
      <div className="form-group text-right">
        <button
          style={{
            fontSize: "15px",
            backgroundColor: COLOR_PURPLE,
            borderColor: COLOR_PURPLE
          }}
          type="submit"
          className="btn btn-inline"
          disabled={isSubmitting || !isAgreedToTnC}
        >
          {publishAdIsLoading || editAdIsLoading ? (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
              }}
            >
              <PulseLoader size={10} margin={1} color={COLOR_LIGHT_BLUE} loading />
            </div>
          ) : (
            <span>{isCreate ? "Published Your Ad" : "Edit Your Ad"}</span>
          )}
        </button>
      </div>
    </div>
  );
}
