import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { RemoteConfigState } from "../../../utils/remoteConfigurations";
import errorStyles from "../../../config/errorStyles";

// @ts-ignore
export default function AdPostLocation(props) {
  const { setFieldValue, handleBlur, values, errors, touched } = props;
  const { t } = useTranslation();
  const { locations } = useSelector<AppState, RemoteConfigState>((state) => state.remoteConfigManagement);

  useEffect(() => {
    Object.keys(locations).forEach((location) => {
      if (locations[location].includes(values.subLocation)) {
        setFieldValue("location", location);
      }
    });
  }, [locations, setFieldValue, values.subLocation]);

  return (
    <div className="col-lg-6">
      <div className="form-group">
        <label className="form-label">
          Location<span style={{ color: "red" }}> *</span>
        </label>
        <select
          className="form-control custom-select"
          name="location"
          onChange={(e) => {
            setFieldValue("location", e.target.value);
            setFieldValue("subLocation", "");
          }}
          onBlur={handleBlur}
          value={values.location}
        >
          <option value="">Select Location</option>
          {Object.keys(locations).map((currentCity, index) => (
            <option key={index} value={currentCity}>
              {t(currentCity)}
            </option>
          ))}
        </select>
        {errors.location && touched.location && <p style={errorStyles.err}>{errors.location}</p>}
      </div>
    </div>
  );
}
