import React from "react";
import ImgPreview from "./ImgPreview";

const MAX_IMAGES = 5;
export default function AdPostImageUpload(props: {
  isEditView: boolean;
  imageAsUrl1: string | undefined;
  imageAsUrl2: string | undefined;
  imageAsUrl3: string | undefined;
  imageAsUrl4: string | undefined;
  imageAsUrl5: string | undefined;
  setImageAsUrl1: any;
  setImageAsUrl2: any;
  setImageAsUrl3: any;
  setImageAsUrl4: any;
  setImageAsUrl5: any;
  adId: string;
}) {
  const {
    isEditView,
    imageAsUrl1,
    imageAsUrl2,
    imageAsUrl3,
    imageAsUrl4,
    imageAsUrl5,
    setImageAsUrl1,
    setImageAsUrl2,
    setImageAsUrl3,
    setImageAsUrl4,
    setImageAsUrl5,
    adId
  } = props;

  const readyToSubmit = [imageAsUrl1, imageAsUrl2, imageAsUrl3, imageAsUrl4, imageAsUrl5].filter((el) => el);

  return (
    <div className="col-lg-12">
      <div className="form-group">
        <label className="form-label">images</label>
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <ImgPreview
            _id="_1"
            isEditView={isEditView}
            imageAsUrl={imageAsUrl1}
            setImageAsUrl={setImageAsUrl1}
            adId={adId}
          />
          <ImgPreview
            _id="_2"
            isEditView={isEditView}
            imageAsUrl={imageAsUrl2}
            setImageAsUrl={setImageAsUrl2}
            adId={adId}
          />
          <ImgPreview
            _id="_3"
            isEditView={isEditView}
            imageAsUrl={imageAsUrl3}
            setImageAsUrl={setImageAsUrl3}
            adId={adId}
          />
          <ImgPreview
            _id="_4"
            isEditView={isEditView}
            imageAsUrl={imageAsUrl4}
            setImageAsUrl={setImageAsUrl4}
            adId={adId}
          />
          <ImgPreview
            _id="_5"
            isEditView={isEditView}
            imageAsUrl={imageAsUrl5}
            setImageAsUrl={setImageAsUrl5}
            adId={adId}
          />
        </div>
        {readyToSubmit.length >= MAX_IMAGES && (
          <p
            style={{
              color: "orange",
              fontSize: "90%",
              lineHeight: 0
            }}
          >
            {`Maximum ${MAX_IMAGES} images.`}
          </p>
        )}
      </div>
    </div>
  );
}
