import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import { UserContext } from "../../../utils/UserProvider";
import SignUpForm from "../components/SignUpForm";
import LoginForm from "../components/LoginForm";

export default function Login() {
  const history = useHistory();
  const [isSignUpForm, setSignUpForm] = useState(false);

  // @ts-ignore
  const previousPath = history.location.state?.from;
  const user = useContext(UserContext);

  useEffect(() => {
    if (user !== null) {
      if (previousPath) {
        history.push(previousPath);
      } else {
        history.push("/ads/home");
      }
    }
  }, [history, previousPath, user]);

  return (
    <section className="user-form-part">
      <Helmet>
        <title>Signin - Aduwata.lk</title>
        <meta
          name="description"
          content="Create a new account with few steps. ඕනිම දෙයක් විකුණන්න මිලදී ගන්න හොඳම තැන - Aduwata.lk"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aduwata.lk/login" />
        <meta property="og:title" content="Signin - Aduwata.lk" />
        <meta
          property="og:description"
          content="Create a new account with few steps. ඕනිම දෙයක් විකුණන්න මිලදී ගන්න හොඳම තැන - Aduwata.lk"
        />
        <meta property="og:image" content="https://aduwata.lk/images/logo.webp" />
      </Helmet>
      <div className="user-form-banner" style={{ backgroundColor: "black" }}>
        <div className="user-form-content">
          <a href="/ads/home">
            <img src="/images/logo-long-white13June.png" alt="aduwata.lk" style={{ width: "100%" }} />
          </a>
          <h1>
            Advertise with us
            <span>Buy what you need.</span>
            <span style={{ fontSize: 18, marginTop: 15 }}>ඕනිම දෙයක් විකුණන්න මිලදී ගන්න හොඳම තැන</span>
          </h1>
        </div>
      </div>

      <div className="user-form-category">
        <div className="user-form-header">
          <a href="/ads/home">
            <img src="/images/logo-square-white13June.png" alt="logo" style={{ width: 40, height: "100%" }} />
          </a>
          <a href="/ads/home">
            <i className="fa fa-arrow-left" />
          </a>
        </div>
        <div className="user-form-category-btn" style={{ height: 62 }} />
        {isSignUpForm ? <SignUpForm setSignUpForm={setSignUpForm} /> : <LoginForm setSignUpForm={setSignUpForm} />}
      </div>
    </section>
  );
}
