export default function searchByCategoryAndSubCategory(
  iCategoryFromUrl: string,
  iSubCategoryFromUrl: string,
  setCategory: any,
  setSubCategory: any,
  history: any
) {
  setCategory(iCategoryFromUrl);
  setSubCategory(iSubCategoryFromUrl);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryFromUrl = urlSearchParams.get("query");
  // const categoryFromUrl = urlSearchParams.get("category");
  // const subCategoryFromUrl = urlSearchParams.get("subCategory");
  const citiesFromUrl = urlSearchParams.get("cities");
  const adTypesFromUrl = urlSearchParams.get("adTypes");
  // const currentPageFromUrl = urlSearchParams.get("page");

  const qq = queryFromUrl ? `query=${queryFromUrl}&` : "";
  const ct = iCategoryFromUrl.length > 0 ? `category=${iCategoryFromUrl}&` : "";
  const sc = iSubCategoryFromUrl.length > 0 ? `subCategory=${iSubCategoryFromUrl}&` : "";
  const cc = citiesFromUrl ? `cities=${citiesFromUrl}&` : "";
  const at = adTypesFromUrl ? `adTypes=${adTypesFromUrl}&` : "";
  const pg = `page=1`;

  history.push(`/ads/home?${qq}${ct}${sc}${cc}${at}${pg}`);
}
