/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import React from "react";
import { useHistory } from "react-router-dom";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";
import { getPrintableDate2 } from "../../../../utils/dateFormatter";
import { COLOR_TEXT_LIGHT_TITLE, COLOR_TEXT_SPEC } from "../../../../config/colorProfile";

export default function ViewAdAuthorCard(props: { currentAd: ReceivedAdvertisementInterface }) {
  const { currentAd } = props;
  const history = useHistory();

  return (
    <div className="ad-details-card">
      <div className="ad-details-title d-flex flex-column">
        <h5>Seller Info</h5>
        <button
          type="button"
          className="seller-info-desc"
          onClick={() => {
            history.push(`/ads/user/my-ads/${currentAd.author.url}`);
          }}
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          View member's ads
        </button>
      </div>
      <div className="ad-details-profile">
        {currentAd.author.image.length > 0 ? (
          <img
            src={currentAd.author.image}
            alt={currentAd.author.name}
            width={100}
            height={100}
            style={{ borderRadius: "50%", marginBottom: 10 }}
          />
        ) : (
          <i
            className="fa fa-user-circle-o"
            aria-hidden="true"
            style={{
              fontSize: "100px",
              marginBottom: "10px",
              color: "gray",
              opacity: 0.2
            }}
          />
        )}
        <div className="author-intro">
          <h4>{currentAd.author?.name}</h4>
        </div>
        <div className="author-details">
          <h6
            style={{
              fontStyle: "italic",
              fontSize: "14px",
              marginBottom: 20,
              color: COLOR_TEXT_LIGHT_TITLE,
              fontWeight: "bold"
            }}
          >
            {currentAd.author?.shortDescription}
          </h6>
          <h6
            style={{
              fontSize: "13px",
              color: COLOR_TEXT_SPEC
            }}
          >
            {currentAd.author?.address}
          </h6>
          <h6>{`joined: ${getPrintableDate2(currentAd.author?.joinedDate)}`}</h6>
        </div>
      </div>
    </div>
  );
}
