import React from "react";
import { Route, Switch } from "react-router-dom";
import ProfileHeaderPart from "../views/user/containers/ProfleHeaderPart";
import ViewUser from "../views/user/pages/ViewUser";
import Messages from "../views/user/pages/Messages";
import MyAds from "../views/user/pages/MyAds";
import EditUser from "../views/user/pages/EditUser";

export default function RoutesForUser() {
  return (
    <>
      <ProfileHeaderPart />
      <Switch>
        <Route exact path="/ads/user/view/:id" component={ViewUser} />
        <Route exact path="/ads/user/edit/:id" component={EditUser} />
        <Route exact path="/ads/user/my-ads/:id" component={MyAds} />
        <Route exact path="/ads/user/messages/:id" component={Messages} />
      </Switch>
    </>
  );
}
