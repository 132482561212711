import React from "react";
import numberWithCommas from "../../../../utils/priceFormatter";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";

export default function ViewAdPriceCard(props: { currentAd: ReceivedAdvertisementInterface }) {
  const { currentAd } = props;
  return (
    <div className="ad-details-price">
      {currentAd.price === 0 ? (
        <h5>Call for price</h5>
      ) : (
        <>
          <h5 style={{ width: "100%", textAlign: "start", marginBottom: 5 }}>
            {`Rs ${numberWithCommas(currentAd.price)}`}
            <br />
            {currentAd.priceUnit.length > 0 && (
              <span
                style={{
                  textTransform: "capitalize",
                  fontSize: "16px",
                  marginLeft: "0"
                }}
              >
                {currentAd.priceUnit}
              </span>
            )}
          </h5>
          {currentAd.isNegotiable ? <span>/negotiable</span> : <span>/fixed</span>}
        </>
      )}
    </div>
  );
}
