import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import yupSchemaForAdPost from "../yupSchemaForAdPost";
import { PublishAdvertisementInterface, ReceivedAdvertisementInterface } from "../../../shared/advertisementInterface";
import { editAdAction } from "../redux/actions";
import FormikOnError from "../../../utils/FormikOnError";
import AdPostTitle from "../components/AdPostTitle";
import AdPostImageUpload from "../components/AdPostImageUpload";
import AdPostCategory from "../components/AdPostCategory";
import AdPostSubCategory from "../components/AdPostSubCategory";
import AdPostLocation from "../components/AdPostLocation";
import AdPostSubLocation from "../components/AdPostSubLocation";
import AdPostPrice from "../components/AdPostPrice";
import AdPostAdType from "../components/AdPostAdType";
import AdPostDescription from "../components/AdPostDescription";
import AdPostTags from "../components/AdPostTags";
import AuthorInformation from "../components/AuthorInformation";
import AdPostSubmitBtn from "../components/AdPostSubmitBtn";
import { AppState } from "../../../store";
import { LoginState } from "../../login/redux/reducer";

export default function EditAdForm(props: { currentAd: ReceivedAdvertisementInterface }) {
  const { currentAd } = props;
  const dispatch = useDispatch();
  const { loggedInUser } = useSelector<AppState, LoginState>((state) => state.loginManagement);

  const [imageAsUrl1, setImageAsUrl1] = useState<string | undefined>(undefined);
  const [imageAsUrl2, setImageAsUrl2] = useState<string | undefined>(undefined);
  const [imageAsUrl3, setImageAsUrl3] = useState<string | undefined>(undefined);
  const [imageAsUrl4, setImageAsUrl4] = useState<string | undefined>(undefined);
  const [imageAsUrl5, setImageAsUrl5] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (currentAd?.price) {
      const elementById = document.getElementById("postAdPrice");
      if (elementById) {
        // @ts-ignore
        elementById.value = currentAd?.price;
      }
    }
    if (currentAd?.images) {
      setImageAsUrl1(currentAd?.images[0]);
      setImageAsUrl2(currentAd?.images[1]);
      setImageAsUrl3(currentAd?.images[2]);
      setImageAsUrl4(currentAd?.images[3]);
      setImageAsUrl5(currentAd?.images[4]);
    }
  }, [currentAd]);

  const initialValue: PublishAdvertisementInterface = {
    id: currentAd.id,
    title: currentAd.title,
    description: currentAd.description,
    images: currentAd.images,
    adType: currentAd.adType,
    category: currentAd.category,
    subCategory: currentAd.subCategory,
    isNegotiable: currentAd.isNegotiable,
    location: currentAd.location,
    subLocation: currentAd.subLocation,
    postedBy: currentAd.author.uid || "",
    price: currentAd.price,
    priceUnit: currentAd.priceUnit,
    specifications: currentAd.specifications,
    tags: currentAd.tags,
    name: loggedInUser?.name || "",
    email: loggedInUser?.email || "",
    contact: loggedInUser?.contact || []
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={yupSchemaForAdPost}
      onSubmit={(values, { setSubmitting }) => {
        const modifiedValues: PublishAdvertisementInterface = { ...values };
        // @ts-ignore
        modifiedValues.images = [imageAsUrl1, imageAsUrl2, imageAsUrl3, imageAsUrl4, imageAsUrl5].filter((el) => el);

        dispatch(editAdAction(modifiedValues));
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form className="adpost-form" onSubmit={handleSubmit}>
          <FormikOnError>
            <div className="adpost-card">
              <div className="adpost-title">
                <h3>Ad Information</h3>
              </div>
              <div className="row">
                <AdPostTitle
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <AdPostImageUpload
                  // eslint-disable-next-line
                  isEditView={true}
                  imageAsUrl1={imageAsUrl1}
                  setImageAsUrl1={setImageAsUrl1}
                  imageAsUrl2={imageAsUrl2}
                  setImageAsUrl2={setImageAsUrl2}
                  imageAsUrl3={imageAsUrl3}
                  setImageAsUrl3={setImageAsUrl3}
                  imageAsUrl4={imageAsUrl4}
                  setImageAsUrl4={setImageAsUrl4}
                  imageAsUrl5={imageAsUrl5}
                  setImageAsUrl5={setImageAsUrl5}
                  adId={values.id}
                />
                <AdPostCategory
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <AdPostSubCategory
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <AdPostLocation
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <AdPostSubLocation
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                {/* <AdPostGeoCoordinates handleChange={handleChange} handleBlur={handleBlur} /> */}
                {/* <div className="col-lg-6" /> */}
                <AdPostPrice
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
                <AdPostAdType handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} />
                <div className="col-lg-12" style={{ height: 20 }} />
                <AdPostDescription
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <AdPostTags handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} />
              </div>
            </div>
            <AuthorInformation loggedInUser={loggedInUser} errors={errors} />
            <AdPostSubmitBtn isSubmitting={isSubmitting} isCreate={false} />
          </FormikOnError>
        </form>
      )}
    </Formik>
  );
}
