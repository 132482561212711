/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { useSelector } from "react-redux";
import searchQuery from "../../utils/searchQuery";
import UserMenuIcon from "./UserMenuIcon";
import { COLOR_PURPLE } from "../../config/colorProfile";
import { AppState } from "../../store";
import { HomePageState } from "../../views/home/redux/reducer";
import { AdvertisementState } from "../../views/advertisement/redux/reducer";

export default function Header(): JSX.Element {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const { fetchAdvertisementsIsLoading } = useSelector<AppState, HomePageState>((state) => state.homePageManagement);
  const { fetchSingleAdIsLoading } = useSelector<AppState, AdvertisementState>(
    (state) => state.advertisementManagement
  );

  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryFromUrl = urlSearchParams.get("query");
  useEffect(() => {
    if (query !== queryFromUrl) {
      setQuery(queryFromUrl || "");
    }
  }, [queryFromUrl]);

  function handleKeyDown(event: { key: string }) {
    if (event.key === "Enter") {
      searchQuery(query, setQuery, history);
    }
  }

  const [innerWidth, setInnerWidth] = React.useState(1440);
  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const PostAdBtn = () => (
    <button
      type="button"
      onClick={() => history.push("/ads/post-ad")}
      className="btn btn-inline"
      style={{
        fontSize: "15px",
        boxShadow: "0 2px 10px 0 rgb(55 51 115 / 30%)",
        backgroundColor: COLOR_PURPLE,
        borderColor: COLOR_PURPLE
      }}
    >
      <span>post free ad</span>
    </button>
  );

  const PostAdMobileViewBtn = () => (
    <>
      {innerWidth < 768 && window.location.pathname === "/ads/home" && (
        <div
          style={{
            position: "fixed",
            zIndex: 99,
            bottom: "2%"
          }}
        >
          <button
            type="button"
            onClick={() => history.push("/ads/post-ad")}
            className="btn btn-inline"
            style={{
              borderRadius: "40px",
              padding: "10px 20px",
              fontSize: "15px",
              boxShadow: "0 2px 10px 0 rgb(55 51 115 / 30%)",
              backgroundColor: COLOR_PURPLE,
              borderColor: COLOR_PURPLE
            }}
          >
            <span>post free ad</span>
          </button>
        </div>
      )}
    </>
  );

  return (
    <>
      <header className="header-part">
        <div className="container">
          <div className="header-content">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: innerWidth < 992 ? "100%" : undefined
              }}
            >
              <div className="header-left">
                <ul className="header-widget">
                  <li>
                    <button type="button" onClick={() => history.push("/ads/home")} className="header-logo">
                      <img src="/images/gifmaker.gif" alt="logo" style={{ height: 61 }} />
                    </button>
                  </li>
                </ul>
              </div>
              {innerWidth < 992 && (
                <div className="header-right" style={{ display: "inherit" }}>
                  <UserMenuIcon innerWidth={innerWidth} />
                  {innerWidth >= 768 && <PostAdBtn />}
                </div>
              )}
            </div>

            <div className="header-search">
              <div className="header-main-search">
                <button
                  type="submit"
                  className="header-search-btn"
                  onClick={() => searchQuery(query, setQuery, history)}
                >
                  <i className="fa fa-search" />
                </button>
                <input
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="form-control"
                  placeholder="Search, Whatever you needs..."
                  onKeyDown={handleKeyDown}
                />
                <button
                  type="button"
                  className="header-option-btn"
                  onClick={() => {
                    setQuery("");
                    searchQuery("", setQuery, history);
                  }}
                >
                  <i className="fa fa-times" aria-hidden="true" style={{ opacity: 0.3 }} />
                </button>
              </div>
            </div>

            <div className="header-right">
              <UserMenuIcon innerWidth={innerWidth} />
              <PostAdBtn />
            </div>
            <PostAdMobileViewBtn />
          </div>
        </div>
      </header>
      {fetchAdvertisementsIsLoading || fetchSingleAdIsLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <BarLoader height={2} width="100%" color={COLOR_PURPLE} />
        </div>
      ) : (
        <div style={{ height: 2, width: "100%", backgroundColor: "rgba(75,72,129,0.2)" }} />
      )}
    </>
  );
}
