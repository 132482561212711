import { takeLatest } from "redux-saga/effects";
import { loginActionTypes } from "../actions";
import callSignInWithEmailAndPasswordSaga from "./signInWithEmailAndPasswordSaga";
import callCreateUserWithEmailAndPasswordSaga from "./createUserWithEmailAndPassword";
import callSignOutSaga from "./callSignOutSaga";
import callFetchLoggedInUserSaga from "./callFetchLoggedInUserSaga";
import callSignInWithGoogleSaga from "./signInWithGoogleSaga";
import callSignInWithFacebookSaga from "./signInWithFacebookSaga";
import callForgetPasswordSaga from "./callForgetPasswordSaga";

function* getLoginSagas(): any {
  yield takeLatest(loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD, callSignInWithEmailAndPasswordSaga);
  yield takeLatest(loginActionTypes.SIGN_IN_WITH_GOOGLE, callSignInWithGoogleSaga);
  yield takeLatest(loginActionTypes.SIGN_IN_WITH_FACEBOOK, callSignInWithFacebookSaga);
  yield takeLatest(loginActionTypes.CREATE_USER_WITH_EMAIL_AND_PASSWORD, callCreateUserWithEmailAndPasswordSaga);
  yield takeLatest(loginActionTypes.SIGN_OUT, callSignOutSaga);
  yield takeLatest(loginActionTypes.FETCH_LOGGED_IN_USER, callFetchLoggedInUserSaga);
  yield takeLatest(loginActionTypes.FORGET_PASSWORD, callForgetPasswordSaga);
}

// eslint-disable-next-line import/prefer-default-export
export const loginSagas = [getLoginSagas];
