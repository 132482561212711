/* eslint-disable no-param-reassign */
import produce from "immer";
import { LoginActions, loginActionTypes } from "./actions";
import User from "../../../shared/userInterface";

export interface LoginState {
  authenticationUID: string | undefined;
  signInWithEmailAndPasswordIsLoading: boolean;
  signInWithEmailAndPasswordError: { hasError: boolean; description: string };
  signInWithGoogleIsLoading: boolean;
  signInWithGoogleError: { hasError: boolean; description: string };
  signInWithFacebookIsLoading: boolean;
  signInWithFacebookError: { hasError: boolean; description: string };
  createUserWithEmailAndPasswordIsLoading: boolean;
  createUserWithEmailAndPasswordError: { hasError: boolean; description: string };
  fetchLoggedInUserIsLoading: boolean;
  loggedInUser: User | undefined;
  fetchLoggedInUserError: { hasError: boolean; description: string };
  signOutIsLoading: boolean;
  signOutIsSuccess: boolean;
  signOutError: { hasError: boolean; description: string };
}

const initialState: LoginState = {
  authenticationUID: undefined,
  signInWithEmailAndPasswordIsLoading: false,
  signInWithEmailAndPasswordError: { hasError: false, description: "" },
  signInWithGoogleIsLoading: false,
  signInWithGoogleError: { hasError: false, description: "" },
  signInWithFacebookIsLoading: false,
  signInWithFacebookError: { hasError: false, description: "" },
  createUserWithEmailAndPasswordIsLoading: false,
  createUserWithEmailAndPasswordError: { hasError: false, description: "" },
  fetchLoggedInUserIsLoading: true,
  loggedInUser: undefined,
  fetchLoggedInUserError: { hasError: false, description: "" },
  signOutIsLoading: false,
  signOutIsSuccess: false,
  signOutError: { hasError: false, description: "" }
};

export default function loginReducer(state: LoginState = initialState, action: LoginActions): LoginState {
  return produce(state, (draft) => {
    switch (action.type) {
      case loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD: {
        draft.signInWithEmailAndPasswordIsLoading = true;
        break;
      }
      case loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS: {
        draft.signInWithEmailAndPasswordIsLoading = false;
        draft.authenticationUID = action.uid;
        draft.signInWithEmailAndPasswordError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case loginActionTypes.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE: {
        draft.signInWithEmailAndPasswordIsLoading = false;
        draft.signInWithEmailAndPasswordError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case loginActionTypes.SIGN_IN_WITH_GOOGLE: {
        draft.signInWithGoogleIsLoading = true;
        break;
      }
      case loginActionTypes.SIGN_IN_WITH_GOOGLE_SUCCESS: {
        draft.signInWithGoogleIsLoading = false;
        draft.authenticationUID = action.uid;
        draft.signInWithGoogleError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case loginActionTypes.SIGN_IN_WITH_GOOGLE_FAILURE: {
        draft.signInWithGoogleIsLoading = false;
        draft.signInWithGoogleError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case loginActionTypes.SIGN_IN_WITH_FACEBOOK: {
        draft.signInWithFacebookIsLoading = true;
        break;
      }
      case loginActionTypes.SIGN_IN_WITH_FACEBOOK_SUCCESS: {
        draft.signInWithFacebookIsLoading = false;
        draft.authenticationUID = action.uid;
        draft.signInWithFacebookError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case loginActionTypes.SIGN_IN_WITH_FACEBOOK_FAILURE: {
        draft.signInWithFacebookIsLoading = false;
        draft.signInWithFacebookError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case loginActionTypes.CREATE_USER_WITH_EMAIL_AND_PASSWORD: {
        draft.createUserWithEmailAndPasswordIsLoading = true;
        break;
      }
      case loginActionTypes.CREATE_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS: {
        draft.createUserWithEmailAndPasswordIsLoading = false;
        draft.authenticationUID = action.uid;
        draft.createUserWithEmailAndPasswordError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case loginActionTypes.CREATE_USER_WITH_EMAIL_AND_PASSWORD_FAILURE: {
        draft.createUserWithEmailAndPasswordIsLoading = false;
        draft.createUserWithEmailAndPasswordError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case loginActionTypes.SET_AUTHENTICATION_UID: {
        draft.signInWithEmailAndPasswordIsLoading = false;
        draft.authenticationUID = action.uid;
        draft.signInWithEmailAndPasswordError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case loginActionTypes.RESET_SIGN_OUT: {
        draft.signOutIsSuccess = false;
        break;
      }
      case loginActionTypes.RESET_LOGIN_ERRORS: {
        draft.signInWithEmailAndPasswordError = {
          hasError: false,
          description: ""
        };
        draft.signInWithGoogleError = {
          hasError: false,
          description: ""
        };
        draft.signInWithFacebookError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case loginActionTypes.SIGN_OUT: {
        draft.signOutIsLoading = true;
        break;
      }
      case loginActionTypes.SIGN_OUT_SUCCESS: {
        draft.signOutIsLoading = true;
        draft.signOutIsSuccess = true;
        draft.authenticationUID = undefined;
        draft.loggedInUser = undefined;
        draft.signOutError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case loginActionTypes.SIGN_OUT_FAILURE: {
        draft.signOutIsLoading = true;
        draft.signOutError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case loginActionTypes.FETCH_LOGGED_IN_USER: {
        draft.fetchLoggedInUserIsLoading = true;
        break;
      }
      case loginActionTypes.FETCH_LOGGED_IN_USER_SUCCESS: {
        draft.fetchLoggedInUserIsLoading = false;
        draft.loggedInUser = action.user;
        draft.fetchLoggedInUserError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case loginActionTypes.FETCH_LOGGED_IN_USER_FAILURE: {
        draft.fetchLoggedInUserIsLoading = false;
        draft.fetchLoggedInUserError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      default:
        break;
    }
  });
}
