import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import SafetyTips from "../components/SafetyTips";
import { AppState } from "../../../store";
import PublishAdForm from "../containers/PublishAdForm";
import { resetAdStatusAction } from "../redux/actions";
import { AdvertisementState } from "../redux/reducer";
import { LoginState } from "../../login/redux/reducer";
import { UserContext } from "../../../utils/UserProvider";

export default function PublishAd(): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  const { publishAdIsSuccess } = useSelector<AppState, AdvertisementState>((state) => state.advertisementManagement);
  const { authenticationUID, loggedInUser } = useSelector<AppState, LoginState>((state) => state.loginManagement);
  const user = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      history.push("/login", { from: "/ads/post-ad" });
    }
  }, [history, user]);

  useEffect(() => {
    if (publishAdIsSuccess) {
      window.scroll(0, 0);
      dispatch(resetAdStatusAction());
    }
  }, [dispatch, publishAdIsSuccess]);

  if (authenticationUID === undefined) {
    return <div style={{ height: "100vh" }} />;
  }

  if (loggedInUser === undefined) {
    return <div style={{ height: "100vh" }} />;
  }

  return (
    <section className="adpost-part" style={{ paddingTop: 60, paddingBottom: 48 }}>
      <Helmet>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <title>Let's post an ad - Aduwata.lk</title>
        <meta name="description" content="ඕනිම දෙයක් විකුණන්න මිලදී ගන්න හොඳම තැන - Aduwata.lk" />
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-lg-8" style={{ padding: "0 6px" }}>
            <PublishAdForm />
          </div>
          <div className="col-lg-4" style={{ padding: "0 6px" }}>
            <SafetyTips />
          </div>
        </div>
      </div>
    </section>
  );
}
