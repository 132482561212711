import React from "react";

export default function ViewAdSafetyCard() {
  return (
    <div className="ad-details-card" style={{ backgroundColor: "#FBF7D6" }}>
      <div className="ad-details-title">
        <h5>Safety Tips</h5>
      </div>
      <div className="ad-details-safety">
        <ul>
          <li>
            <i className="fa fa-dot-circle-o" aria-hidden="true" />
            <p>Check the item before you buy</p>
          </li>
          <li>
            <i className="fa fa-dot-circle-o" aria-hidden="true" />
            <p>Pay only after collecting item</p>
          </li>
          <li>
            <i className="fa fa-dot-circle-o" aria-hidden="true" />
            <p>Beware of unrealistic offers</p>
          </li>
        </ul>
      </div>
    </div>
  );
}
