import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { SendMessageToAdminAction, sendMessageToAdminFailureAction, sendMessageToAdminSuccessAction } from "../actions";

async function sendMessageToAdminApiCall(name: string, email: string, subject: string, message: string) {
  const body = {
    name,
    email,
    subject,
    message
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/contact-us`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callSendMessageToAdminSaga(action: SendMessageToAdminAction) {
  try {
    // @ts-ignore
    const result = yield call(sendMessageToAdminApiCall, action.name, action.email, action.subject, action.message);
    if (result.status === "success") {
      toast.success("You have successfully sent the message.");
      yield put(sendMessageToAdminSuccessAction());
    } else {
      throw new Error(JSON.stringify(result));
    }
  } catch (e) {
    toast.error("An error occurred, Please try again later.");
    yield put(sendMessageToAdminFailureAction(e.toString()));
  }
}
