import { call, put, select } from "redux-saga/effects";
import { FetchLoggedInUserAction, fetchLoggedInUserFailureAction, fetchLoggedInUserSuccessAction } from "../actions";
import User from "../../../../shared/userInterface";
import getCurrentUserAuthorization from "../../../../utils/getAuthorizationToken";

async function fetchLoggedInUserFromApi(id: string, authToken: string) {
  return fetch(`${process.env.REACT_APP_BASE_URL}/user/${id}`, {
    method: "GET",
    headers: {
      Authorization: `bearer ${authToken}`
    }
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callFetchLoggedInUserSaga(action: FetchLoggedInUserAction) {
  const loggedInUser: User | undefined = yield select((state) => state.loginManagement.loggedInUser);
  const authToken: string = yield call(getCurrentUserAuthorization);

  try {
    // @ts-ignore
    const result = yield call(fetchLoggedInUserFromApi, action.uid || loggedInUser?.uid, authToken);
    if (result.status === "success") {
      const rUser = result.user;
      const user: User = {
        address: rUser.address,
        openHours: rUser.openHours
          ? {
            monday: rUser.openHours.monday,
            tuesday: rUser.openHours.tuesday,
            wednesday: rUser.openHours.wednesday,
            thursday: rUser.openHours.thursday,
            friday: rUser.openHours.friday,
            saturday: rUser.openHours.saturday,
            sunday: rUser.openHours.sunday
          }
          : undefined,
        shortDescription: rUser.shortDescription,
        contact: rUser.contact || [],
        description: rUser.description,
        email: rUser.email,
        image: rUser.image,
        isArchive: rUser.isArchive,
        joinedDate: new Date(rUser.joinedDate),
        name: rUser.name,
        role: rUser.role,
        type: rUser.type,
        uid: rUser.uid,
        url: rUser.url
      };
      yield put(fetchLoggedInUserSuccessAction(user));
    } else {
      yield put(fetchLoggedInUserSuccessAction(undefined));
    }
  } catch (e) {
    yield put(fetchLoggedInUserFailureAction(e.toString()));
  }
}
