/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { AppState } from "../../store";
import { LoginState } from "../../views/login/redux/reducer";
import { signOutAction } from "../../views/login/redux/actions";
import { UserContext } from "../../utils/UserProvider";
import { COLOR_LIGHT_BLUE, COLOR_PURPLE } from "../../config/colorProfile";

export default function UserMenuIcon(props: { innerWidth: number }) {
  const { innerWidth } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { authenticationUID, loggedInUser, fetchLoggedInUserIsLoading } = useSelector<AppState, LoginState>(
    (state) => state.loginManagement
  );
  const user = useContext(UserContext);

  return (
    <ul className="header-widget">
      <li>
        <div className="dropdown header-user">
          <button
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            className="header-user"
            onClick={() => {
              if (user) {
                //   dispatch(signOutAction());
              } else {
                history.push("/login");
              }
            }}
          >
            {loggedInUser?.image && loggedInUser?.image.length > 0 ? (
              <img
                src={loggedInUser?.image}
                alt="logo"
                width={40}
                height={40}
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            ) : (
              <i
                className="fa fa-user-circle-o"
                style={{
                  fontSize: 40,
                  color: COLOR_PURPLE
                }}
              />
            )}
            {innerWidth >= 576 && (
              <span
                style={{
                  lineHeight: "18px",
                  maxWidth: 120,
                  textAlign: "start",
                  fontSize: "15px",
                  fontWeight: 700,
                  color: COLOR_PURPLE
                }}
              >
                {loggedInUser ? (
                  <p
                    style={{
                      whiteSpace: "nowrap"
                    }}
                  >
                    My Account
                  </p>
                ) : (
                  "Signin"
                )}
              </span>
            )}
          </button>
          <div className="dropdown-menu header-user" aria-labelledby="dropdownMenuButton">
            {loggedInUser && authenticationUID && (
              <button type="button" className="dropdown-item" onClick={() => history.push("/ads/home")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  style={{ marginRight: 5, opacity: 0.5, minWidth: 20 }}
                >
                  <path d="M20 7.093v-5.093h-3v2.093l3 3zm4 5.907l-12-12-12 12h3v10h7v-5h4v5h7v-10h3zm-5 8h-3v-5h-8v5h-3v-10.26l7-6.912 7 6.99v10.182z" />
                </svg>
                Home
              </button>
            )}
            {loggedInUser && authenticationUID && (
              <button
                type="button"
                className="dropdown-item"
                onClick={() => history.push(`/ads/user/messages/${loggedInUser?.url}`)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  style={{ marginRight: 5, opacity: 0.5, minWidth: 20 }}
                >
                  <path d="M11.99 0l-11.99 8.723v15.277h24v-15.277l-12.01-8.723zm.001 2.472l9.793 7.113-6.735 4.588-3.049-2.47-3.049 2.471-6.737-4.589 9.777-7.113zm-9.991 9.386l5.329 3.63-5.329 4.318v-7.948zm.474 10.142l9.526-7.723 9.526 7.723h-19.052zm19.526-2.194l-5.329-4.317 5.329-3.631v7.948z" />
                </svg>
                Messages
              </button>
            )}
            {loggedInUser && authenticationUID && (
              <button
                type="button"
                className="dropdown-item"
                onClick={() => history.push(`/ads/user/my-ads/${loggedInUser?.url}`)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  style={{ marginRight: 5, opacity: 0.5, minWidth: 20 }}
                >
                  <path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" />
                </svg>
                My Ads
              </button>
            )}
            {loggedInUser && authenticationUID && (
              <button
                type="button"
                className="dropdown-item"
                onClick={() => history.push(`/ads/user/view/${loggedInUser?.url}`)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  style={{ marginRight: 5, opacity: 0.5, minWidth: 20 }}
                >
                  <path d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z" />
                </svg>
                Profile
              </button>
            )}
            {fetchLoggedInUserIsLoading && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  height: 136,
                  alignItems: "center",
                  flexDirection: "column"
                }}
              >
                <PulseLoader size={10} margin={1} color={COLOR_LIGHT_BLUE} loading />
              </div>
            )}
            {!fetchLoggedInUserIsLoading && !loggedInUser && authenticationUID && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                onClick={() => window.location.reload()}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  height: 136,
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "pointer"
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ opacity: 0.4 }}
                >
                  <path d="M13.5 2c-5.629 0-10.212 4.436-10.475 10h-3.025l4.537 5.917 4.463-5.917h-2.975c.26-3.902 3.508-7 7.475-7 4.136 0 7.5 3.364 7.5 7.5s-3.364 7.5-7.5 7.5c-2.381 0-4.502-1.119-5.876-2.854l-1.847 2.449c1.919 2.088 4.664 3.405 7.723 3.405 5.798 0 10.5-4.702 10.5-10.5s-4.702-10.5-10.5-10.5z" />
                </svg>
                <p
                  style={{
                    fontSize: "13px",
                    color: "#777777"
                  }}
                >
                  Reload Page
                </p>
              </div>
            )}
            <div className="dropdown-divider" />
            {authenticationUID && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  dispatch(signOutAction());
                }}
                style={{ cursor: "pointer" }}
              >
                Sign out
              </button>
            )}
          </div>
        </div>
      </li>
    </ul>
  );
}
