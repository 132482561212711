import React from "react";

export default function SafetyTips() {
  return (
    <div className="account-card alert fade show" style={{ backgroundColor: "#FBF7D6" }}>
      <div className="account-title">
        <h3>Safety Tips</h3>
      </div>
      <ul className="account-card-text">
        <li>
          <p>Make sure your post has a valid title. Buyers should be able to easily understand.</p>
        </li>
        <li>
          <p>Do not upload pictures with watermarks.</p>
        </li>
        <li>
          <p>Select the correct category and location.</p>
        </li>
        <li>
          <p>Do not post the same ad multiple times. Use renew button instead of that.</p>
        </li>
        <li>
          <p>Do not post multiple items under one advertisement.</p>
        </li>
        <li>
          <p>Do not put your email or phone numbers in the title or description.</p>
        </li>
      </ul>
    </div>
  );
}
