export const COLOR_RED = "#DC3545";
export const COLOR_PRIMARY_BLUE = "#0022AA";
export const COLOR_LIGHT_BLUE = "#E8F0FE";
export const COLOR_OFF_WHITE = "#F5F5F5";
export const COLOR_OFF_BLACK = "#555555";
export const COLOR_LIGHT_PURPLE = "#6e0ad6";
export const COLOR_PURPLE = "#373373";
export const COLOR_TEXT_INFO = "#3e4153";
export const COLOR_TEXT_LIGHT_TITLE = "#6f727f";
export const COLOR_TEXT_SPEC = "#8e909b";
export const COLOR_TEXT_LINK = "#2681db";
