import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Redirect, Route, Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import { AppState } from "../store";
import ScrollToTop from "./ScrollToTop";
import { fetchCountsAction } from "../views/home/redux/actions";
import RoutesWithHeaderAndFooter from "./RoutesWithHeaderAndFooter";
import { LoginState } from "../views/login/redux/reducer";
import { fetchLoggedInUserAction, resetSignOutAction } from "../views/login/redux/actions";
import Login from "../views/login/pages/Login";

const history = createBrowserHistory();

const trackingId = process.env.REACT_APP_GA_ID as string;
ReactGA.initialize(trackingId, {
  titleCase: false
});

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

export default function RouterHandler() {
  const dispatch = useDispatch();
  const { authenticationUID, signOutIsSuccess, loggedInUser } = useSelector<AppState, LoginState>(
    (state) => state.loginManagement
  );

  useEffect(() => {
    dispatch(fetchCountsAction());
  }, [dispatch]);

  useEffect(() => {
    if (authenticationUID && authenticationUID !== loggedInUser?.uid) {
      setTimeout(() => {
        dispatch(fetchLoggedInUserAction(authenticationUID));
      }, 2000);
    }
  }, [authenticationUID, dispatch]);

  useEffect(() => {
    if (signOutIsSuccess) {
      dispatch(resetSignOutAction());
      history.push("/sign-out");
    }
  }, [dispatch, signOutIsSuccess]);

  return (
    <Router history={history}>
      <ScrollToTop />
      <Route exact path="/login" component={Login} />
      <Route path="/ads" component={RoutesWithHeaderAndFooter} />
      <Route exact path="/">
        <Redirect to="/ads/home" />
      </Route>
      <Route exact path="/sign-out">
        <Redirect to="/ads/home" />
      </Route>
    </Router>
  );
}
