import { takeLatest } from "redux-saga/effects";
import { advertisementActionTypes } from "../actions";
import callPublishAdSaga from "./callPublishAdSaga";
import callEditAdSaga from "./callEditAdSaga";
import callDeleteAdSaga from "./callDeleteAdSaga";
import callFetchSingleAdSaga from "./callFetchSingleAdSaga";
import callReportAdSaga from "./callReportAdSaga";
import callSendMessageToSellerSaga from "./callSendMessageToSellerSaga";

function* getPostAdSagas(): any {
  yield takeLatest(advertisementActionTypes.PUBLISH_AD, callPublishAdSaga);
  yield takeLatest(advertisementActionTypes.EDIT_AD, callEditAdSaga);
  yield takeLatest(advertisementActionTypes.DELETE_AD, callDeleteAdSaga);
  yield takeLatest(advertisementActionTypes.FETCH_SINGLE_AD, callFetchSingleAdSaga);
  yield takeLatest(advertisementActionTypes.REPORT_AD, callReportAdSaga);
  yield takeLatest(advertisementActionTypes.SEND_MESSAGE_TO_SELLER_AD, callSendMessageToSellerSaga);
}

// eslint-disable-next-line import/prefer-default-export
export const advertisementSagas = [getPostAdSagas];
