import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import SafetyTips from "../components/SafetyTips";
import { AppState } from "../../../store";
import { fetchSingleAdvertisementAction, resetAdStatusAction } from "../redux/actions";
import { AdvertisementState } from "../redux/reducer";
import Error from "../../../shared/components/Error";
import EditAdForm from "../containers/EditAdForm";
import { LoginState } from "../../login/redux/reducer";
import { UserContext } from "../../../utils/UserProvider";

export default function EditAd(props: { match: any }): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  const adUrl = match.params.id;

  const { authenticationUID, loggedInUser } = useSelector<AppState, LoginState>((state) => state.loginManagement);
  const { editAdIsSuccess, adUrl: updatedUrl, singleAd: currentAd, fetchSingleAdIsLoading } = useSelector<
    AppState,
    AdvertisementState
  >((state) => state.advertisementManagement);
  const user = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      history.push("/login", { from: `/ads/edit-ad/${adUrl}` });
    }
  }, [adUrl, history, user]);

  useEffect(() => {
    if (adUrl) {
      if (currentAd?.url !== adUrl) {
        dispatch(fetchSingleAdvertisementAction(adUrl));
      }
    }
  }, [dispatch, adUrl, currentAd?.url]);

  useEffect(() => {
    if (editAdIsSuccess) {
      window.scroll(0, 0);
      dispatch(resetAdStatusAction());
      setTimeout(() => {
        history.push(`/ads/view-ad/${updatedUrl}`);
      }, 1000);
    }
  }, [dispatch, history, updatedUrl, editAdIsSuccess]);

  if (authenticationUID === undefined) {
    return <div style={{ height: "100vh" }} />;
  }
  if (fetchSingleAdIsLoading) {
    return <div style={{ height: "100vh" }} />;
  }
  if (currentAd === undefined) {
    return <Error />;
  }
  if (loggedInUser?.role !== "admin" && loggedInUser?.uid !== currentAd.author?.uid) {
    return <Error />;
  }

  return (
    <section className="adpost-part" style={{ paddingTop: 60, paddingBottom: 48 }}>
      <Helmet>
        <title>Edit your ad - Aduwata.lk</title>
        <meta name="description" content="ඕනිම දෙයක් විකුණන්න මිලදී ගන්න හොඳම තැන - Aduwata.lk" />
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-lg-8" style={{ padding: "0 6px" }}>
            <EditAdForm currentAd={currentAd} />
          </div>
          <div className="col-lg-4" style={{ padding: "0 6px" }}>
            <SafetyTips />
          </div>
        </div>
      </div>
    </section>
  );
}
