import React from "react";
import { COLOR_RED } from "./colorProfile";

const errorStyles = {
  err: ({
    color: COLOR_RED,
    paddingLeft: "20px",
    fontSize: "80%",
    fontWeight: "400",
    lineHeight: "18px"
  } as unknown) as React.CSSProperties
};

export default errorStyles;
