import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { PersistGate } from "redux-persist/lib/integration/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";
import i18n from "./config/i18n";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div />}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
