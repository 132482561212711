import firebase from "firebase";

export default async function getCurrentUserAuthorization(): Promise<any> {
  const { currentUser } = firebase.auth();
  if (currentUser) {
    // eslint-disable-next-line no-return-await
    return await currentUser.getIdToken();
  }
  return "";
}
