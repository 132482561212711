/* eslint-disable no-param-reassign */
import produce from "immer";
import { call, put, takeEvery } from "redux-saga/effects";
import { remoteConfig } from "../config/firebase";
import { CATEGORIES } from "../config/categories";
import { LOCATIONS } from "../config/locations";
import { AD_TYPES } from "../config/adTypes";

enum remoteConfigActionTypes {
  FETCH_REMOTE_CONFIG = "aduwata-lk/locations/fetch_remote_config",
  FETCH_REMOTE_CONFIG_SUCCESS = "aduwata-lk/locations/fetch_remote_config_success",
  FETCH_REMOTE_CONFIG_FAILURE = "aduwata-lk/locations/fetch_remote_config_failure"
}

interface FetchRemoteConfigAction {
  type: remoteConfigActionTypes.FETCH_REMOTE_CONFIG;
}

interface FetchRemoteConfigSuccessAction {
  type: remoteConfigActionTypes.FETCH_REMOTE_CONFIG_SUCCESS;
  categories: any;
  adTypes: any;
  locations: any;
}

interface FetchRemoteConfigFailureAction {
  type: remoteConfigActionTypes.FETCH_REMOTE_CONFIG_FAILURE;
  err: string;
}

function fetchRemoteConfigAction(): FetchRemoteConfigAction {
  return {
    type: remoteConfigActionTypes.FETCH_REMOTE_CONFIG
  };
}

function fetchRemoteConfigSuccessAction(categories: any, adTypes: any, locations: any): FetchRemoteConfigSuccessAction {
  return {
    type: remoteConfigActionTypes.FETCH_REMOTE_CONFIG_SUCCESS,
    adTypes,
    categories,
    locations
  };
}

function fetchRemoteConfigFailureAction(err: string): FetchRemoteConfigFailureAction {
  console.error("fetchRemoteConfigFailureAction", err);
  return {
    type: remoteConfigActionTypes.FETCH_REMOTE_CONFIG_FAILURE,
    err
  };
}

type RemoteConfigActionTypes =
  | FetchRemoteConfigAction
  | FetchRemoteConfigSuccessAction
  | FetchRemoteConfigFailureAction;

export interface RemoteConfigState {
  categories: any;
  adTypes: any[];
  locations: any;
  fetchRemoteConfigIsLoading: boolean;
  fetchRemoteConfigFailure: { description: string; hasError: boolean };
}

const initialState: RemoteConfigState = {
  adTypes: AD_TYPES,
  categories: CATEGORIES,
  locations: LOCATIONS,
  fetchRemoteConfigIsLoading: false,
  fetchRemoteConfigFailure: { description: "", hasError: false }
};

export default function remoteConfigReducer(state: RemoteConfigState = initialState, action: RemoteConfigActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case remoteConfigActionTypes.FETCH_REMOTE_CONFIG: {
        draft.fetchRemoteConfigIsLoading = true;
        break;
      }
      case remoteConfigActionTypes.FETCH_REMOTE_CONFIG_SUCCESS: {
        draft.categories = action.categories;
        draft.adTypes = action.adTypes;
        draft.locations = action.locations;
        draft.fetchRemoteConfigIsLoading = false;
        draft.fetchRemoteConfigFailure = { hasError: false, description: "" };
        break;
      }
      case remoteConfigActionTypes.FETCH_REMOTE_CONFIG_FAILURE: {
        draft.fetchRemoteConfigIsLoading = false;
        draft.fetchRemoteConfigFailure = { hasError: true, description: action.err };
        break;
      }
      default:
        break;
    }
  });
}

// ------------------------------------------------------saga-1----------------------------------------------------------
async function fetchRemoteConfigFromFirestore() {
  await remoteConfig.fetch();
  await remoteConfig.activate();
  const config = remoteConfig.getAll();

  const configProps = {};

  Object.keys(config).forEach((c) => {
    // @ts-ignore
    configProps[c] = config[c]._value;
  });

  return configProps;
}

function* callFetchRemoteConfig() {
  try {
    // @ts-ignore
    const result = yield call(fetchRemoteConfigFromFirestore);

    const categories = JSON.parse(result.categories);
    const adTypes = JSON.parse(result.ad_types);
    const locations = JSON.parse(result.locations);
    yield put(fetchRemoteConfigSuccessAction(categories, adTypes, locations));
  } catch (e) {
    yield put(fetchRemoteConfigFailureAction(e.toString()));
  }
}

//-----------------------------------------------------------------------------------------------------------------------

function* watchRemoteConfigActionSagas() {
  yield takeEvery(remoteConfigActionTypes.FETCH_REMOTE_CONFIG, callFetchRemoteConfig);
}

const remoteConfigSagas = [watchRemoteConfigActionSagas];
export { remoteConfigSagas, fetchRemoteConfigAction };
