/* eslint-disable no-param-reassign */
import produce from "immer";
import { AdvertisementCardInterface } from "../../../shared/advertisementInterface";
import { HomePageActions, homePageActionTypes } from "./actions";

export interface HomePageState {
  fetchAdvertisementsIsLoading: boolean;
  advertisements: AdvertisementCardInterface[];
  totalAds: number;
  fetchAdvertisementsError: { hasError: boolean; description: string };
  fetchCountsIsLoading: boolean;
  categoryCounts: any;
  cityCounts: any;
  totalAdCount: number;
  fetchCountsError: { hasError: boolean; description: string };
  subscribeIsLoading: boolean;
  subscribeError: { hasError: boolean; description: string };
}

const initialState: HomePageState = {
  fetchAdvertisementsIsLoading: false,
  advertisements: [],
  totalAds: 0,
  fetchAdvertisementsError: { hasError: false, description: "" },
  fetchCountsIsLoading: false,
  categoryCounts: {},
  cityCounts: {},
  totalAdCount: 0,
  fetchCountsError: { description: "", hasError: false },
  subscribeIsLoading: false,
  subscribeError: { hasError: false, description: "" }
};

export default function homePageReducer(state: HomePageState = initialState, action: HomePageActions): HomePageState {
  return produce(state, (draft) => {
    switch (action.type) {
      case homePageActionTypes.FETCH_ADVERTISEMENTS: {
        draft.fetchAdvertisementsIsLoading = true;
        break;
      }
      case homePageActionTypes.FETCH_ADVERTISEMENTS_SUCCESS: {
        draft.fetchAdvertisementsIsLoading = false;
        draft.advertisements = action.advertisements;
        draft.totalAds = action.totalAds;
        draft.fetchAdvertisementsError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case homePageActionTypes.FETCH_ADVERTISEMENTS_FAILURE: {
        draft.fetchAdvertisementsIsLoading = false;
        draft.fetchAdvertisementsError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case homePageActionTypes.FETCH_COUNTS: {
        draft.fetchCountsIsLoading = true;
        break;
      }
      case homePageActionTypes.FETCH_COUNTS_SUCCESS: {
        draft.fetchCountsIsLoading = false;
        draft.categoryCounts = action.categoryCounts;
        draft.cityCounts = action.cityCounts;
        draft.totalAdCount = action.totalAdCount;
        draft.fetchCountsError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case homePageActionTypes.FETCH_COUNTS_FAILURE: {
        draft.fetchCountsIsLoading = false;
        draft.fetchCountsError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case homePageActionTypes.SUBSCRIBE: {
        draft.subscribeIsLoading = true;
        break;
      }
      case homePageActionTypes.SUBSCRIBE_SUCCESS: {
        draft.subscribeIsLoading = false;
        draft.subscribeError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case homePageActionTypes.SUBSCRIBE_FAILURE: {
        draft.subscribeIsLoading = false;
        draft.subscribeError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      default:
        break;
    }
  });
}
