/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { UserState } from "../redux/reducer";
import Error from "../../../shared/components/Error";
import { fetchCustomUserAction } from "../redux/actions";
import UnAuthorized from "../../../shared/components/UnAuthorized";
import { LoginState } from "../../login/redux/reducer";
import EditUserForm from "../containers/EditUserForm";
import ManageMyContactModal from "../containers/ManageMyContactModal";

export default function EditUser(props: { match: any }): JSX.Element {
  const dispatch = useDispatch();
  const { match } = props;
  const userUrl = match.params.id;
  const { fetchCustomUserIsLoading, customUser: currentUser } = useSelector<AppState, UserState>(
    (state) => state.userManagement
  );
  const { authenticationUID } = useSelector<AppState, LoginState>((state) => state.loginManagement);

  useEffect(() => {
    if (userUrl && userUrl !== currentUser?.url) {
      dispatch(fetchCustomUserAction(userUrl));
    }
  }, [currentUser, dispatch, userUrl]);

  if (fetchCustomUserIsLoading) {
    return <div style={{ height: "100vh" }} />;
  }
  if (currentUser === undefined) {
    return <Error />;
  }
  if (authenticationUID === undefined || authenticationUID !== currentUser.uid) {
    return <UnAuthorized />;
  }

  return (
    <>
      <Helmet>
        <title>Edit My Profile - Aduwata.lk</title>
        <meta name="description" content="ඕනිම දෙයක් විකුණන්න මිලදී ගන්න හොඳම තැන - Aduwata.lk" />
      </Helmet>
      <ManageMyContactModal currentUser={currentUser} />
      <div className="setting-part" style={{ marginBottom: 48 }}>
        <div className="container" style={{ padding: "0 6px" }}>
          <div className="row">
            <div className="col-lg-12">
              <EditUserForm currentUser={currentUser} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
