import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { RemoteConfigState } from "../../../utils/remoteConfigurations";
import errorStyles from "../../../config/errorStyles";

// @ts-ignore
export default function AdPostSubLocation(props) {
  const { handleChange, handleBlur, values, errors, touched } = props;
  const { t } = useTranslation();
  const { locations } = useSelector<AppState, RemoteConfigState>((state) => state.remoteConfigManagement);
  const subLocationsList = Object.values(locations).flat().sort();

  return (
    <div className="col-lg-6">
      <div className="form-group">
        <label className="form-label">
          Sub Location<span style={{ color: "red" }}> *</span>
        </label>
        <select
          className="form-control custom-select"
          name="subLocation"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.subLocation}
        >
          <option value="">Select Sub Location</option>
          {(locations[values.location] || subLocationsList).map((currentSubLocation: any, index: number) => (
            <option key={index} value={currentSubLocation}>
              {t(currentSubLocation)}
            </option>
          ))}
        </select>
        {errors.subLocation && touched.subLocation && <p style={errorStyles.err}>{errors.subLocation}</p>}
      </div>
    </div>
  );
}
