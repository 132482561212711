import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { reportAdAction } from "../../redux/actions";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";
import onCaptchaChange from "../../../login/redux/onCaptchaChange";

export default function ReportAdModal(props: { ad: ReceivedAdvertisementInterface }) {
  const dispatch = useDispatch();
  const { ad } = props;
  const [reason, setReason] = useState<string>("OTHER");
  const [description, setDescription] = useState<string>("");
  const [notARobot, setNotARobot] = useState(false);

  return (
    <div
      className="modal"
      id="reportAdModal"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Report This Ad ?
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ color: "black" }}>
            <p>Please choose the option that best describes your complaint.</p>
            <div className="d-flex flex-column">
              <div className="form-check w-100 h-100 pl-5 my-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="option1"
                  checked={reason === "MISSING_OR_WRONG_INFORMATION"}
                  onChange={() => setReason("MISSING_OR_WRONG_INFORMATION")}
                />
                <label className="form-check-label" htmlFor="exampleRadios1">
                  Missing / wrong information
                </label>
              </div>
              <div className="form-check w-100 h-100 pl-5 my-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="option2"
                  checked={reason === "POLICY_VIOLENCE"}
                  onChange={() => setReason("POLICY_VIOLENCE")}
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                  Policy Violence
                </label>
              </div>
              <div className="form-check w-100 h-100 pl-5 my-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios3"
                  value="option3"
                  checked={reason === "OTHER"}
                  onChange={() => setReason("OTHER")}
                />
                <label className="form-check-label" htmlFor="exampleRadios3">
                  Other
                </label>
              </div>
              <div style={{ marginLeft: 48 }}>
                <textarea
                  style={{
                    maxHeight: "100px",
                    padding: "5px 12px"
                  }}
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  className="form-control"
                  placeholder="Let the admins know what's wrong with this ad."
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div
              className="col-12"
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <ReCAPTCHA
                onExpired={() => setNotARobot(false)}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ""}
                onChange={(value) => onCaptchaChange(value, setNotARobot)}
              />
            </div>
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
              style={{
                height: "35px",
                padding: "0 32px",
                textTransform: "capitalize"
              }}
            >
              Close
            </button>
            <button
              disabled={!notARobot}
              type="button"
              className="btn btn-danger btn-sm"
              data-dismiss="modal"
              style={{
                height: "35px",
                padding: "0 32px",
                textTransform: "capitalize"
              }}
              onClick={() => {
                setReason("OTHER");
                setDescription("");
                dispatch(reportAdAction(ad.id, `${reason}:${description}`));
              }}
            >
              Report Ad
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
