import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { PulseLoader } from "react-spinners";
import moment from "moment";
import User from "../../../shared/userInterface";
import FormikOnError from "../../../utils/FormikOnError";
import { editUserProfileAction } from "../redux/actions";
import errorStyles from "../../../config/errorStyles";
import { COLOR_LIGHT_BLUE, COLOR_PURPLE, COLOR_TEXT_LINK } from "../../../config/colorProfile";
import { AppState } from "../../../store";
import { UserState } from "../redux/reducer";
import EditOpeningTime from "../components/EditOpeningTime";

function getAvailableHours(
  isClosed: boolean,
  isOpen24h: boolean,
  startTime: string | undefined,
  endTime: string | undefined
) {
  const st = moment(startTime, "HH:mm").format("hh:mm A");
  const et = moment(endTime, "HH:mm").format("hh:mm A");
  return isClosed
    ? "Closed"
    : isOpen24h
      ? "Open 24 hours"
      : `${st === "Invalid date" ? "00:00 AM" : st} - ${et === "Invalid date" ? "11:59 PM" : et}`;
}

function getStartTimeFromPrevious(day: string | undefined) {
  return day?.includes(" - ") ? moment(day?.split(" - ")[0], "hh:mm A").format("HH:mm") : "09:00";
}

function getEndTimeFromPrevious(day: string | undefined) {
  return day?.includes(" - ") ? moment(day?.split(" - ")[0], "hh:mm A").format("HH:mm") : "18:00";
}

export default function EditUserForm(props: { currentUser: User }) {
  const { currentUser } = props;
  const dispatch = useDispatch();
  const { editUserProfileIsLoading } = useSelector<AppState, UserState>((state) => state.userManagement);

  const yupSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").max(20, "Maximum 20 characters"),
    email: Yup.string().email("Valid email is required").required("Email is required"),
    address: Yup.string().max(100, "Maximum 100 characters"),
    contact: Yup.array()
      .of(
        Yup.object({
          number: Yup.string(),
          verified: Yup.boolean()
        })
      )
      .min(1, "Must have at least one contact number")
      .required("Contact number required"),
    shortDescription: Yup.string().max(100, "Maximum 100 characters"),
    description: Yup.string().max(600, "Maximum 600 characters")
  });

  const initialValue: User = {
    uid: currentUser.uid,
    url: currentUser.url,
    image: currentUser.image,
    shortDescription: currentUser.shortDescription,
    description: currentUser.description,
    name: currentUser.name,
    address: currentUser.address,
    role: currentUser.role,
    type: currentUser.type,
    email: currentUser.email,
    contact: currentUser.contact,
    joinedDate: currentUser.joinedDate,
    isArchive: currentUser.isArchive,
    openHours: currentUser.openHours
  };

  const { openHours } = currentUser;
  const [mondayIsClosed, setMondayIsClosed] = useState<boolean>(!openHours || openHours?.monday === "Closed");
  const [mondayIsOpen24h, setMondayIsOpen24h] = useState<boolean>(openHours?.monday === "Open 24 hours");
  const [mondayStartTime, setMondayStartTime] = useState<string | undefined>(
    getStartTimeFromPrevious(openHours?.monday)
  );
  const [mondayEndTime, setMondayEndTime] = useState<string | undefined>(getEndTimeFromPrevious(openHours?.monday));
  //-----------
  const [tuesdayIsClosed, setTuesdayIsClosed] = useState<boolean>(!openHours || openHours?.tuesday === "Closed");
  const [tuesdayIsOpen24h, setTuesdayIsOpen24h] = useState<boolean>(openHours?.tuesday === "Open 24 hours");
  const [tuesdayStartTime, setTuesdayStartTime] = useState<string | undefined>(
    getStartTimeFromPrevious(openHours?.tuesday)
  );
  const [tuesdayEndTime, setTuesdayEndTime] = useState<string | undefined>(getEndTimeFromPrevious(openHours?.tuesday));
  //-----------
  const [wednesdayIsClosed, setWednesdayIsClosed] = useState<boolean>(!openHours || openHours?.wednesday === "Closed");
  const [wednesdayIsOpen24h, setWednesdayIsOpen24h] = useState<boolean>(openHours?.wednesday === "Open 24 hours");
  const [wednesdayStartTime, setWednesdayStartTime] = useState<string | undefined>(
    getStartTimeFromPrevious(openHours?.wednesday)
  );
  const [wednesdayEndTime, setWednesdayEndTime] = useState<string | undefined>(
    getEndTimeFromPrevious(openHours?.wednesday)
  );
  //-----------
  const [thursdayIsClosed, setThursdayIsClosed] = useState<boolean>(!openHours || openHours?.thursday === "Closed");
  const [thursdayIsOpen24h, setThursdayIsOpen24h] = useState<boolean>(openHours?.thursday === "Open 24 hours");
  const [thursdayStartTime, setThursdayStartTime] = useState<string | undefined>(
    getStartTimeFromPrevious(openHours?.thursday)
  );
  const [thursdayEndTime, setThursdayEndTime] = useState<string | undefined>(
    getEndTimeFromPrevious(openHours?.thursday)
  );
  //-----------
  const [fridayIsClosed, setFridayIsClosed] = useState<boolean>(!openHours || openHours?.friday === "Closed");
  const [fridayIsOpen24h, setFridayIsOpen24h] = useState<boolean>(openHours?.friday === "Open 24 hours");
  const [fridayStartTime, setFridayStartTime] = useState<string | undefined>(
    getStartTimeFromPrevious(openHours?.friday)
  );
  const [fridayEndTime, setFridayEndTime] = useState<string | undefined>(getEndTimeFromPrevious(openHours?.friday));
  //-----------
  const [saturdayIsClosed, setSaturdayIsClosed] = useState<boolean>(!openHours || openHours?.saturday === "Closed");
  const [saturdayIsOpen24h, setSaturdayIsOpen24h] = useState<boolean>(openHours?.saturday === "Open 24 hours");
  const [saturdayStartTime, setSaturdayStartTime] = useState<string | undefined>(
    getStartTimeFromPrevious(openHours?.saturday)
  );
  const [saturdayEndTime, setSaturdayEndTime] = useState<string | undefined>(
    getEndTimeFromPrevious(openHours?.saturday)
  );
  //-----------
  const [sundayIsClosed, setSundayIsClosed] = useState<boolean>(!openHours || openHours?.sunday === "Closed");
  const [sundayIsOpen24h, setSundayIsOpen24h] = useState<boolean>(openHours?.sunday === "Open 24 hours");
  const [sundayStartTime, setSundayStartTime] = useState<string | undefined>(
    getStartTimeFromPrevious(openHours?.sunday)
  );
  const [sundayEndTime, setSundayEndTime] = useState<string | undefined>(getEndTimeFromPrevious(openHours?.sunday));

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={yupSchema}
      onSubmit={(values, { setSubmitting }) => {
        const modifiedValues: User = { ...values };

        if (modifiedValues.type === "organization") {
          modifiedValues.openHours = {
            monday: getAvailableHours(mondayIsClosed, mondayIsOpen24h, mondayStartTime, mondayEndTime),
            tuesday: getAvailableHours(tuesdayIsClosed, tuesdayIsOpen24h, tuesdayStartTime, tuesdayEndTime),
            wednesday: getAvailableHours(wednesdayIsClosed, wednesdayIsOpen24h, wednesdayStartTime, wednesdayEndTime),
            thursday: getAvailableHours(thursdayIsClosed, thursdayIsOpen24h, thursdayStartTime, thursdayEndTime),
            friday: getAvailableHours(fridayIsClosed, fridayIsOpen24h, fridayStartTime, fridayEndTime),
            saturday: getAvailableHours(saturdayIsClosed, saturdayIsOpen24h, saturdayStartTime, saturdayEndTime),
            sunday: getAvailableHours(sundayIsClosed, sundayIsOpen24h, sundayStartTime, sundayEndTime)
          };
        } else {
          modifiedValues.openHours = undefined;
        }
        dispatch(editUserProfileAction(modifiedValues));
        setSubmitting(false);
        setTimeout(() => {
          window.scroll(0, 0);
        }, 2000);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, isValid }) => (
        <form className="adpost-form" onSubmit={handleSubmit}>
          <FormikOnError>
            <div className="account-card alert fade show">
              <div className="account-title">
                <h3>Edit Profile</h3>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">
                      Name<span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      className="form-control"
                      placeholder=""
                    />
                    {errors.name && touched.name && <p style={errorStyles.err}>{errors.name}</p>}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Email<span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      disabled={currentUser.email.length > 0}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="form-control"
                      placeholder=""
                    />
                    {errors.email && touched.email && <p style={errorStyles.err}>{errors.email}</p>}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label className="form-label">User Type</label>
                    <select
                      className="form-control custom-select"
                      name="type"
                      onChange={(e) => {
                        setFieldValue("type", e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.type}
                    >
                      <option value="single">Single</option>
                      <option value="organization">Organization</option>
                    </select>
                    {errors.type && touched.type && <p style={errorStyles.err}>{errors.type}</p>}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group" style={{ position: "relative" }}>
                    <label className="form-label">
                      Contact Number<span style={{ color: "red" }}> *</span>
                    </label>
                    <button
                      type="button"
                      style={{
                        position: "absolute",
                        left: "140px",
                        top: "1px",
                        border: "none",
                        backgroundColor: "transparent"
                      }}
                      data-toggle="modal"
                      data-target="#manageMyContactModal"
                    >
                      <i
                        className="fa fa-pencil-square"
                        aria-hidden="true"
                        style={{ fontSize: 18, color: COLOR_TEXT_LINK }}
                      />
                    </button>
                    <input
                      disabled
                      type="text"
                      name="contact"
                      value={currentUser.contact.map((el) => el.number).join(", ")}
                      className="form-control"
                      placeholder=""
                    />
                    {errors.contact && touched.contact && <p style={errorStyles.err}>{errors.contact}</p>}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">Address</label>
                    <input
                      type="text"
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                      className="form-control"
                      placeholder=""
                    />
                    {errors.address && touched.address && <p style={errorStyles.err}>{errors.address}</p>}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">Short Description</label>
                    <input
                      type="text"
                      name="shortDescription"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.shortDescription}
                      className="form-control"
                      placeholder=""
                    />
                    {errors.shortDescription && touched.shortDescription && (
                      <p style={errorStyles.err}>{errors.shortDescription}</p>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <textarea
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      className="form-control"
                      placeholder="About the shop"
                    />
                    {errors.description && touched.description && <p style={errorStyles.err}>{errors.description}</p>}
                  </div>
                </div>
                {values.type === "organization" && (
                  <div className="col-12">
                    <div className="form-group" style={{ marginBottom: 40 }}>
                      <label className="form-label mb-0">Opening Hours</label>
                      <EditOpeningTime
                        day="monday"
                        isClosed={mondayIsClosed}
                        setIsClosed={setMondayIsClosed}
                        isOpen24h={mondayIsOpen24h}
                        setIsOpen24h={setMondayIsOpen24h}
                        startTime={mondayStartTime}
                        setStartTime={setMondayStartTime}
                        endTime={mondayEndTime}
                        setEndTime={setMondayEndTime}
                      />
                      <EditOpeningTime
                        day="tuesday"
                        isClosed={tuesdayIsClosed}
                        setIsClosed={setTuesdayIsClosed}
                        isOpen24h={tuesdayIsOpen24h}
                        setIsOpen24h={setTuesdayIsOpen24h}
                        startTime={tuesdayStartTime}
                        setStartTime={setTuesdayStartTime}
                        endTime={tuesdayEndTime}
                        setEndTime={setTuesdayEndTime}
                      />
                      <EditOpeningTime
                        day="wednesday"
                        isClosed={wednesdayIsClosed}
                        setIsClosed={setWednesdayIsClosed}
                        isOpen24h={wednesdayIsOpen24h}
                        setIsOpen24h={setWednesdayIsOpen24h}
                        startTime={wednesdayStartTime}
                        setStartTime={setWednesdayStartTime}
                        endTime={wednesdayEndTime}
                        setEndTime={setWednesdayEndTime}
                      />
                      <EditOpeningTime
                        day="thursday"
                        isClosed={thursdayIsClosed}
                        setIsClosed={setThursdayIsClosed}
                        isOpen24h={thursdayIsOpen24h}
                        setIsOpen24h={setThursdayIsOpen24h}
                        startTime={thursdayStartTime}
                        setStartTime={setThursdayStartTime}
                        endTime={thursdayEndTime}
                        setEndTime={setThursdayEndTime}
                      />
                      <EditOpeningTime
                        day="friday"
                        isClosed={fridayIsClosed}
                        setIsClosed={setFridayIsClosed}
                        isOpen24h={fridayIsOpen24h}
                        setIsOpen24h={setFridayIsOpen24h}
                        startTime={fridayStartTime}
                        setStartTime={setFridayStartTime}
                        endTime={fridayEndTime}
                        setEndTime={setFridayEndTime}
                      />
                      <EditOpeningTime
                        day="saturday"
                        isClosed={saturdayIsClosed}
                        setIsClosed={setSaturdayIsClosed}
                        isOpen24h={saturdayIsOpen24h}
                        setIsOpen24h={setSaturdayIsOpen24h}
                        startTime={saturdayStartTime}
                        setStartTime={setSaturdayStartTime}
                        endTime={saturdayEndTime}
                        setEndTime={setSaturdayEndTime}
                      />
                      <EditOpeningTime
                        day="sunday"
                        isClosed={sundayIsClosed}
                        setIsClosed={setSundayIsClosed}
                        isOpen24h={sundayIsOpen24h}
                        setIsOpen24h={setSundayIsOpen24h}
                        startTime={sundayStartTime}
                        setStartTime={setSundayStartTime}
                        endTime={sundayEndTime}
                        setEndTime={setSundayEndTime}
                      />
                    </div>
                  </div>
                )}
                {Object.keys(errors).length > 0 && (
                  <div className="col-lg-12">
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "red",
                        textAlign: "center",
                        marginBottom: "10px",
                        lineHeight: "18px"
                      }}
                    >
                      Please correct errors before submitting this form
                    </p>
                  </div>
                )}
                <div className="col-lg-12 form-group text-right" style={{ marginBottom: 0 }}>
                  <button
                    style={{
                      fontSize: "15px",
                      backgroundColor: COLOR_PURPLE,
                      borderColor: COLOR_PURPLE
                    }}
                    type="submit"
                    className="btn btn-inline"
                    disabled={isSubmitting}
                  >
                    {editUserProfileIsLoading ? (
                      <div
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex"
                        }}
                      >
                        <PulseLoader size={10} margin={1} color={COLOR_LIGHT_BLUE} loading />
                      </div>
                    ) : (
                      <span>update profile</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </FormikOnError>
        </form>
      )}
    </Formik>
  );
}
