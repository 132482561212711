import React from "react";
import styled from "styled-components";

export const CardPriceText = styled.p`
  font-weight: 600;
  color: #6e0ad6;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CardLocationText = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #8e909b;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 22px;
`;

export const CardPriceUnitText = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: #707676;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 16px;
  margin-top: -6px;
`;

export const CardCategoryTitle = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #6f727f;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 16px;
`;

export const CardTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #3e4153;
  min-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 19px;
  margin-bottom: 5px;
`;
