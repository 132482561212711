import React from "react";
import errorStyles from "../../../config/errorStyles";

// @ts-ignore
export default function AdPostTitle(props) {
  const { handleChange, handleBlur, values, errors, touched } = props;
  return (
    <div className="col-lg-12">
      <div className="form-group">
        <label className="form-label">
          Title<span style={{ color: "red" }}> *</span>
        </label>
        <input
          type="text"
          name="title"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          className="form-control"
          placeholder="Type your product title here"
        />
        {errors.title && touched.title && <p style={errorStyles.err}>{errors.title}</p>}
      </div>
    </div>
  );
}
