import React from "react";
import { AdvertisementCardInterface } from "../../../shared/advertisementInterface";
import getBackgroundColorCode from "../../../utils/getBackgroundColorCode";

export default function ImageOnlyCard(props: { ad: AdvertisementCardInterface }) {
  const { ad } = props;

  return (
    <div
      className="col-6 col-sm-6 col-md-4 col-lg-4 card-grid"
      style={{
        marginBottom: window.innerWidth < 576 ? 6 : 12,
        cursor: "pointer",
        padding: window.innerWidth < 576 ? "0 3px" : "0 6px"
      }}
    >
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a href={ad.url} target="_blank" style={{ width: "100%" }}>
        <div className="product-card">
          <div className="product-head">
            <div
              className="product-img"
              style={{
                backgroundColor: "rgb(55, 51, 115)",
                backgroundImage: `url('${ad.images[0]}')`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                height: window.innerWidth < 576 ? "307px" : "370px",
                borderRadius: "8px"
              }}
            >
              {ad.isVerified && <i className="cross-badge fa fa-shield" />}
              <span
                className="flat-badge rent"
                style={{
                  backgroundColor: getBackgroundColorCode(ad.adType)
                }}
              >
                Promotion
              </span>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
