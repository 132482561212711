// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default async function onCaptchaChange(value: string | null, setNotARobot: any) {
  if (value) {
    try {
      await fetch(
        `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_RECAPTCHA_SECRETKEY}&response=${value}`,
        {
          method: "POST"
        }
      )
        .then((response: any) => response.json())
        .then((data) => {
          setNotARobot(data.success);
        })
        .catch((err) => err);
    } catch (e) {
      //
    }
  }
}
