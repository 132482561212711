import * as Yup from "yup";

const yupSchemaForAdPost = Yup.object().shape({
  title: Yup.string()
    .required("Ad Title is required")
    .min(10, "Minimum 10 characters")
    .max(60, "Maximum 60 characters"),
  category: Yup.string().required("Category is required"),
  subCategory: Yup.string().when("category", {
    is: (value: string | any[]) =>
      value && value.length > 0 && value !== "services" && value !== "other" && value !== "jobs",
    then: Yup.string().required("Sub category is required")
  }),
  location: Yup.string().required("Location is required"),
  subLocation: Yup.string().when("location", {
    is: (value: string | any[]) => value && value.length > 0,
    then: Yup.string().required("Sub location is required")
  }),
  price: Yup.number().moreThan(-1, "Enter valid Amount").required("Price is required"),
  description: Yup.string().required("Description is required"),
  name: Yup.string().required("Name is required").max(20, "Maximum 20 characters"),
  email: Yup.string().email("Valid email is required").required("Email is required"),
  contact: Yup.array()
    .of(
      Yup.object({
        number: Yup.string(),
        verified: Yup.boolean()
      })
    )
    .min(1, "Must have at least one contact number")
    .required("Contact number required")
});

export default yupSchemaForAdPost;
