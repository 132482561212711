import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { ReportAdAction, reportAdFailureAction, reportAdSuccessAction } from "../actions";

async function reportAdApiCall(adId: string, reason: string) {
  const body = {
    reason,
    adId
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/report-ad`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callReportAdSaga(action: ReportAdAction) {
  try {
    // @ts-ignore
    const result = yield call(reportAdApiCall, action.adId, action.reason);
    if (result.status === "success") {
      toast.success("You have successfully reported this ad.");
      yield put(reportAdSuccessAction());
    } else {
      throw new Error(JSON.stringify(result));
    }
  } catch (e) {
    toast.error("An error occurred, Please try again later.");
    yield put(reportAdFailureAction(e.toString()));
  }
}
