import { call, put } from "redux-saga/effects";
import {
  FetchAdvertisementsAction,
  fetchAdvertisementsFailureAction,
  fetchAdvertisementsSuccessAction
} from "../actions";
import { AdvertisementCardInterface } from "../../../../shared/advertisementInterface";

async function fetchAdvertisementFromFirestore(
  query: string,
  category: string,
  subCategory: string,
  cities: string[],
  adTypes: string[],
  page: number
) {
  const body = {
    query,
    category,
    subCategory,
    cities,
    adTypes,
    page
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callFetchAdvertisementSaga(action: FetchAdvertisementsAction) {
  try {
    // @ts-ignore
    const result = yield call(
      fetchAdvertisementFromFirestore,
      action.query,
      action.category,
      action.subCategory,
      action.cities,
      action.adTypes,
      action.page
    );

    const idResult: AdvertisementCardInterface[] = (result.items || []).map((el: any) => ({
      url: el.url,
      title: el.title,
      images: el.images,
      adType: el.adType,
      category: el.category,
      subCategory: el.subCategory,
      isNegotiable: el.isNegotiable,
      location: el.location,
      subLocation: el.subLocation,
      postedOn: new Date(el.postedOn),
      price: el.price,
      priceUnit: el.priceUnit,
      isVerified: el.isVerified
    }));

    const banner1: AdvertisementCardInterface = {
      adType: "",
      category: "",
      images: ["/images/product/banner1.gif"],
      isNegotiable: false,
      isVerified: false,
      location: "",
      postedOn: new Date(),
      price: 0,
      priceUnit: "",
      subCategory: "",
      subLocation: "",
      title: "",
      url: "https://aduwata.lk/ads/post-ad"
    };
    const banner2: AdvertisementCardInterface = {
      adType: "",
      category: "",
      images: ["/images/product/banner2.jpg"],
      isNegotiable: false,
      isVerified: false,
      location: "",
      postedOn: new Date(),
      price: 0,
      priceUnit: "",
      subCategory: "",
      subLocation: "",
      title: "",
      url: "https://aduwata.lk/ads/post-ad"
    };
    const banner3: AdvertisementCardInterface = {
      adType: "",
      category: "",
      images: ["/images/product/banner3.jpg"],
      isNegotiable: false,
      isVerified: false,
      location: "",
      postedOn: new Date(),
      price: 0,
      priceUnit: "",
      subCategory: "",
      subLocation: "",
      title: "",
      url: "https://aduwata.lk/ads/contact-us"
    };
    const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;
    const selected1 = random(1, 10);
    const selected2 = random(11, 20);
    const selected3 = random(21, 30);

    const segment1 = idResult.slice(0, selected1).concat(banner1);
    const segment2 = idResult.slice(selected1, selected2).concat(banner2);
    const segment3 = idResult.slice(selected2, selected3).concat(banner3);
    const segment4 = idResult.slice(selected3, idResult.length);

    yield put(
      fetchAdvertisementsSuccessAction(
        segment1.concat(segment2).concat(segment3).concat(segment4),
        result.totalAds || 0
      )
    );
  } catch (e) {
    yield put(fetchAdvertisementsFailureAction(e.toString()));
  }
}
