/* eslint-disable no-param-reassign */
import produce from "immer";
import { UserActions, userActionTypes } from "./actions";
import User from "../../../shared/userInterface";
import { AdvertisementCardInterface } from "../../../shared/advertisementInterface";
import Message from "../../../shared/messageInterface";

export interface UserState {
  fetchCustomUserIsLoading: boolean;
  customUser: User | undefined;
  fetchCustomUserError: { hasError: boolean; description: string };
  fetchMyAdsIsLoading: boolean;
  myAds: AdvertisementCardInterface[];
  totalAds: number;
  fetchMyAdsError: { hasError: boolean; description: string };
  editUserProfileIsLoading: boolean;
  fetchMessagesIsLoading: boolean;
  messages: Message[];
  fetchMessagesError: { hasError: boolean; description: string };
}

const initialState: UserState = {
  fetchCustomUserIsLoading: true,
  customUser: undefined,
  fetchCustomUserError: { hasError: false, description: "" },
  fetchMyAdsIsLoading: false,
  myAds: [],
  totalAds: 0,
  fetchMyAdsError: { description: "", hasError: false },
  editUserProfileIsLoading: false,
  fetchMessagesIsLoading: false,
  messages: [],
  fetchMessagesError: { description: "", hasError: false }
};

export default function userReducer(state: UserState = initialState, action: UserActions): UserState {
  return produce(state, (draft) => {
    switch (action.type) {
      case userActionTypes.FETCH_CUSTOM_USER: {
        draft.fetchCustomUserIsLoading = true;
        break;
      }
      case userActionTypes.FETCH_CUSTOM_USER_SUCCESS: {
        draft.fetchCustomUserIsLoading = false;
        draft.customUser = action.user;
        draft.fetchCustomUserError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case userActionTypes.FETCH_CUSTOM_USER_FAILURE: {
        draft.fetchCustomUserIsLoading = false;
        draft.fetchCustomUserError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case userActionTypes.FETCH_MY_ADS: {
        draft.fetchMyAdsIsLoading = true;
        break;
      }
      case userActionTypes.FETCH_MY_ADS_SUCCESS: {
        draft.fetchMyAdsIsLoading = false;
        draft.myAds = action.myAds;
        draft.totalAds = action.totalAds;
        draft.fetchCustomUserError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case userActionTypes.FETCH_MY_ADS_FAILURE: {
        draft.fetchMyAdsIsLoading = false;
        draft.fetchCustomUserError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      case userActionTypes.SET_PROFILE_IMG_URL: {
        if (draft.customUser) {
          draft.customUser.image = action.imageUrl;
        }
        break;
      }
      case userActionTypes.EDIT_USER_PROFILE: {
        draft.editUserProfileIsLoading = true;
        break;
      }
      case userActionTypes.EDIT_USER_PROFILE_SUCCESS: {
        draft.editUserProfileIsLoading = false;
        break;
      }
      case userActionTypes.EDIT_USER_PROFILE_FAILURE: {
        draft.editUserProfileIsLoading = false;
        break;
      }
      case userActionTypes.FETCH_MESSAGES: {
        draft.fetchMessagesIsLoading = true;
        break;
      }
      case userActionTypes.FETCH_MESSAGES_SUCCESS: {
        draft.fetchMessagesIsLoading = false;
        draft.messages = action.messages;
        draft.fetchMessagesError = {
          hasError: false,
          description: ""
        };
        break;
      }
      case userActionTypes.FETCH_MESSAGES_FAILURE: {
        draft.fetchMessagesIsLoading = false;
        draft.fetchMessagesError = {
          hasError: true,
          description: action.err
        };
        break;
      }
      default:
        break;
    }
  });
}
