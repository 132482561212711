/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { AppState } from "../../store";
import { HomePageState } from "../../views/home/redux/reducer";
import { subscribeAction } from "../../views/home/redux/actions";
import errorStyles from "../../config/errorStyles";
import { COLOR_PURPLE } from "../../config/colorProfile";

export default function Footer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { totalAdCount } = useSelector<AppState, HomePageState>((state) => state.homePageManagement);

  const yupSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email").required("Email is required")
  });

  const initialValues = { email: "" };
  return (
    <footer className="footer-part">
      <div className="container">
        <div className="row newsletter">
          <div className="col-lg-6">
            <div className="news-content">
              <h2>Subscribe for Latest Offers</h2>
              <p>Online Marketplace operation 24/7</p>
            </div>
          </div>
          <div className="col-lg-6">
            <Formik
              initialValues={initialValues}
              validationSchema={yupSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                dispatch(subscribeAction(values.email));
                setSubmitting(false);
                // @ts-ignore
                resetForm(initialValues);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="news-form">
                    <input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      placeholder="Enter Your Email Address"
                    />
                    {errors.email && touched.email && <p style={errorStyles.err}>{errors.email}</p>}
                    <button
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      className="btn btn-inline"
                      style={{
                        fontSize: "15px",
                        backgroundColor: COLOR_PURPLE,
                        borderColor: COLOR_PURPLE
                      }}
                    >
                      <i className="fa fa-envelope" />
                      <span>Subscribe</span>
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer-content">
              <h3>Contact Us</h3>
              <ul className="footer-address">
                <li>
                  <i className="fa fa-map-marker" aria-hidden="true" />
                  <p>688/c/1, Elhena rd, Gothatuwa, Colombo.</p>
                </li>
                <li>
                  <i className="fa fa-envelope" aria-hidden="true" />
                  <p>aduwata.lk@aol.com</p>
                </li>
                <li>
                  <i className="fa fa-phone" aria-hidden="true" />
                  <p>
                    071 108 2602 <span>071 098 1602</span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer-content">
              <h3>Quick Links</h3>
              <ul className="footer-widget">
                <li>
                  <button
                    type="button"
                    onClick={() => history.push("/ads/faq")}
                    style={{
                      background: "transparent",
                      border: "none"
                    }}
                  >
                    Faq
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => history.push("/ads/terms-of-use")}
                    style={{
                      background: "transparent",
                      border: "none"
                    }}
                  >
                    Terms of Use
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => history.push("/ads/privacy-policy")}
                    style={{
                      background: "transparent",
                      border: "none"
                    }}
                  >
                    Privacy Policy
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer-content">
              <h3>Information</h3>
              <ul className="footer-widget">
                <li>
                  <button
                    type="button"
                    onClick={() => history.push("/ads/about-us")}
                    style={{
                      background: "transparent",
                      border: "none"
                    }}
                  >
                    About Us
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={() => history.push("/ads/contact-us")}
                    style={{
                      background: "transparent",
                      border: "none"
                    }}
                  >
                    Contact Us
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="footer-info">
              <button
                type="button"
                onClick={() => history.push("/ads/home")}
                style={{
                  background: "transparent",
                  border: "none",
                  marginBottom: 10
                }}
              >
                <img src="/images/logo-square-white13June.png" alt="logo" style={{ width: 60, height: "100%" }} />
              </button>
              <ul className="footer-count">
                <li>
                  <h5>{`${Math.ceil(totalAdCount / 10) * 10}+`}</h5>
                  <p>Community Ads</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-end">
        <div className="container">
          <div className="footer-end-content">
            <p>All Copyrights Reserved &copy; 2021</p>
            <ul className="social-transparent footer-social">
              <li>
                <a href="https://www.facebook.com/aduwataclassifieds" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-facebook-official" aria-hidden="true" style={{ fontSize: 22 }} />
                </a>
              </li>
              <li>
                <a href="https://lk.linkedin.com/" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-linkedin-square" aria-hidden="true" style={{ fontSize: 22 }} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
