import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import Header from "../shared/components/Header";
import Home from "../views/home/pages/Home";
import Footer from "../shared/components/Footer";
import ViewAd from "../views/advertisement/pages/ViewAd";
import Error from "../shared/components/Error";
import RoutesForUser from "./RoutesForUser";
import EditAd from "../views/advertisement/pages/EditAd";
import PublishAd from "../views/advertisement/pages/PublishAd";

const LazyPublishAd = lazy(() => import("../views/advertisement/pages/PublishAd"));
const LazyEditAd = lazy(() => import("../views/advertisement/pages/EditAd"));
const LazyAboutUs = lazy(() => import("../views/aboutUs/pages/AboutUs"));
const LazyContactUs = lazy(() => import("../views/contactUs/pages/ContactUs"));
const LazyPrivacyPolicy = lazy(() => import("../views/privacyPolicy/pages/PrivacyPolicy"));
const LazyTermsOfUse = lazy(() => import("../views/termsOfUse/pages/TermsOfUse"));
const LazyFAQ = lazy(() => import("../views/faq/pages/FAQ"));

export default function RoutesWithHeaderAndFooter() {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/ads/home" component={Home} />
        <Route exact path="/ads/view-ad/:id" component={ViewAd} />
        <Route exact path="/ads/post-ad" component={PublishAd} />
        <Route exact path="/ads/edit-ad/:id" component={EditAd} />
        <Route exact path="/ads/about-us" component={LazyAboutUs} />
        <Route exact path="/ads/contact-us" component={LazyContactUs} />
        <Route exact path="/ads/privacy-policy" component={LazyPrivacyPolicy} />
        <Route exact path="/ads/terms-of-use" component={LazyTermsOfUse} />
        <Route exact path="/ads/faq" component={LazyFAQ} />
        <Route path="/ads/user" component={RoutesForUser} />
        <Route component={Error} />
      </Switch>
      <Footer />
    </>
  );
}
