// @ts-ignore
import firebase from "firebase/app";
// import 'firebase/firestore';
import "firebase/auth";
import "firebase/remote-config";
import "firebase/storage";
import "firebase/analytics";
import "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyDiP8v3OGKL8PNMpAKxwlHcpqH63dTix8M",
  authDomain: "aduwata-lk.firebaseapp.com",
  projectId: "aduwata-lk",
  storageBucket: "aduwata-lk.appspot.com",
  messagingSenderId: "88965911076",
  appId: "1:88965911076:web:802c0cec415e437202beb1",
  measurementId: "G-RLDL3K30XF"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
export const auth = firebase.auth();
// export const firestore = firebase.firestore();
export const remoteConfig = firebase.remoteConfig();
export const storageRef = firebase.storage().ref();

const perf = firebase.performance(); // Done
const analytics = firebase.analytics(); // Done

remoteConfig.settings = {
  fetchTimeoutMillis: 36000,
  minimumFetchIntervalMillis: 1000
};
