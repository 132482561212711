import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { sendMessageToSellerAdAction } from "../../redux/actions";
import errorStyles from "../../../../config/errorStyles";
import { ReceivedAdvertisementInterface } from "../../../../shared/advertisementInterface";
import { COLOR_PURPLE } from "../../../../config/colorProfile";
import onCaptchaChange from "../../../login/redux/onCaptchaChange";

export default function ViewAdMessageToSeller(props: { currentAd: ReceivedAdvertisementInterface }) {
  const dispatch = useDispatch();
  const { currentAd } = props;
  const [notARobot, setNotARobot] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    contactNo: "",
    message: ""
  };

  const phoneRegExp = /^0[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/;
  const yupSchema = Yup.object().shape(
    {
      name: Yup.string().required("Name is required").min(3, "Minimum 3 characters").max(60, "Maximum 60 characters"),
      email: Yup.string().when("contactNo", {
        is: (value: any) => !value,
        then: Yup.string().email("Email must be a valid email").required("Email or Contact number is required")
      }),
      contactNo: Yup.string().when("email", {
        is: (value: any) => !value,
        then: Yup.string()
          .matches(phoneRegExp, "Must contain 10 numbers. (eg: 071 XXX XXXX)")
          .required("Email or Contact number is required")
      }),
      message: Yup.string().required("Message is required").max(600, "Maximum 600 characters")
    },
    [["email", "contactNo"]]
  );

  return (
    <div className="ad-details-card">
      <div className="ad-details-title">
        <h5>Message to Seller</h5>
      </div>
      <div className="ad-details-review">
        <Formik
          initialValues={initialValues}
          validationSchema={yupSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            dispatch(
              sendMessageToSellerAdAction(
                currentAd.id,
                currentAd.author.uid,
                values.name,
                values.email,
                values.contactNo,
                values.message
              )
            );
            setSubmitting(false);
            // @ts-ignore
            resetForm(initialValues);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
            <form className="ad-review-form" onSubmit={handleSubmit}>
              <div className="ad-review-form-grid">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    className="form-control"
                    placeholder="Name"
                  />
                  {errors.name && touched.name && <p style={errorStyles.err}>{errors.name}</p>}
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className="form-control"
                    placeholder="Email"
                  />
                  {errors.email && touched.email && <p style={errorStyles.err}>{errors.email}</p>}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="contactNo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactNo}
                    className="form-control"
                    placeholder="Contact No"
                  />
                  {errors.contactNo && touched.contactNo && <p style={errorStyles.err}>{errors.contactNo}</p>}
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  className="form-control"
                  placeholder="Message"
                />
                {errors.message && touched.message && <p style={errorStyles.err}>{errors.message}</p>}
              </div>
              <div
                className="col-12"
                style={{
                  marginBottom: "20px",
                  display: isValid && values.message.length > 0 ? "flex" : "none",
                  justifyContent: "center"
                }}
              >
                <ReCAPTCHA
                  onExpired={() => setNotARobot(false)}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ""}
                  onChange={(value) => onCaptchaChange(value, setNotARobot)}
                />
              </div>
              <button
                disabled={!isValid || isSubmitting || !notARobot}
                style={{
                  fontSize: "15px",
                  backgroundColor: COLOR_PURPLE,
                  borderColor: COLOR_PURPLE
                }}
                type="submit"
                className="btn btn-inline"
              >
                <span>drop a message</span>
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
