/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";
import { AppState } from "../../../store";
import { UserState } from "../redux/reducer";
import { fetchLoggedInUserAction, signOutAction } from "../../login/redux/actions";
import { LoginState } from "../../login/redux/reducer";
import getCurrentUserAuthorization from "../../../utils/getAuthorizationToken";
import { setProfileImgUrlAction } from "../redux/actions";

async function uploadProfileImage(file: any, userId: string) {
  const formData = new FormData();
  formData.append("image", file);

  const authToken = await getCurrentUserAuthorization();
  return fetch(`${process.env.REACT_APP_BASE_URL}/image/user/${userId}`, {
    method: "POST",
    headers: {
      // Accept: "application/json",
      // "Content-Type": "multipart/form-data",
      Authorization: `bearer ${authToken}`
    },
    body: formData
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

const StyledContainer = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }
  scrollbar-width: none;
`;

export default function ProfileHeaderPart() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState<undefined | string>(undefined);

  const { customUser: currentUser, fetchCustomUserIsLoading } = useSelector<AppState, UserState>(
    (state) => state.userManagement
  );
  const { authenticationUID } = useSelector<AppState, LoginState>((state) => state.loginManagement);

  if (currentUser === undefined) {
    return <div />;
  }
  if (fetchCustomUserIsLoading) {
    return <div />;
  }

  const handleImageAsFile = async (e: any) => {
    setImageIsLoading(true);
    const file = e.target.files[0];
    if (file && authenticationUID) {
      setImagePreview(URL.createObjectURL(file));
      const res = await uploadProfileImage(file, authenticationUID);

      if (res.status === "success") {
        dispatch(fetchLoggedInUserAction(authenticationUID));
        dispatch(setProfileImgUrlAction(res.imageUrl));
        setImagePreview(undefined);
      }
    }
    setImageIsLoading(false);
  };

  const isAuthenticatedUser = currentUser.uid === authenticationUID;
  return (
    <section
      className="dash-header-part"
      style={{
        // backgroundColor: "white",
        paddingTop: 12,
        paddingBottom: 12
      }}
    >
      <div className="container" style={{ padding: "0 6px" }}>
        <div className="dash-header-card">
          <div className="row">
            <div className="col-lg-5">
              <div className="dash-header-left">
                <div className="dash-avatar" style={{ position: "relative" }}>
                  {currentUser.image ? (
                    <img
                      src={imagePreview || currentUser?.image}
                      alt="avatar"
                      width={140}
                      height={140}
                      style={{ borderRadius: "50%", opacity: imageIsLoading ? 0.5 : 1, objectFit: "cover" }}
                    />
                  ) : (
                    <i
                      className="fa fa-user-circle-o"
                      aria-hidden="true"
                      style={{
                        fontSize: 140,
                        color: "gray",
                        opacity: 0.1
                      }}
                    />
                  )}
                  {imageIsLoading && (
                    <div
                      className="d-flex justify-content-center align-items-center h-100 w-100"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0
                      }}
                    >
                      <ClipLoader size={50} color="lightgray" />
                    </div>
                  )}
                  {isAuthenticatedUser && (
                    <>
                      <input
                        id="uploadprfileimage"
                        type="file"
                        accept="image/*"
                        onChange={handleImageAsFile}
                        style={{
                          opacity: "0",
                          width: "24px",
                          height: "24px",
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                          zIndex: 9
                        }}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                          cursor: "pointer",
                          filter: "drop-shadow( 0px 0px 3px white)"
                        }}
                      >
                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-5 17l1.006-4.036 3.106 3.105-4.112.931zm5.16-1.879l-3.202-3.202 5.841-5.919 3.201 3.2-5.84 5.921z" />
                      </svg>
                    </>
                  )}
                </div>
                <div className="dash-intro">
                  <h4>{currentUser.name}</h4>
                  <h5>{currentUser.shortDescription}</h5>
                  <ul className="dash-meta">
                    {currentUser.contact.length > 0 &&
                      currentUser.contact.map((el, index) => (
                        <li key={index}>
                          <i
                            className="fa fa-phone"
                            aria-hidden="true"
                            style={{
                              minWidth: "25px",
                              fontSize: "20px",
                              margin: "3px 0 0 0"
                            }}
                          />
                          <a href={`tel:${el.number}}`}>{el.number}</a>
                        </li>
                      ))}
                    {currentUser.email && (
                      <li>
                        <i
                          className="fa fa-envelope-o"
                          aria-hidden="true"
                          style={{
                            minWidth: "25px",
                            fontSize: "17px",
                            margin: "3px 0 0 0"
                          }}
                        />
                        <a href={`mailto:${currentUser.email}`}>{currentUser.email}</a>
                      </li>
                    )}
                    {currentUser.address && (
                      <li>
                        <i
                          className="fa fa-map-marker"
                          aria-hidden="true"
                          style={{
                            minWidth: "25px",
                            fontSize: "20px",
                            margin: "3px 0 0 0"
                          }}
                        />
                        <span style={{ maxWidth: 174, overflowX: "hidden" }}>{currentUser.address}</span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="dash-header-right">
                <div className="dash-focus dash-list">
                  <h2>13</h2>
                  <p>listing ads</p>
                </div>
                <div className="dash-focus dash-book">
                  <h2>4</h2>
                  <p>sold ads</p>
                </div>
                {isAuthenticatedUser && (
                  <div className="dash-focus dash-rev">
                    <h2>123</h2>
                    <p>messages</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <StyledContainer className="dash-menu-list">
                <ul>
                  <li>
                    <button
                      type="button"
                      className={window.location.pathname.includes("/ads/user/view/") ? "active" : ""}
                      onClick={() => history.push(`/ads/user/view/${currentUser?.url}`)}
                    >
                      Profile
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={window.location.pathname.includes("/ads/user/my-ads/") ? "active" : ""}
                      onClick={() => history.push(`/ads/user/my-ads/${currentUser?.url}`)}
                    >
                      Ads
                    </button>
                  </li>
                  {isAuthenticatedUser ? (
                    <li>
                      <button
                        type="button"
                        className={window.location.pathname.includes("/ads/user/edit/") ? "active" : ""}
                        onClick={() => history.push(`/ads/user/edit/${currentUser?.url}`)}
                      >
                        settings
                      </button>
                    </li>
                  ) : (
                    <li />
                  )}
                  {isAuthenticatedUser ? (
                    <li>
                      <button
                        type="button"
                        className={window.location.pathname.includes("/ads/user/messages/") ? "active" : ""}
                        onClick={() => history.push(`/ads/user/messages/${currentUser?.url}`)}
                      >
                        message
                      </button>
                    </li>
                  ) : (
                    <li />
                  )}
                  {isAuthenticatedUser ? (
                    <li>
                      <button
                        type="button"
                        onClick={() => {
                          dispatch(signOutAction());
                        }}
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          fontWeight: 500,
                          textAlign: "center",
                          textTransform: "uppercase",
                          padding: "12px 0px",
                          color: "red",
                          background: "var(--white)",
                          borderBottom: "2px solid var(--white)",
                          cursor: "pointer"
                        }}
                      >
                        Sign out
                      </button>
                    </li>
                  ) : (
                    <li />
                  )}
                  <li />
                  <li />
                </ul>
              </StyledContainer>
              <div
                style={{
                  zIndex: 999,
                  position: "absolute",
                  width: "40px",
                  height: "52px",
                  right: "15px",
                  top: "30px",
                  backgroundImage:
                    "linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0) 15%, white)"
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
