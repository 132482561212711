export default function searchQuery(iQuery: string, setQuery: any, history: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  // const queryFromUrl = urlSearchParams.get("query");
  const categoryFromUrl = urlSearchParams.get("category");
  const subCategoryFromUrl = urlSearchParams.get("subCategory");
  const citiesFromUrl = urlSearchParams.get("cities");
  const adTypesFromUrl = urlSearchParams.get("adTypes");
  // const currentPageFromUrl = urlSearchParams.get("page");

  const qq = iQuery.length > 0 ? `query=${iQuery}&` : "";
  const ct = categoryFromUrl ? `category=${categoryFromUrl}&` : "";
  const sc = subCategoryFromUrl ? `subCategory=${subCategoryFromUrl}&` : "";
  const cc = citiesFromUrl ? `cities=${citiesFromUrl}&` : "";
  const at = adTypesFromUrl ? `adTypes=${adTypesFromUrl}&` : "";
  const pg = `page=1`;

  history.push(`/ads/home?${qq}${ct}${sc}${cc}${at}${pg}`);
}
