import { takeLatest } from "redux-saga/effects";
import { aboutUsPageActionTypes } from "../actions";
import callFetchAboutUsCountsSaga from "./callFetchAboutUsCountsSaga";

function* getAboutUsSagas(): any {
  yield takeLatest(aboutUsPageActionTypes.FETCH_ABOUT_US_COUNTS, callFetchAboutUsCountsSaga);
}

// eslint-disable-next-line import/prefer-default-export
export const aboutUsPageSagas = [getAboutUsSagas];
