/* eslint-disable react/no-unescaped-entities */
import { Formik } from "formik";
import { PulseLoader } from "react-spinners";
import React, { useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { AppState } from "../../../store";
import { LoginState } from "../redux/reducer";
import { COLOR_LIGHT_BLUE, COLOR_PURPLE, COLOR_RED } from "../../../config/colorProfile";
import { CreateUser } from "../../../shared/userInterface";
import { createUserWithEmailAndPasswordAction } from "../redux/actions";
import errorStyles from "../../../config/errorStyles";
import onCaptchaChange from "../redux/onCaptchaChange";

export default function SignUpForm(props: { setSignUpForm: any }) {
  const { setSignUpForm } = props;
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [notARobot, setNotARobot] = useState(false);

  const {
    createUserWithEmailAndPasswordError: {
      hasError: createUserWithEmailAndPasswordHasError,
      description: createUserWithEmailAndPasswordHasErrorDescription
    },
    createUserWithEmailAndPasswordIsLoading
  } = useSelector<AppState, LoginState>((state) => state.loginManagement);

  const phoneRegExp = /^0[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/;

  const yupSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").max(20, "Maximum 20 characters"),
    contact: Yup.array()
      .of(Yup.string().matches(phoneRegExp, "Must contain 10 numbers. (eg: 071 XXX XXXX)"))
      .required("Contact number is required")
      .min(1, "Contact number is required"),
    email: Yup.string().email("Valid email is required").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Must contain at least 6 characters")
      .max(12, "Maximum length 12 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required")
  });

  const initialValue: CreateUser = {
    name: "",
    contact: [],
    email: "",
    password: "",
    confirmPassword: ""
  };

  return (
    <div className="tab-pane active" id="login-tab">
      <div className="user-form-title">
        <h2>Register</h2>
        <p>Setup a new account in a minute.</p>
      </div>
      <Formik
        initialValues={initialValue}
        validationSchema={yupSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(createUserWithEmailAndPasswordAction(values));
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    className="form-control"
                    placeholder="Name *"
                  />
                  {errors.name && touched.name && <p style={errorStyles.err}>{errors.name}</p>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    name="contact.[0]"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    placeholder="Contact Number *"
                  />
                  {errors.contact && touched.contact && <p style={errorStyles.err}>{errors.contact}</p>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className="form-control"
                    placeholder="Email *"
                  />
                  {errors.email && touched.email && <p style={errorStyles.err}>{errors.email}</p>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    className="form-control"
                    placeholder="Password *"
                  />
                  <button
                    type="button"
                    className="form-icon"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ marginTop: 2 }}
                  >
                    {showPassword ? (
                      <i className="fa fa-eye-slash" aria-hidden="true" />
                    ) : (
                      <i className="fa fa-eye" aria-hidden="true" />
                    )}
                  </button>
                  {errors.password && touched.password && <p style={errorStyles.err}>{errors.password}</p>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    className="form-control"
                    placeholder="Confirm Password *"
                  />
                  <button
                    type="button"
                    className="form-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{ marginTop: 2 }}
                  >
                    {showPassword ? (
                      <i className="fa fa-eye-slash" aria-hidden="true" />
                    ) : (
                      <i className="fa fa-eye" aria-hidden="true" />
                    )}
                  </button>
                  {errors.confirmPassword && touched.confirmPassword && (
                    <p style={errorStyles.err}>{errors.confirmPassword}</p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  {createUserWithEmailAndPasswordHasError && (
                    <p
                      style={{
                        color: COLOR_RED,
                        textAlign: "center"
                      }}
                    >
                      {createUserWithEmailAndPasswordHasErrorDescription === "auth/email-already-in-use"
                        ? `Sorry, Entered email already in use. Please use a different email address.`
                        : `Sorry, you can't create a new account right now. Please try again.`}
                    </p>
                  )}
                </div>
              </div>
              <div
                className="col-12"
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <ReCAPTCHA
                  onExpired={() => setNotARobot(false)}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ""}
                  onChange={(value) => onCaptchaChange(value, setNotARobot)}
                />
              </div>
              <div className="col-12">
                <div className="form-group">
                  <button
                    style={{
                      fontSize: "15px",
                      backgroundColor: COLOR_PURPLE,
                      borderColor: COLOR_PURPLE
                    }}
                    type="submit"
                    className="btn btn-inline"
                    disabled={!isValid || isSubmitting || !notARobot}
                  >
                    {createUserWithEmailAndPasswordIsLoading ? (
                      <div
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex"
                        }}
                      >
                        <PulseLoader size={10} margin={1} color={COLOR_LIGHT_BLUE} loading />
                      </div>
                    ) : (
                      <span>Create New Account</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <div className="user-form-direction">
        <p>
          Already have an account? click on the
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span
            style={{
              cursor: "pointer"
            }}
            onClick={() => setSignUpForm(false)}
          >
            &nbsp;( sign in )&nbsp;
          </span>
          button.
        </p>
      </div>
    </div>
  );
}
