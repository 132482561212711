/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { UserState } from "../redux/reducer";
import Error from "../../../shared/components/Error";
import { fetchCustomUserAction } from "../redux/actions";
import Pagination from "../../home/components/Pagination";
import { HomePageState } from "../../home/redux/reducer";
import MyAdsPageSummaryTop from "../components/MyAdsPageSummaryTop";
import MyAdsCard from "../components/MyAdsCard";

export default function MyAds(props: { match: any }): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const { match } = props;
  const userUrl = match.params.id;
  const { fetchCustomUserIsLoading, customUser: currentUser } = useSelector<AppState, UserState>(
    (state) => state.userManagement
  );
  const advertisementsPerPage = (process.env.REACT_APP_ADVERTISEMENTS_PER_PAGE as unknown) as number;
  const { advertisements, totalAds } = useSelector<AppState, HomePageState>((state) => state.homePageManagement);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    if (userUrl && userUrl !== currentUser?.url) {
      dispatch(fetchCustomUserAction(userUrl));
    }
  }, [currentUser, dispatch, userUrl]);

  if (fetchCustomUserIsLoading) {
    return <div style={{ height: "100vh" }} />;
  }
  if (currentUser === undefined) {
    return <Error />;
  }

  return (
    <>
      <Helmet>
        <title>My Ads User - Aduwata.lk</title>
        <meta name="description" content="ඕනිම දෙයක් විකුණන්න මිලදී ගන්න හොඳම තැන - Aduwata.lk" />
      </Helmet>
      <section
        className="ad-list-part"
        style={{
          // backgroundColor: "white",
          paddingTop: 0,
          paddingBottom: 48
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12" style={{ height: "100vh" }}>
              Coming Soon...
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
