/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { AppState } from "../../../store";
import Error from "../../../shared/components/Error";
import ViewAdPriceCard from "../components/viewAd/ViewAdPriceCard";
import ViewAdNumberCard from "../components/viewAd/ViewAdNumberCard";
import ViewAdAuthorCard from "../components/viewAd/ViewAdAuthorCard";
import ViewAdLocationCard from "../components/viewAd/ViewAdLocationCard";
import ViewAdSafetyCard from "../components/viewAd/ViewAdSafetyCard";
import ViewAdDescriptionCard from "../components/viewAd/ViewAdDescriptionCard";
import ViewAdMessageToSeller from "../components/viewAd/ViewAdMessageToSeller";
import ViewAdSpec from "../components/viewAd/ViewAdSpec";
import ViewAdTitle from "../components/viewAd/ViewAdTitle";
import { AdvertisementState } from "../redux/reducer";
import { fetchSingleAdvertisementAction, resetAdStatusAction } from "../redux/actions";
import DeleteConfirmationModal from "../components/viewAd/DeleteConfirmationModal";
import ReportAdModal from "../components/viewAd/ReportAdModal";
import { getThumbnailImg } from "../../../utils/imageUrlGenerator";
import ViewAdOpeningHours from "../components/viewAd/ViewAdOpeningHours";

export default function ViewAd(props: { match: any }): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { match } = props;
  const { t } = useTranslation();
  const documentID = match.params.id;

  const { singleAd: currentAd, fetchSingleAdIsLoading, deleteAdIsSuccess } = useSelector<AppState, AdvertisementState>(
    (state) => state.advertisementManagement
  );

  useEffect(() => {
    if (deleteAdIsSuccess) {
      history.push("/ads/home");
      dispatch(resetAdStatusAction());
    }
  }, [deleteAdIsSuccess]);

  useEffect(() => {
    if (documentID) {
      dispatch(fetchSingleAdvertisementAction(documentID));
    }
  }, [dispatch, documentID]);

  if (fetchSingleAdIsLoading) {
    return <div style={{ height: "100vh" }} />;
  }

  if (currentAd === undefined) {
    return <Error />;
  }

  // if (currentAd.status === "sold_out") {
  //   return <SoldOut />;
  // }

  const path = [t(currentAd?.category), t(currentAd?.subCategory), t(currentAd?.location), t(currentAd?.subLocation)];

  const NavigationPath = () => (
    <div style={{ marginLeft: -9 }}>
      {path.map((el, index) => (
        <button key={index} type="button" className="home-page-filters" style={{ marginRight: 5 }}>
          {el}
          <i className="fa fa-times-circle ml-2" aria-hidden="true" />
        </button>
      ))}
      {currentAd?.title && (
        <button
          type="button"
          className="home-page-filters"
          style={{
            width: "115px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            position: "relative",
            paddingRight: 20
          }}
        >
          {currentAd?.title}
          <i
            className="fa fa-times-circle ml-2"
            aria-hidden="true"
            style={{
              position: "absolute",
              top: "6px",
              right: "8px"
            }}
          />
        </button>
      )}
    </div>
  );

  return (
    <>
      <Helmet>
        <title>{`${currentAd.title} - Aduwata.lk`}</title>
        <meta name="title" content={currentAd.title} />
        <meta name="description" content={currentAd.description} />

        <meta property="og:type" content="product" />
        <meta property="og:url" content={`https://aduwata.lk/ads/view-ad/${currentAd.url}`} />
        <meta property="og:title" content={currentAd.title} />
        <meta property="og:description" content={currentAd.description.toString()} />
        <meta property="og:image" content={getThumbnailImg(currentAd?.images[0])} />
      </Helmet>

      <DeleteConfirmationModal ad={currentAd} />
      <ReportAdModal ad={currentAd} />
      <section className="ad-details-part" style={{ paddingTop: 29, paddingBottom: 48 }}>
        <div className="container">
          <NavigationPath />
          <div className="row">
            <div className="col-lg-8" style={{ padding: "0 6px" }}>
              <ViewAdTitle currentAd={currentAd} />
              <ViewAdSpec currentAd={currentAd} />
              <ViewAdDescriptionCard currentAd={currentAd} />
              <ViewAdMessageToSeller currentAd={currentAd} />
            </div>
            <div className="col-lg-4" style={{ padding: "0 6px" }}>
              <ViewAdPriceCard currentAd={currentAd} />
              <ViewAdNumberCard currentAd={currentAd} />
              <ViewAdAuthorCard currentAd={currentAd} />
              {currentAd.author.openHours && <ViewAdOpeningHours currentAd={currentAd} />}
              <ViewAdLocationCard currentAd={currentAd} />
              <ViewAdSafetyCard />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
