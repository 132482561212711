import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { COLOR_PURPLE } from "../../../config/colorProfile";
import { forgetPasswordAction } from "../redux/actions";
import errorStyles from "../../../config/errorStyles";
import onCaptchaChange from "../redux/onCaptchaChange";

export default function ForgetPasswordModal() {
  const dispatch = useDispatch();
  const [notARobot, setNotARobot] = useState(false);

  const yupSchema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email").required("Email is required")
  });
  const initialValues = { email: "" };

  return (
    <div
      className="modal"
      id="forgetPasswordModal"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalCenterTitle">
              Forget Password ?
            </h3>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ color: "black" }}>
            <h5 style={{ marginBottom: 20, paddingLeft: 15, paddingRight: 15 }}>
              Please enter your email address. We’ll send you an email with instructions to reset password.
            </h5>
            <Formik
              initialValues={initialValues}
              validationSchema={yupSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                dispatch(forgetPasswordAction(values.email));
                setSubmitting(false);
                // @ts-ignore
                resetForm(initialValues);
                // @ts-ignore
                $("#forgetPasswordModal").modal("hide");
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="d-flex flex-column">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className="form-control"
                          placeholder="Email"
                        />
                        {errors.email && touched.email && <p style={errorStyles.err}>{errors.email}</p>}
                      </div>
                    </div>
                    <div
                      className="col-12"
                      style={{
                        marginBottom: "20px",
                        display: isValid && values.email.length > 0 ? "flex" : "none",
                        justifyContent: "center"
                      }}
                    >
                      <ReCAPTCHA
                        onExpired={() => setNotARobot(false)}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY || ""}
                        onChange={(value) => onCaptchaChange(value, setNotARobot)}
                      />
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <button
                          disabled={!isValid || isSubmitting || !notARobot}
                          style={{
                            fontSize: "15px",
                            backgroundColor: COLOR_PURPLE,
                            borderColor: COLOR_PURPLE
                          }}
                          type="submit"
                          className="btn btn-inline"
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
