import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppState } from "../../../store";
import { RemoteConfigState } from "../../../utils/remoteConfigurations";
import searchAdTypes from "../../../utils/searchAdTypes";

export default function FilterByType(): JSX.Element {
  const history = useHistory();
  const { adTypes: availableAdTypes } = useSelector<AppState, RemoteConfigState>(
    (state) => state.remoteConfigManagement
  );
  const { t } = useTranslation();
  const [adTypes, setAdTypes] = useState<string[]>([]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const adTypesFromUrl = (urlSearchParams.get("adTypes") || "").split(",").filter((el) => el.length > 0);
  useEffect(() => {
    if (adTypes.toString() !== adTypesFromUrl.toString()) {
      setAdTypes(adTypesFromUrl);
    }
  }, [adTypesFromUrl]);

  return (
    <div className="col-md-6 col-lg-12" style={{ padding: "0 6px" }}>
      <div className="product-sidebar" style={{ backgroundColor: "white" }}>
        <div className="product-sidebar-title">
          <h6>Filter by type</h6>
        </div>
        <div className="product-sidebar-content">
          <ul className="product-sidebar-list">
            {availableAdTypes.map((currentType: any) => (
              <li key={currentType}>
                <input
                  id={currentType}
                  type="checkbox"
                  checked={adTypes.includes(currentType)}
                  onChange={(e) => {
                    // @ts-ignore
                    if (e.target.checked) {
                      searchAdTypes(adTypes.concat(currentType), setAdTypes, history);
                    } else {
                      searchAdTypes(
                        adTypes.filter((x) => x !== currentType),
                        setAdTypes,
                        history
                      );
                    }
                  }}
                />
                <label htmlFor={currentType}>{t(currentType)}</label>
              </li>
            ))}
          </ul>
          <button type="button" className="product-filter-btn" onClick={() => searchAdTypes([], setAdTypes, history)}>
            <span>Clear Filter</span>
          </button>
        </div>
      </div>
    </div>
  );
}
