import React from "react";
import { COLOR_RED } from "../../config/colorProfile";

export default function UnAuthorized() {
  return (
    <section className="error-part">
      <div className="container" style={{ height: 429 }}>
        <div className="error">
          <h1 style={{ color: COLOR_RED }}>401</h1>
          <h2 style={{ color: COLOR_RED }}>Unauthorized</h2>
          <a href="/" className="btn btn-outline sold-out" style={{ color: COLOR_RED, borderColor: COLOR_RED }}>
            <span>go to homepage</span>
          </a>
        </div>
      </div>
    </section>
  );
}
