import { call, put } from "redux-saga/effects";
import { EditUserProfileAction, editUserProfileFailureAction, editUserProfileSuccessAction } from "../actions";
import User from "../../../../shared/userInterface";
import getCurrentUserAuthorization from "../../../../utils/getAuthorizationToken";

async function editUserProfileFromApi(user: User, authToken: string) {
  const body = {
    name: user.name,
    type: user.type,
    openHours: user.openHours,
    address: user.address,
    shortDescription: user.shortDescription,
    description: user.description
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/user/${user.uid}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${authToken}`
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callEditUserProfileSaga(action: EditUserProfileAction) {
  const authToken: string = yield call(getCurrentUserAuthorization);

  try {
    // @ts-ignore
    const result = yield call(editUserProfileFromApi, action.user, authToken);
    if (result.status === "success") {
      yield put(editUserProfileSuccessAction());
    } else {
      throw new Error(result.toString());
    }
  } catch (e) {
    yield put(editUserProfileFailureAction(e.toString()));
  }
}
