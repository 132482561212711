export enum aboutUsPageActionTypes {
  FETCH_ABOUT_US_COUNTS = "aduwata-lk/aboutUs/fetch_about_us_counts",
  FETCH_ABOUT_US_COUNTS_SUCCESS = "aduwata-lk/aboutUs/fetch_about_us_counts_success",
  FETCH_ABOUT_US_COUNTS_FAILURE = "aduwata-lk/aboutUs/fetch_about_us_counts_failure"
}

export interface FetchAboutUsCountsAction {
  type: aboutUsPageActionTypes.FETCH_ABOUT_US_COUNTS;
}

interface FetchAboutUsCountsSuccessAction {
  type: aboutUsPageActionTypes.FETCH_ABOUT_US_COUNTS_SUCCESS;
  registeredUsers: number;
  communityAds: number;
  soldOnThisWebsite: number;
}

interface FetchAboutUsCountsFailureAction {
  type: aboutUsPageActionTypes.FETCH_ABOUT_US_COUNTS_FAILURE;
  err: string;
}

export type AboutUsPageActions =
  | FetchAboutUsCountsAction
  | FetchAboutUsCountsSuccessAction
  | FetchAboutUsCountsFailureAction;

export function fetchAboutUsCountsAction(): FetchAboutUsCountsAction {
  return {
    type: aboutUsPageActionTypes.FETCH_ABOUT_US_COUNTS
  };
}

export function fetchAboutUsCountsSuccessAction(
  registeredUsers: number,
  communityAds: number,
  soldOnThisWebsite: number
): FetchAboutUsCountsSuccessAction {
  return {
    type: aboutUsPageActionTypes.FETCH_ABOUT_US_COUNTS_SUCCESS,
    registeredUsers,
    communityAds,
    soldOnThisWebsite
  };
}

export function fetchAboutUsCountsFailureAction(err: string): FetchAboutUsCountsFailureAction {
  console.error("fetchAboutUsCountsFailureAction", err);
  return {
    type: aboutUsPageActionTypes.FETCH_ABOUT_US_COUNTS_FAILURE,
    err
  };
}
