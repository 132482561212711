import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { UserProvider } from "./utils/UserProvider";
import RouterHandler from "./utils/RouterHandler";
import "react-toastify/dist/ReactToastify.css";
import { fetchRemoteConfigAction } from "./utils/remoteConfigurations";

export default function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  i18n.changeLanguage("en-US");

  useEffect(() => {
    dispatch(fetchRemoteConfigAction());
  }, [dispatch]);

  return (
    <UserProvider>
      <RouterHandler />
      <ToastContainer autoClose={3000} hideProgressBar />
    </UserProvider>
  );
}
