import { call, put } from "redux-saga/effects";
import { fetchCountsFailureAction, fetchCountsSuccessAction } from "../actions";

async function fetchCountFromFirestore() {
  return fetch(`${process.env.REACT_APP_BASE_URL}/counts`, {
    method: "GET"
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callFetchCountSaga() {
  try {
    // @ts-ignore
    const result = yield call(fetchCountFromFirestore);
    if (result.status === "success") {
      yield put(fetchCountsSuccessAction(result.categories, result.cities, result.total || 0));
    } else {
      throw new Error(JSON.stringify(result));
    }
  } catch (e) {
    yield put(fetchCountsFailureAction(e.toString()));
  }
}
