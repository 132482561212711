import { call, put, select } from "redux-saga/effects";
import { AddContactNoAction, addContactNoFailureAction, addContactNoSuccessAction } from "../actions";
import getCurrentUserAuthorization from "../../../../utils/getAuthorizationToken";

async function addContactNoFromApi(number: string, uid: string, authToken: string) {
  const body = {
    uid,
    number
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/user/contact`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${authToken}`
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callAddContactNoSaga(action: AddContactNoAction) {
  const authToken: string = yield call(getCurrentUserAuthorization);
  const authenticationUID: string | undefined = yield select((state) => state.loginManagement.authenticationUID);

  try {
    // @ts-ignore
    const result = yield call(addContactNoFromApi, action.contact, authenticationUID, authToken);
    if (result.status === "success") {
      yield put(addContactNoSuccessAction());
    } else {
      throw new Error(result.toString());
    }
  } catch (e) {
    yield put(addContactNoFailureAction(e.toString()));
  }
}
