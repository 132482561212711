import { call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { SubscribeAction, subscribeFailureAction, subscribeSuccessAction } from "../actions";

async function subscribeApiCall(email: string) {
  const body = {
    email
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/subscribe`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then((response: any) => response.json())
    .then((data) => data)
    .catch((err) => err);
}

export default function* callSubscribeSaga(action: SubscribeAction) {
  try {
    // @ts-ignore
    const result = yield call(subscribeApiCall, action.email);
    if (result.status === "success") {
      toast.success("You have successfully sent the message.");
      yield put(subscribeSuccessAction());
    } else {
      throw new Error(JSON.stringify(result));
    }
  } catch (e) {
    toast.error("An error occurred, Please try again later.");
    yield put(subscribeFailureAction(e.toString()));
  }
}
