/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { UserState } from "../redux/reducer";
import Error from "../../../shared/components/Error";
import { fetchCustomUserAction } from "../redux/actions";
import { getPrintableDate2 } from "../../../utils/dateFormatter";
import { LoginState } from "../../login/redux/reducer";

export default function ViewUser(props: { match: any }): JSX.Element {
  const dispatch = useDispatch();
  const { match } = props;
  const userUrl = match.params.id;
  const { fetchCustomUserIsLoading, customUser: currentUser } = useSelector<AppState, UserState>(
    (state) => state.userManagement
  );
  const { authenticationUID } = useSelector<AppState, LoginState>((state) => state.loginManagement);

  useEffect(() => {
    if (userUrl && userUrl !== currentUser?.url) {
      dispatch(fetchCustomUserAction(userUrl));
    }
  }, [currentUser, dispatch, userUrl]);

  if (fetchCustomUserIsLoading) {
    return <div style={{ height: "100vh" }} />;
  }
  if (currentUser === undefined) {
    return <Error />;
  }
  const isAuthenticatedUser = currentUser.uid === authenticationUID;

  return (
    <>
      <Helmet>
        <title>View User - Aduwata.lk</title>
        <meta name="description" content="ඕනිම දෙයක් විකුණන්න මිලදී ගන්න හොඳම තැන - Aduwata.lk" />
      </Helmet>
      <section className="dashboard-part">
        <div className="container" style={{ marginBottom: 48 }}>
          <div className="row">
            <div className="col-lg-8" style={{ padding: "0 6px" }}>
              <div className="account-card alert fade show">
                <div className="account-title">
                  <h3>About</h3>
                </div>
                <div className="dash-content">
                  <p style={{ whiteSpace: "pre-line" }}>{currentUser.description}</p>
                </div>
              </div>
              {currentUser.type === "organization" && (
                <div className="account-card alert fade show">
                  <div className="account-title">
                    <h3>Opening Hours</h3>
                  </div>
                  <div className="ad-details-opening">
                    <ul>
                      <li>
                        <h6>monday</h6>
                        <p>{currentUser?.openHours?.monday || "-"}</p>
                      </li>
                      <li>
                        <h6>tuesday</h6>
                        <p>{currentUser?.openHours?.tuesday || "-"}</p>
                      </li>
                      <li>
                        <h6>wednesday</h6>
                        <p>{currentUser?.openHours?.wednesday || "-"}</p>
                      </li>
                      <li>
                        <h6>thursday</h6>
                        <p>{currentUser?.openHours?.thursday || "-"}</p>
                      </li>
                      <li>
                        <h6>friday</h6>
                        <p>{currentUser?.openHours?.friday || "-"}</p>
                      </li>
                      <li>
                        <h6>Saturday</h6>
                        <p>{currentUser?.openHours?.saturday || "-"}</p>
                      </li>
                      <li>
                        <h6>Sunday</h6>
                        <p>{currentUser?.openHours?.sunday || "-"}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-4" style={{ padding: "0 6px" }}>
              <div className="account-card alert fade show">
                <div className="account-title">
                  <h3>Membership</h3>
                </div>
                <ul className="account-card-list">
                  <li>
                    <h5>Status</h5>
                    <p>New</p>
                  </li>
                  <li>
                    <h5>User Type</h5>
                    <p style={{ textTransform: "capitalize" }}>{currentUser.type}</p>
                  </li>
                  <li>
                    <h5>Joined</h5>
                    <p>{getPrintableDate2(currentUser.joinedDate)}</p>
                  </li>
                </ul>
              </div>
              {isAuthenticatedUser && (
                <div className="account-card alert fade show">
                  <div className="account-title">
                    <h3>Active Ads</h3>
                  </div>
                  <ul className="account-card-list">
                    <li>
                      <h5>Sale Ads</h5>
                      <h6>3</h6>
                    </li>
                    <li>
                      <h5>Rental Ads</h5>
                      <h6>1</h6>
                    </li>
                    <li>
                      <h5>Booking Ads</h5>
                      <h6>0</h6>
                    </li>
                    <li>
                      <h5>Wanted Ads</h5>
                      <h6>2</h6>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
