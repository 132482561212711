import { useEffect } from "react";
import isObject from "lodash/isObject";
import { useFormikContext } from "formik";

// @ts-ignore
const getFirstErrorKey = (object, keys = []) => {
  const firstErrorKey = Object.keys(object)[0];
  if (isObject(object[firstErrorKey])) {
    // @ts-ignore
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join(".");
};

// @ts-ignore
const FormikOnError = ({ children }) => {
  const formik = useFormikContext();

  useEffect(() => {
    if (!formik.isValid && formik.submitCount > 0) {
      const firstErrorKey = getFirstErrorKey(formik.errors);
      const listOf = global.window.document.getElementsByName(firstErrorKey);
      if (listOf.length) {
        listOf[listOf.length - 1].focus();
      }
    }
  }, [formik.submitCount, formik.isValid, formik.errors]);
  return children;
};

export default FormikOnError;
